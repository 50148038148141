import { createSlice } from "@reduxjs/toolkit";

import {
  getResumeInfo,
  submitContactInfo,
  submitEducationInfo,
  submitFitnessInfo,
  submitNationalityInfo,
  submitPersonalInfo,
  submitProfessionalExperience,
  submitSkillsInfo,
} from "./CvBuilder.thunk";

const initialState = {
  error: null,
  success: null,
  cvProgressValue: 0,
  loading: false,
  personalFormLoading: false,
  contactFormLoading: false,
  fitnessFormLoading: false,
  nationalityFormLoading: false,
  educationFormLoading: false,
  experienceFormLoading: false,
  skillsFormLoading: false,
  resumeId: null,
  resumeInfo: null,
  personalInfoSaved: false,
  dirty: {
    personalInfo: false,
    contactInfo: false,
    medicalInfo: false,
    demoGraphicsInfo: false,
    educationInfo: false,
    experienceInfo: false,
    skillsInfo: false,
  },
};

export const CvSlice = createSlice({
  name: "cv",
  initialState,
  reducers: {
    resetMessageState: (state) => {
      state.error = null;
      state.success = null;
    },
    setCvProgressValue: (state, { payload }) => {
      state.cvProgressValue = payload;
    },
    setDirty: (state, { payload }) => {
      state.dirty[payload] = true;
    },
    resetDirty: (state, { payload }) => {
      state.dirty[payload] = false;
    },
    setUserImg: (state, { payload }) => {
      state.resumeInfo.personalInfo = { ...state.resumeInfo.personalInfo, userImg: payload };
    },
    updateDirtyPersonalInfo: (state, { payload }) => {
      state.resumeInfo.personalInfo = payload;
    },
    updateDirtyContactInfo: (state, { payload }) => {
      state.resumeInfo.contactInfo = payload;
    },
    updateDirtyFitnessInfo: (state, { payload }) => {
      state.resumeInfo.medicalInfo = payload;
    },
    updateDirtyNationalityInfo: (state, { payload }) => {
      state.resumeInfo.demoGraphicsInfo = payload;
    },
    updateDirtySkillsInfo: (state, { payload }) => {
      state.resumeInfo.skillsInfo = payload;
    },
    setPersonalInfoSaved: (state) => {
      state.personalInfoSaved = true;
    },
    setExperienceInfo: (state, { payload }) => {
      if (!payload.length) {
        state.resumeInfo = {
          ...state.resumeInfo,
          completion: { ...state.resumeInfo.completion, experienceInfo: false },
        };
      } else {
        state.resumeInfo = {
          ...state.resumeInfo,
          completion: { ...state.resumeInfo.completion, experienceInfo: false },
        };
      }
      state.resumeInfo = { ...state.resumeInfo, experienceInfo: { experience: payload } };
    },
    setEducationInfo: (state, { payload }) => {
      if (!payload.length) {
        state.resumeInfo = {
          ...state.resumeInfo,
          completion: { ...state.resumeInfo.completion, educationInfo: false },
        };
      } else {
        state.resumeInfo = {
          ...state.resumeInfo,
          completion: { ...state.resumeInfo.completion, educationInfo: true },
        };
      }
      state.resumeInfo = { ...state.resumeInfo, educationInfo: { education: payload } };
    },
  },
  extraReducers: {
    [submitPersonalInfo.pending]: (state) => {
      state.personalFormLoading = true;
    },
    [submitPersonalInfo.fulfilled]: (state, { payload }) => {
      state.success = "Personal info saved";
      state.personalFormLoading = false;
      state.personalInfoSaved = true;
      state.resumeId = payload.resumeId;
      state.resumeInfo.completion.personalInfo = true;
    },
    [submitPersonalInfo.rejected]: (state, { error }) => {
      state.personalFormLoading = false;
      state.error = error.message;
    },
    [submitContactInfo.pending]: (state) => {
      state.contactFormLoading = true;
    },
    [submitContactInfo.fulfilled]: (state) => {
      state.contactFormLoading = false;
      state.success = "Contact info saved";
      state.resumeInfo.completion.contactInfo = true;
    },
    [submitContactInfo.rejected]: (state, { error }) => {
      state.contactFormLoading = false;
      state.error = error.message;
    },
    [submitFitnessInfo.pending]: (state) => {
      state.fitnessFormLoading = true;
    },
    [submitFitnessInfo.fulfilled]: (state) => {
      state.fitnessFormLoading = false;
      state.success = "Fitness info saved";
      state.resumeInfo.completion.medicalInfo = true;
    },
    [submitFitnessInfo.rejected]: (state, { error }) => {
      state.fitnessFormLoading = false;
      state.error = error.message;
    },
    [submitNationalityInfo.pending]: (state) => {
      state.nationalityFormLoading = true;
    },
    [submitNationalityInfo.fulfilled]: (state) => {
      state.nationalityFormLoading = false;
      state.success = "Nationality info saved";
      state.resumeInfo.completion.demoGraphicsInfo = true;
    },
    [submitNationalityInfo.rejected]: (state, { error }) => {
      state.nationalityFormLoading = false;
      state.error = error.message;
    },
    [submitEducationInfo.pending]: (state) => {
      state.educationFormLoading = true;
    },
    [submitEducationInfo.fulfilled]: (state) => {
      state.educationFormLoading = false;
      state.success = "Education info saved";
      state.resumeInfo.completion.educationInfo = true;
    },
    [submitEducationInfo.rejected]: (state, { error }) => {
      state.educationFormLoading = false;
      state.error = error.message;
    },
    [submitProfessionalExperience.pending]: (state) => {
      state.experienceFormLoading = true;
    },
    [submitProfessionalExperience.fulfilled]: (state) => {
      state.experienceFormLoading = false;
      state.success = "Experience info saved";
      state.resumeInfo.completion.experienceInfo = true;
    },
    [submitProfessionalExperience.rejected]: (state, { error }) => {
      state.experienceFormLoading = false;
      state.error = error.message;
    },
    [submitSkillsInfo.pending]: (state) => {
      state.skillsFormLoading = true;
    },
    [submitSkillsInfo.fulfilled]: (state) => {
      state.skillsFormLoading = false;
      state.success = "Skills info saved";
      state.resumeInfo.completion.skillsInfo = true;
    },
    [submitSkillsInfo.rejected]: (state, { error }) => {
      state.skillsFormLoading = false;
      state.error = error.message;
    },
    [getResumeInfo.pending]: (state) => {
      state.loading = true;
    },
    [getResumeInfo.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.resumeInfo = payload;
      state.personalInfoSaved = payload.completion.personalInfo;
      state.cvProgressValue = Number(payload.completion.inPercentage);
      state.resumeId = payload.resumeId;
    },
    [getResumeInfo.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export const {
  resetMessageState,
  setDirty,
  resetDirty,
  setUserImg,
  updateDirtyPersonalInfo,
  updateDirtyContactInfo,
  updateDirtyFitnessInfo,
  updateDirtyNationalityInfo,
  updateDirtySkillsInfo,
  setExperienceInfo,
  setEducationInfo,
} = CvSlice.actions;

export default CvSlice.reducer;
