import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SERVER_URL } from "constants";
import { getUserId } from "utils";

// eslint-disable-next-line no-promise-executor-return
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const collectApi = async (requestId, expireAt) => {
  expireAt = expireAt || Date.now() + 90000; // 90 seconds
  if (Date.now() > expireAt) throw new Error("SignIn Timeout.");
  await delay(3000);
  const { data } = await axios.post(`${SERVER_URL}/auth/meraid-collect`, requestId);
  if (data.token) {
    return data;
  }
  if (data.message === "failed") {
    throw data.message;
  } else if (data.message === "pending") {
    return collectApi(requestId, expireAt);
  }
  return data;
};

export const signup = createAsyncThunk("SIGN_UP", async (userData) => {
  try {
    localStorage.setItem("userId", userData.cnic);
    const { data } = await axios.post(`${SERVER_URL}/auth/sign-up`, userData);
    return data.message;
  } catch (err) {
    throw err.response.data.message;
  }
});

export const signIn = createAsyncThunk("SIGN_IN", async (userData) => {
  try {
    localStorage.setItem("userId", userData.cnic);
    const { data } = await axios.post(`${SERVER_URL}/auth/sign-in`, userData);
    return data;
  } catch (err) {
    throw err.response.data;
  }
});

export const meraidLogin = createAsyncThunk("MERAID_LOGIN", async (formData) => {
  localStorage.setItem("userId", formData.userId);
  try {
    const { data } = await axios.post(`${SERVER_URL}/auth/meraid-sign-in`, formData);
    const { requestId } = data;
    const response = await collectApi({ requestId });
    return response;
  } catch (err) {
    const message = err?.response?.data?.message;
    if (message) throw new Error(message);
    throw err;
  }
});

export const resendVerificationEmail = createAsyncThunk("RESEND_VERIFICATION_EMAIL", async () => {
  try {
    const userId = getUserId();
    const { data } = await axios.post(`${SERVER_URL}/auth/resend-email`, { cnic: userId });
    return data.message;
  } catch (err) {
    throw err.response.data;
  }
});

export const forgotPassword = createAsyncThunk("FORGOT_PASSWORD", async (cnic) => {
  try {
    const { data } = await axios.post(`${SERVER_URL}/auth/request-reset-password`, {
      userId: cnic,
    });
    return data.message;
  } catch (err) {
    throw err.response.data;
  }
});
