import { yupResolver } from "@hookform/resolvers/yup";
import { Card, CircularProgress, Grid } from "@mui/material";
import SelectCreateable from "components/createable";
import EditorComponent from "components/editor";
import SelectComponent from "components/select";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTextInputField from "components/SuiTextInputField";
import SuiTypography from "components/SuiTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { fieldOfExperience } from "layouts/cv-builder/CvBuilder.constant";
import { stationForTest } from "layouts/cv-builder/CvBuilder.constant";
import { getOrganisations } from "layouts/organisations/Organisations.thunk";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { formateDate } from "utils";
import { mapSelectFieldData } from "utils";
import {
  DEFAULT_NEW_VALUES,
  educations,
  experienceDuration,
  requiredMaxAge,
  requiredMinAge,
  schema,
} from "./JobBuilder.constant";
import { resetMessageState } from "./JobBuilder.reducer";
import { createJob } from "./JobBuilder.thunk";

function JobBuilder() {
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector((state) => state.jobBuilder);
  const { data: orgData, loading: orgLoading } = useSelector((state) => state.org);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: "all",
    defaultValues: DEFAULT_NEW_VALUES,
    resolver: yupResolver(schema),
  });

  const handleChange = (e, regex) => {
    setValue(e.target.name, e.target.value.replace(regex, ""), {
      shouldDirty: true,
    });
  };

  const onCreate = (data) => {
    dispatch(
      createJob({
        ...data,
        fieldOfExperience: data.fieldOfExperience.map((e) => {
          return e.value;
        }),
        skills: data.skills.map((s) => {
          return s.value;
        }),
        testCenters: data.testCenters.map((t) => {
          return t.value;
        }),
        publishDate: formateDate(data.publishDate),
        expiryDate: formateDate(data.expiryDate),
      })
    );
  };

  useEffect(() => {
    if (success) {
      toast.success(success || "Successfully Submitted", {
        duration: 4000,
        position: "top-center",
        className: "toaster successToast",
        icon: null,
      });
      reset({});
      dispatch(resetMessageState());
    }
    if (error) {
      toast.error(error, {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
      dispatch(resetMessageState());
    }
  }, [error, success]);
  useEffect(() => {
    dispatch(getOrganisations());
  }, [dispatch]);
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox mb={3} mt={5}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={2.5}>
              <SuiTypography variant="h6" color="dark">
                Create Job
              </SuiTypography>
            </SuiBox>
            <SuiBox py={2}>
              <Grid container>
                <Grid item xs={12} sm={6} md={4} px={2} py={1}>
                  <Grid container flexDirection="column">
                    <Grid item>
                      <SuiTextInputField
                        name="jobTitle"
                        label="Job Title"
                        control={control}
                        req
                        error={errors.jobTitle}
                        onChange={(e) => {
                          handleChange(e, /[^A-Za-z ]/gi);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <SuiTextInputField
                        name="location"
                        label="Location"
                        control={control}
                        req
                        error={errors.location}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} px={2} py={1}>
                  <EditorComponent
                    label="Description"
                    name="description"
                    error={errors.description}
                    control={control}
                    req
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6} md={4} px={2} py={1}>
                  <SuiTextInputField
                    name="vacancies"
                    label="No Of Vacancies"
                    type="number"
                    control={control}
                    req
                    error={errors.vacancies}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} px={2} py={1}>
                  <SuiTextInputField
                    name="publishDate"
                    label="Publish Date"
                    type="date"
                    control={control}
                    req
                    error={errors.publishDate}
                  />
                  {errors.publishDate && (
                    <SuiBox ml={1} mt={-1}>
                      <SuiTypography variant="caption" color="error">
                        {errors?.publishDate.message}
                      </SuiTypography>
                    </SuiBox>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4} px={2} py={1}>
                  <SuiTextInputField
                    name="expiryDate"
                    label="Expiry Date"
                    type="date"
                    control={control}
                    req
                    error={errors.expiryDate}
                  />
                  {errors.expiryDate && (
                    <SuiBox ml={1} mt={-1}>
                      <SuiTypography variant="caption" color="error">
                        {errors?.expiryDate.message}
                      </SuiTypography>
                    </SuiBox>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6} md={4} px={2} py={1}>
                  <SelectComponent
                    name="orgId"
                    label="Employer Organization Name"
                    control={control}
                    options={orgData.map((org) => {
                      return { label: org.orgName, value: org.orgId };
                    })}
                    req
                    error={errors.orgId}
                    isLoading={orgLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} px={2} py={1}>
                  <SuiTextInputField
                    name="grade"
                    label="Grade"
                    control={control}
                    req
                    error={errors.grade}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} px={2} py={1}>
                  <SuiTextInputField
                    name="salary"
                    label="Salary In PKR"
                    control={control}
                    error={errors.salary}
                    onChange={(e) => {
                      handleChange(e, /[^0-9]/gi);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6} md={4} px={2} py={1}>
                  <SelectComponent
                    name="jobType"
                    label="Job Type"
                    options={[
                      {
                        label: "On Site",
                        value: "onSite",
                      },
                      {
                        label: "Remote",
                        value: "Remote",
                      },
                    ]}
                    control={control}
                    req
                    error={errors.jobType}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} px={2} py={1}>
                  <SelectComponent
                    name="shift"
                    label="Shift"
                    options={[
                      {
                        label: "Morning",
                        value: "m",
                      },
                      {
                        label: "Evening",
                        value: "e",
                      },
                    ]}
                    control={control}
                    error={errors.shift}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={2} px={2} py={1}>
                  <SelectComponent
                    name="minAge"
                    label="Min Age"
                    options={mapSelectFieldData(requiredMinAge)}
                    control={control}
                    req
                    error={errors.minAge}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={2} px={2} py={1}>
                  <SelectComponent
                    name="maxAge"
                    label="Max Age"
                    options={mapSelectFieldData(requiredMaxAge)}
                    control={control}
                    req
                    error={errors.maxAge}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={4} px={2} py={1}>
                  <SelectComponent
                    name="education"
                    label="Required Education"
                    options={educations}
                    control={control}
                    req
                    error={errors.education}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} px={2} py={1}>
                  <SelectCreateable
                    isMulti
                    name="skills"
                    label="Required Skills"
                    placeholderText="Create"
                    control={control}
                    req
                    error={errors.skills}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} px={2} py={1}>
                  <SelectComponent
                    isMulti
                    name="fieldOfExperience"
                    label="Required Experience Field"
                    placeholderText="Select"
                    control={control}
                    options={fieldOfExperience}
                    req
                    error={errors.fieldOfExperience}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={4} px={2} py={1}>
                  <SelectComponent
                    name="experienceDuration"
                    label="Required Experience Duration"
                    options={experienceDuration}
                    control={control}
                    req
                    error={errors.experienceDuration}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} px={2} py={1}>
                  <SelectComponent
                    name="employmentType"
                    label="Employment Type"
                    options={mapSelectFieldData(["Regular", "Contract", "PartTime", "DailyWages"])}
                    control={control}
                    req
                    error={errors.employmentType}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} px={2} py={1}>
                  <SelectComponent
                    isMulti
                    name="testCenters"
                    label="Test Centers"
                    options={stationForTest}
                    control={control}
                    error={errors.testCenters}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" alignItems="flex-end">
                <Grid item xs={12} sm={12} md={4} px={2} py={1}>
                  <SuiTextInputField
                    name="declarations"
                    label="Declarations"
                    control={control}
                    multiline
                    rows={6}
                    error={errors.declarations}
                    req
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} mr={2} py={1}>
                  <SuiButton color="dark" size="large" onClick={handleSubmit(onCreate)}>
                    {loading ? <CircularProgress size={25} color="white" /> : "Create"}
                  </SuiButton>
                </Grid>
              </Grid>
            </SuiBox>
          </Card>
        </SuiBox>
        <Footer company={{ name: "Panoptic Solutions" }} />
      </DashboardLayout>
    </>
  );
}

export default JobBuilder;
