import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getResumeInfoUrl } from "constants";
import { getResumeServerUrl } from "constants";
import { getUserId } from "utils";

export const submitPersonalInfo = createAsyncThunk("SUBMIT_PERSONAL_INFO", async (obj) => {
  try {
    const userId = getUserId();
    const { data } = await axios.post(`${getResumeServerUrl(userId)}/personal-info`, obj);
    return data;
  } catch (err) {
    throw err.response.data.message;
  }
});

export const submitContactInfo = createAsyncThunk("SUBMIT_CONTACT_INFO", async (contantInfo) => {
  try {
    const userId = getUserId();
    const { data } = await axios.post(
      `${getResumeServerUrl(userId)}/resume/${contantInfo.resumeId}/contact-info`,
      contantInfo
    );

    return data;
  } catch (err) {
    throw err.response.data.message;
  }
});

export const submitFitnessInfo = createAsyncThunk("SUBMIT_FITNESS_INFO", async (obj) => {
  try {
    const userId = getUserId();
    const { data } = await axios.post(
      `${getResumeServerUrl(userId)}/resume/${obj.resumeId}/medical-info`,
      obj
    );
    return data;
  } catch (err) {
    throw err.response.data.message;
  }
});

export const submitNationalityInfo = createAsyncThunk("SUBMIT_NATIOALITY_INFO", async (obj) => {
  try {
    const userId = getUserId();
    const { data } = await axios.post(
      `${getResumeServerUrl(userId)}/resume/${obj.resumeId}/demographics-info`,
      obj
    );
    return data;
  } catch (err) {
    throw err.response.data.message;
  }
});

export const submitEducationInfo = createAsyncThunk("SUBMIT_EDUCATION_INFO", async (obj) => {
  try {
    const userId = getUserId();
    const { data } = await axios.post(
      `${getResumeServerUrl(userId)}/resume/${obj.resumeId}/education-info`,
      obj
    );
    return data;
  } catch (err) {
    throw err.response.data.message;
  }
});

export const submitProfessionalExperience = createAsyncThunk(
  "SUBMIT_PROFESSIONAL_EXPERIENCE",
  async (obj) => {
    try {
      const userId = getUserId();
      const { data } = await axios.post(
        `${getResumeServerUrl(userId)}/resume/${obj.resumeId}/experience-info`,
        obj
      );
      return data;
    } catch (err) {
      throw err.response.data.message;
    }
  }
);

export const submitSkillsInfo = createAsyncThunk("SUBMIT_SKILLS_INFO", async (obj) => {
  try {
    const userId = getUserId();
    const { data } = await axios.post(
      `${getResumeServerUrl(userId)}/resume/${obj.resumeId}/skills-info`,
      obj.data
    );
    return data;
  } catch (err) {
    throw err.response.data.message;
  }
});

export const getResumeInfo = createAsyncThunk("GET_RESUME_INFO", async (obj) => {
  try {
    const userId = getUserId();
    const { data } = await axios.get(`${getResumeInfoUrl(userId)}`);
    return data;
  } catch (err) {
    throw err.response.data.message;
  }
});
