import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import curved9 from "assets/images/curved-images/curved-6.jpg";
import { useForm } from "react-hook-form";
import SuiTextInputField from "components/SuiTextInputField";
// import logo from "../../../assets/images/MeraID_Logo_Self_Square.svg";
import { resetMessageState } from "../auth.reducer";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { meraidSchema, signInSchema } from "./signIn.constant";
import { CircularProgress, Slide } from "@mui/material";
import {
  meraidLogin,
  resendVerificationEmail,
  signIn,
  forgotPassword as forgotRequest,
} from "../auth.thunk";
import toast from "react-hot-toast";

function SignIn() {
  const dispatch = useDispatch();
  const { loading, error, emailLoading, success } = useSelector((state) => state.auth);
  const [loginWithMeraid, setMeraidLogin] = useState(false);
  const [resend, setResend] = useState(false);
  const { pathname } = useLocation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(signInSchema),
  });

  const meraidLoginForm = useForm({
    resolver: yupResolver(meraidSchema),
  });

  const signInWithMeraId = (data) => {
    dispatch(resetMessageState());
    dispatch(meraidLogin({ userId: data.cnic }));
  };

  const signInUser = (data) => {
    dispatch(signIn(data));
  };

  useEffect(() => {
    if (error === "Please verify your email address!") {
      setTimeout(() => {
        setResend(true);
      }, 500);
    }

    if (error) {
      toast(error, {
        duration: 6000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
      dispatch(resetMessageState());
    }
    if (success) {
      toast(success, {
        duration: 6000,
        position: "top-center",
        className: "toaster successToast",
        icon: null,
      });
      dispatch(resetMessageState());
      setResend(false);
    }
  }, [error, success]);

  useEffect(() => {
    window.onbeforeunload = () => {
      dispatch(resetMessageState());
    };
  }, []);
  useEffect(() => {
    dispatch(resetMessageState());
  }, [pathname]);

  const handleCnicChange = (e, m) => {
    if (m) {
      meraidLoginForm.setValue(e.target.name, e.target.value.replace(/[^0-9]/gi, ""), {
        shouldDirty: true,
      });
    } else {
      setValue(e.target.name, e.target.value.replace(/[^0-9]/gi, ""), {
        shouldDirty: true,
      });
    }
  };

  const forgotPassword = (data) => {
    dispatch(forgotRequest(data.cnic));
  };

  return (
    <CoverLayout
      title="Sign In"
      description={
        loginWithMeraid ? "Enter your CNIC to sign in" : "Enter your CNIC and Password to sign in"
      }
      image={curved9}
    >
      <SuiBox component="form" role="form">
        {loginWithMeraid ? (
          <SuiBox>
            <SuiBox ml={0.5}>
              <SuiBox>
                <SuiTextInputField
                  name="cnic"
                  placeholder="CNIC"
                  control={meraidLoginForm.control}
                  label="CNIC"
                  error={meraidLoginForm.formState?.errors?.cnic}
                  inputProps={{ maxLength: 13 }}
                  onChange={(e) => {
                    handleCnicChange(e, true);
                  }}
                />
                {meraidLoginForm.formState.errors.cnic && (
                  <SuiBox ml={1} mt={-1}>
                    <SuiTypography variant="caption" color="error">
                      {meraidLoginForm.formState.errors.cnic?.message}
                    </SuiTypography>
                  </SuiBox>
                )}
              </SuiBox>
              <SuiBox pt={2}>
                <SuiButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={meraidLoginForm.handleSubmit(signInWithMeraId)}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <CircularProgress color="dark" size={25} disableShrink /> : "sign in"}
                </SuiButton>
              </SuiBox>
              <SuiBox pt={1}>
                <SuiButton
                  color="light"
                  fullWidth
                  onClick={() => {
                    setMeraidLogin(false);
                  }}
                >
                  sign in with password
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        ) : (
          <>
            <SuiBox>
              <SuiBox mb={1} ml={0.5}>
                <SuiTextInputField
                  name="cnic"
                  placeholder="e.g 1730128115431"
                  control={control}
                  label="CNIC"
                  error={errors.cnic}
                  inputProps={{ maxLength: 13 }}
                  onChange={(e) => {
                    handleCnicChange(e, false);
                  }}
                />
                {errors.cnic && (
                  <SuiBox ml={1} mt={-1}>
                    <SuiTypography variant="caption" color="error">
                      {errors.cnic?.message}
                    </SuiTypography>
                  </SuiBox>
                )}
              </SuiBox>
            </SuiBox>
            <SuiBox mb={2}>
              <SuiBox mb={1} ml={0.5}>
                <SuiTextInputField
                  name="password"
                  type="password"
                  placeholder="********"
                  control={control}
                  label="Password"
                  error={errors.password}
                />
                {errors.password && (
                  <SuiBox ml={1} mt={-1}>
                    <SuiTypography variant="caption" color="error">
                      {errors.password?.message}
                    </SuiTypography>
                  </SuiBox>
                )}
              </SuiBox>
            </SuiBox>

            <SuiBox mt={-1.5} ml={1}>
              <SuiTypography variant="button" color="text" fontWeight="regular">
                <SuiTypography
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  style={{ cursor: "pointer" }}
                  textGradient
                  onClick={handleSubmit(forgotPassword)}
                >
                  Forgot password?
                </SuiTypography>
              </SuiTypography>
            </SuiBox>

            {resend && (
              <SuiBox mt={1.3}>
                <Slide
                  direction="up"
                  in={resend}
                  mountOnEnter
                  unmountOnExit
                  timeout={{ enter: 2000 }}
                  style={{ transitionDelay: `1000ms` }}
                >
                  <div>
                    <SuiTypography variant="button" color="text" fontWeight="regular">
                      Didn't get an email?{" "}
                      <SuiTypography
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        style={{ cursor: "pointer" }}
                        textGradient
                        onClick={() => {
                          dispatch(resendVerificationEmail());
                        }}
                      >
                        Resend Email
                      </SuiTypography>
                    </SuiTypography>
                  </div>
                </Slide>
              </SuiBox>
            )}
            <SuiBox mt={1.4} mb={1}>
              <SuiButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                onClick={handleSubmit(signInUser)}
                disabled={emailLoading || loading}
              >
                {loading ? <CircularProgress color="dark" size={25} disableShrink /> : "sign in"}
              </SuiButton>
            </SuiBox>
            <SuiButton
              variant="gradient"
              color="light"
              fullWidth
              onClick={() => {
                setMeraidLogin(true);
              }}
            >
              sign in With MeraID&nbsp;
              {/* <img src={logo} className="logo" alt="logo" /> */}
            </SuiButton>
          </>
        )}
        <SuiBox mt={3} textAlign="center">
          <SuiTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SuiTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SuiTypography>
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </CoverLayout>
  );
}

export default SignIn;
