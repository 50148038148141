import * as yup from "yup";

export const schema = yup.object().shape({
  disability: yup.string().trim().required(),
  bloodGroup: yup.string().trim().required(),
});

export const DEFAULT_VALUES = {
  heightFt: "",
  heightInches: "",
  weight: "",
  weightPoint: "",
  bloodGroup: "",
  chestType: "",
  chestSize: "",
  disability: "",
  diseases: "",
};
