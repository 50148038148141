import React, { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";
// import { History, Blocker, Transition } from "history";

function useBlocker(Blocker, when = true) {
  const navigator = useContext(UNSAFE_NavigationContext).navigator;

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      Blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, Blocker, when]);
}

export default useBlocker;
