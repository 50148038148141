import * as yup from "yup";

export const schema = yup.object().shape({
  testCenter: yup.string().notRequired(),
  declarationAccepted: yup.bool().oneOf([true]).required(),
  tncAccepted: yup.bool().oneOf([true]).required(),
});

export const DEFAULT_VALUES = {
  testCenter: "",
  declarationAccepted: false,
  tncAccepted: false,
  ageRelaxationClaimed: false,
};
