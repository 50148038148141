import { createSlice } from "@reduxjs/toolkit";
import { getUserProfile, updateProfile } from "./Profile.thunk";

const initialState = {
  error: null,
  success: null,
  loading: false,
  user: null,
};

export const ProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetMessageState: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: {
    [getUserProfile.pending]: (state) => {
      state.loading = true;
    },
    [getUserProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user = payload;
    },
    [getUserProfile.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [updateProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = payload;
    },
    [updateProfile.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export const { resetMessageState } = ProfileSlice.actions;

export default ProfileSlice.reducer;
