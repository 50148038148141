import React, { useEffect, useState } from "react";
import SuiBox from "components/SuiBox";
import SuiTextInputField from "components/SuiTextInputField";
import Grid from "@mui/material/Grid";
import SuiButton from "components/SuiButton";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectComponent from "components/select";
import AlertDialogue from "components/alertDialogue";
import {
  AjkDomicileDistricts,
  balochistanDistricts,
  domicileValues,
  drivingLiscenceTypes,
  fataDistricts,
  genderValues,
  gilgitBaltistanDistricts,
  islamabadDistricts,
  kpkDistricts,
  maritalStatusValues,
  punjabDistricts,
  religions,
  sindhRuralDistricts,
  sindhUrbanDistricts,
} from "../CvBuilder.constant";
import { formateDate, getUserId } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { submitPersonalInfo } from "../CvBuilder.thunk";
import { CircularProgress, Icon } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import { DEFAULT_VALUES, schema } from "./PersonalInfo.constant";
import { useNavigate } from "react-router-dom";
import { educations } from "layouts/job-builder/JobBuilder.constant";
import toast from "react-hot-toast";
import { resetMessageState } from "./personalInfo.reducer";
import { resetDirty, setDirty, setUserImg, updateDirtyPersonalInfo } from "../CvBuilder.reducer";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { getUserProfile } from "layouts/profile/Profile.thunk";
import { getDocUploadData, uploadImage } from "./PersonalInfo.thunk";

function PersonalInfo() {
  const dispatch = useDispatch();
  const { personalFormLoading, resumeInfo, success } = useSelector((state) => state.cv);
  const { user } = useSelector((state) => state.profile);
  const { docUploadData, imgLoading, imgErr, imgSuccess } = useSelector(
    (state) => state.personalInfo
  );
  const userId = getUserId();
  const [domicileDistricts, setDomicileDistricts] = useState([]);
  const [selectedImg, setSelectedImg] = useState(null);
  const [uploadImg, setImageUpload] = useState(null);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [deleteImg, setDeleteImg] = useState(false);
  const [imgReady, setImgReady] = useState(false);

  useEffect(() => {
    if (imgSuccess) {
      dispatch(setDirty("personalInfo"));
      toast.success(imgSuccess || "Successfully Submitted", {
        duration: 4000,
        position: "top-center",
        className: "toaster successToast",
        icon: null,
      });
      setImgReady(true);
      dispatch(resetMessageState());
    }
    if (imgErr) {
      toast.error(imgErr, {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
      dispatch(resetMessageState());
    }
  }, [imgErr, imgSuccess, dispatch]);

  const onChangeDomicile = (value) => {
    switch (value) {
      case "AJK":
        setDomicileDistricts(AjkDomicileDistricts);
        break;
      case "Balochistan":
        setDomicileDistricts(balochistanDistricts);
        break;
      case "Gilgit-Baltistan":
        setDomicileDistricts(gilgitBaltistanDistricts);
        break;
      case "Islamabad Capital Territory (ICT)":
        setDomicileDistricts(islamabadDistricts);
        break;
      case "KPK":
        setDomicileDistricts(kpkDistricts);
        break;
      case "Northern Area/FATA":
        setDomicileDistricts(fataDistricts);
        break;
      case "Punjab":
        setDomicileDistricts(punjabDistricts);
        break;
      case "Sindh (Rural)":
        setDomicileDistricts(sindhRuralDistricts);
        break;
      case "Sindh (Urban)":
        setDomicileDistricts(sindhUrbanDistricts);
        break;
      default:
        setDomicileDistricts([]);
    }
  };

  const { control: userImgCtrl } = useForm({});

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUES,
  });
  const personalFormSubmit = (data) => {
    if (data.drivingLicense === "No") {
      delete data["licenseExpiryDate"];
      delete data["licenseType"];
    }

    if (docUploadData) {
      dispatch(
        submitPersonalInfo({
          ...data,
          birthDate: formateDate(data.birthDate),
          userImg: !deleteImg ? docUploadData.signedUrl.fields.Key : "",
        })
      );
    } else {
      dispatch(
        submitPersonalInfo({
          ...data,
          birthDate: formateDate(data.birthDate),
        })
      );
    }
  };

  const watchDomicileDitrict = useWatch({
    name: "domicile",
    control: control,
  });
  const watchLicense = useWatch({
    name: "drivingLicense",
    control: control,
  });

  useEffect(() => {
    if (uploadImg) {
      dispatch(getDocUploadData({}));
    }
  }, [uploadImg]);

  useEffect(() => {
    if (docUploadData && uploadImg) {
      dispatch(
        uploadImage({
          ...docUploadData.signedUrl,
          fields: { ...docUploadData.signedUrl.fields, file: uploadImg },
        })
      );
    }
  }, [docUploadData]);

  useEffect(() => {
    onChangeDomicile(watchDomicileDitrict);
  }, [watchDomicileDitrict]);

  useEffect(() => {
    const subscription = watch((value) => dispatch(updateDirtyPersonalInfo(value)));
    return () => {
      subscription?.unsubscribe();
    };
  }, [watch]);

  useEffect(() => {
    if (isDirty) {
      dispatch(setDirty("personalInfo"));
    }
  }, [isDirty]);

  useEffect(() => {
    if (success) {
      dispatch(resetDirty("personalInfo"));
      dispatch(getUserProfile());
      dispatch(setUserImg(selectedImg));
    }
  }, [success]);

  useEffect(() => {
    if (resumeInfo && resumeInfo.personalInfo) {
      reset({
        ...resumeInfo.personalInfo,
      });
    }
  }, []);

  const handleImgChange = (e) => {
    const file = e.target.files[0];
    if (file.type.match(/image/)) {
      setImageUpload(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (el) => {
        const baseImg = el.target.result;
        setSelectedImg(baseImg);
      };
    } else {
      toast.error("Please Select Image", {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
    }
  };

  const handleChange = (e, regex) => {
    setValue(e.target.name, e.target.value.replace(regex, ""), {
      shouldDirty: true,
    });
  };
  const onCancel = () => {
    setDeleteImg(false);
    setOpenAlertDialog(false);
  };
  const approveDeleteImg = () => {
    dispatch(setDirty("personalInfo"));
    setDeleteImg(true);
    setImgReady(true);
    setOpenAlertDialog(false);
  };
  return (
    <SuiBox container>
      <Grid container justifyContent="flex-start" alignItems="flex-end">
        <Grid item xs={4} sm={4} md={2} px={2} py={1}>
          <div className="form-image-wrapper">
            {(selectedImg && !deleteImg) || (resumeInfo?.personalInfo?.userImg && !deleteImg) ? (
              <>
                {imgLoading ? (
                  <CircularProgress color="dark" />
                ) : (
                  <img
                    src={selectedImg ? selectedImg : resumeInfo.personalInfo.userImg}
                    alt="User"
                  />
                )}
              </>
            ) : (
              <Icon fontSize="large" color="dark">
                person
              </Icon>
            )}
            <div className="overlay-btn">
              <SuiTextInputField
                name="userImg"
                control={userImgCtrl}
                type="file"
                onChange={handleImgChange}
                label={<PhotoCamera fontSize="large" />}
              />
            </div>
          </div>
        </Grid>
        {resumeInfo?.personalInfo?.userImg && (
          <Grid item mb={0} xs={2} md={2}>
            <DeleteForeverOutlinedIcon
              m={5}
              cursor="pointer"
              onClick={() => setOpenAlertDialog(true)}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4} px={2} py={1}>
          <SuiTextInputField
            name="cnic"
            control={control}
            placeholder="e.g 1730129334421"
            label=" CNIC/CRC/NICOP Number"
            type="number"
            error={errors?.cnic}
            value={userId}
            verified={user?.cnicVerified}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} px={2} py={1}>
          <SuiTextInputField
            name="email"
            control={control}
            label="Email"
            placeholder="username@domain.com"
            type="email"
            req
            value={user?.email}
            verified={user?.emailVerified}
            disabled
          />
        </Grid>
        <AlertDialogue
          open={openAlertDialog}
          content="are you sure you want to delete"
          agreeBtnText="Delete"
          disagreeBtnText="Cancel"
          onCancel={onCancel}
          onApprove={approveDeleteImg}
        />
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} px={2} py={1}>
          <SuiTextInputField
            name="firstName"
            control={control}
            label="First Name"
            error={errors?.firstName}
            req
            onChange={(e) => {
              handleChange(e, /[^a-zA-Z ]/gi);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} px={2} py={1}>
          <SuiTextInputField
            name="lastName"
            control={control}
            label="Last Name"
            req
            error={errors?.lastName}
            onChange={(e) => {
              handleChange(e, /[^a-zA-Z ]/gi);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} px={2} py={1}>
          <SuiTextInputField
            name="fatherName"
            control={control}
            label="Father Name"
            req
            error={errors?.fatherName}
            onChange={(e) => {
              handleChange(e, /[^a-zA-Z ]/gi);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} px={2} py={1}>
          <SelectComponent
            name="gender"
            placeholderText="Select"
            options={genderValues}
            label="Gender"
            control={control}
            req
            error={errors?.gender}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} px={2} py={1}>
          <SuiTextInputField
            name="birthDate"
            control={control}
            label="Date Of Birth"
            type="date"
            req
            error={errors?.birthDate}
          />
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={6} md={4} px={2} py={1}>
            <SelectComponent
              name="domicile"
              placeholderText="Select"
              options={domicileValues}
              label="Domicile"
              control={control}
              req
              error={errors?.domicile}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} px={2} py={1}>
            <SelectComponent
              name="domicileDistrict"
              placeholderText="Select"
              options={domicileDistricts}
              label="District of Domicile"
              control={control}
              req
              error={errors?.domicileDistrict}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} px={2} py={1}>
            <SelectComponent
              name="highestDegree"
              label="Highest Degree"
              placeholderText="Select"
              options={educations}
              control={control}
              req
              error={errors?.highestDegree}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} px={2} py={1}>
            <SelectComponent
              name="religion"
              placeholderText="Select"
              options={religions}
              label="Religion"
              control={control}
              req
              error={errors?.religion}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} px={2} py={1}>
            <SelectComponent
              name="maritalStatus"
              placeholderText="Select"
              options={maritalStatusValues}
              label="Marital Status"
              control={control}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={4} px={2} py={1}>
          <SelectComponent
            name="drivingLicense"
            placeholderText="Select"
            label="Driving License"
            req
            options={[
              { label: "Yes", value: "Yes" },
              { label: "No", value: "No" },
            ]}
            error={errors.drivingLicense}
            control={control}
          />
        </Grid>
        {watchLicense === "Yes" ? (
          <>
            <Grid item xs={12} sm={6} md={4} px={2} py={1}>
              <SelectComponent
                name="licenseType"
                placeholderText="Select"
                options={drivingLiscenceTypes}
                label="License Type"
                control={control}
                error={errors.licenseType}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} px={2} py={1}>
              <SuiTextInputField
                name="licenseExpiryDate"
                control={control}
                label="Driving Lisence Expiry Date"
                type="date"
                error={errors.licenseExpiryDate}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
      <Grid container direction="row" justifyContent="flex-end">
        <Grid item mr={2}>
          {personalFormLoading ? (
            <CircularProgress color="dark" />
          ) : (
            <SuiButton
              variant="contained"
              color="dark"
              onClick={handleSubmit(personalFormSubmit)}
              disabled={!isDirty && !imgReady}
            >
              Save
            </SuiButton>
          )}
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default PersonalInfo;
