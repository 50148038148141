import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, Grid } from "@mui/material";
import MaterialDialog from "components/Dialog";
import SelectComponent from "components/select";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTextInputField from "components/SuiTextInputField";
import SuiTypography from "components/SuiTypography";
import { resetMessageState as resetImageMessage } from "layouts/cv-builder/personalInfo/personalInfo.reducer";
import { uploadImage } from "layouts/cv-builder/personalInfo/PersonalInfo.thunk";
import { getDocUploadData } from "layouts/cv-builder/personalInfo/PersonalInfo.thunk";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { mapSelectFieldData } from "utils";
import { DEFAULT_VALUES, schema } from "./Organisations.constant";
import { getOrganisation, resetOrganisation } from "./Organisations.reducer";
import { addOrganisation, updateOrganisation } from "./Organisations.thunk";

function AddNewOrganisation({ open, onClose }) {
  const { search } = useLocation();
  const [edit, setEdit] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [uploadImg, setImageUpload] = useState(null);
  const orgId = new URLSearchParams(search).get("orgId");
  const dispatch = useDispatch();
  const { addLoading, org } = useSelector((state) => state.org);
  const { docUploadData, imgLoading, imgErr, imgSuccess } = useSelector(
    (state) => state.personalInfo
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUES,
  });

  useEffect(() => {
    if (orgId) {
      setEdit(true);
      dispatch(getOrganisation(orgId));
    }
    if (org && orgId) {
      reset(org);
    } else {
      reset(DEFAULT_VALUES);
    }
    if (edit && !orgId) {
      onClose();
    }
  }, [orgId, org]);

  const onAddOrg = (data) => {
    console.log("form data", data);
    if (orgId) {
      if (docUploadData.signedUrl.fields.Key) {
        dispatch(updateOrganisation({ ...data, orgId, logo: docUploadData.signedUrl.fields.Key }));
      } else {
        dispatch(updateOrganisation({ ...data, orgId, logo: null }));
      }
    } else {
      if (docUploadData.signedUrl.fields.Key) {
        dispatch(addOrganisation({ ...data, logo: docUploadData.signedUrl.fields.Key }));
      } else {
        dispatch(addOrganisation({ ...data, logo: null }));
      }
    }
  };

  useEffect(() => {
    if (imgSuccess) {
      toast.success(imgSuccess || "Successfully Submitted", {
        duration: 4000,
        position: "top-center",
        className: "toaster successToast",
        icon: null,
      });
    }
    if (imgErr) {
      toast.error(imgErr, {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
    }
    dispatch(resetImageMessage());
  }, [imgErr, imgSuccess, dispatch]);
  useEffect(() => {
    if (docUploadData && uploadImg) {
      dispatch(
        uploadImage({
          ...docUploadData.signedUrl,
          fields: { ...docUploadData.signedUrl.fields, file: uploadImg },
        })
      );
    }
  }, [docUploadData]);
  useEffect(() => {
    if (uploadImg) {
      dispatch(getDocUploadData({}));
    }
  }, [uploadImg]);

  const handleImgChange = (e) => {
    const file = e.target.files[0];
    if (file.type.match(/image/)) {
      setImageUpload(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (el) => {
        const baseImg = el.target.result;
        setSelectedImg(baseImg);
      };
    } else {
      toast.error("Please Select Image", {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
    }
  };

  const handleChange = (e, regex) => {
    setValue(e.target.name, e.target.value.replace(regex, ""), {
      shouldDirty: true,
    });
  };

  return (
    <MaterialDialog
      open={open}
      title={orgId ? "Update Organization" : "Add Organization"}
      content={
        <>
          <Grid container>
            <Grid item xs={12} sm={6} md={6} px={1} py={1}>
              <SuiTextInputField
                name="orgName"
                control={control}
                label="Organization Name"
                req
                error={errors?.orgName}
                onChange={(e) => {
                  handleChange(e, /[^a-zA-Z ]/gi);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} px={1} py={1}>
              <SelectComponent
                name="orgType"
                label="Organization Type"
                control={control}
                options={mapSelectFieldData(["public", "private"])}
                error={errors.orgType}
                req
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={6} sm={6} md={6} px={1} py={1}>
              <SuiTextInputField
                name="department"
                control={control}
                label="Department Name"
                error={errors?.department}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} px={1} py={1}>
              <SuiTextInputField
                name="ministry"
                label="Ministry Name"
                control={control}
                placeholder="(If any)"
                onChange={(e) => {
                  handleChange(e, /[^a-zA-Z ]/gi);
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} sm={6} md={6} px={1} py={1}>
              <SuiTextInputField
                name="website"
                label="Website URL"
                control={control}
                placeholder="https://www.google.com"
                error={errors.website}
                req
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} px={1} py={1}>
              <SuiTextInputField
                name="orgLogo"
                label="Organization Logo"
                control={control}
                type="file"
                error={errors.orgLogo}
                onChange={handleImgChange}
              />
              {imgLoading && (
                <SuiBox mt={-1.2} ml={1}>
                  <SuiTypography variant="caption" fontWeigth="bold" gutterBottom color="dark">
                    Image is loading to be saved...
                  </SuiTypography>
                </SuiBox>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} sm={6} md={6} px={1} py={1}>
              <SuiTextInputField
                name="orgAddress"
                label="Organization Address"
                control={control}
                multiline
                rows={3}
              />
            </Grid>
            {selectedImg || (edit && (org?.logo || selectedImg)) ? (
              <Grid item xs={6} sm={6} md={6} px={2} py={2}>
                <img
                  className="orgLogo"
                  src={selectedImg || org?.logo || null}
                  width="180"
                  height="100"
                  alt="Organization logo"
                />
              </Grid>
            ) : null}
          </Grid>

          <Grid container>
            <Grid xs={12} paddingX={1}>
              <h4 style={{ borderBottom: "1px solid #fbfbfb" }}>Admin</h4>
            </Grid>
            <Grid xs={6} sm={6} md={6} px={1} py={1}>
              <SuiTextInputField
                type="number"
                name="adminCnic"
                control={control}
                label="CNIC"
                error={errors?.adminCnic}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" mb={-2}>
            <Grid item xs={6} sm={6} md={2.5} px={1} py={1}>
              <SuiButton
                onClick={() => {
                  onClose();
                  dispatch(resetOrganisation());
                }}
              >
                Cancel
              </SuiButton>
            </Grid>
            <Grid item xs={6} sm={6} md={2.5} px={1} py={1}>
              <SuiButton
                color="dark"
                onClick={handleSubmit(onAddOrg)}
                size="medium"
                disabled={imgLoading || addLoading}
              >
                {addLoading ? (
                  <CircularProgress size={20} color="white" />
                ) : orgId ? (
                  "Update"
                ) : (
                  "Submit"
                )}
              </SuiButton>
            </Grid>
          </Grid>
        </>
      }
    />
  );
}

export default AddNewOrganisation;
