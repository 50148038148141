import React, { useEffect } from "react";
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";
import SelectComponent from "components/select";
import SuiTextInputField from "components/SuiTextInputField";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  bloodGroups,
  chestSize,
  chestType,
  FITNESS_DEFAULT_VALUES,
  heightFt,
  heightInch,
  weights,
} from "../CvBuilder.constant";
import { restrictInput } from "utils";
import { CircularProgress } from "@mui/material";
import SuiButton from "components/SuiButton";
import { submitFitnessInfo } from "../CvBuilder.thunk";
import { DEFAULT_VALUES, schema } from "./FitnessInfo.constant";
import { resetDirty, setDirty, updateDirtyFitnessInfo } from "../CvBuilder.reducer";

function FitnessInfo() {
  const dispatch = useDispatch();
  const { fitnessFormLoading, resumeInfo, success, resumeId } = useSelector((state) => state.cv);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    watch,
  } = useForm({
    defaultValues: FITNESS_DEFAULT_VALUES,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const subscription = watch((value) => dispatch(updateDirtyFitnessInfo(value)));
    return () => {
      subscription?.unsubscribe();
    };
  }, [watch]);

  useEffect(() => {
    if (isDirty) {
      dispatch(setDirty("medicalInfo"));
    }
  }, [isDirty]);
  useEffect(() => {
    if (success) {
      dispatch(resetDirty("medicalInfo"));
    }
  }, [success]);

  useEffect(() => {
    if (resumeInfo && resumeInfo.medicalInfo) {
      const fitnessInfoData = resumeInfo?.medicalInfo;
      const heightFt = fitnessInfoData.height?.includes(".")
        ? fitnessInfoData.height.split(".")[0]
        : fitnessInfoData.height;
      const heightInches = fitnessInfoData.height?.includes(".")
        ? fitnessInfoData.height.split(".")[1]
        : null;
      reset({
        ...fitnessInfoData,
        heightFt,
        heightInches,
      });
    } else {
      reset(DEFAULT_VALUES);
    }
  }, []);

  const fitnessFormSubmit = (data) => {
    if (resumeId) {
      // const resumeId = queryParams.get("resumeId");
      dispatch(
        submitFitnessInfo({
          bloodGroup: data.bloodGroup,
          chestSize: data.chestSize,
          chestType: data.chestType,
          disability: data.disability,
          height: `${data.heightFt}.${data.heightInches}`,
          weight: data.weight,
          diseases: data.diseases,
          resumeId,
        })
      );
    } else {
      toast.error("Please Fill Personal Info Form First", {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
    }
  };

  return (
    <SuiBox container>
      <Grid container>
        <Grid container>
          <Grid item xs={12} sm={6} md={2.5} lg={1.8} py={1} px={2}>
            <SelectComponent
              label="Height (Feet)"
              name="heightFt"
              control={control}
              options={heightFt}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2.5} lg={1.8} py={1} px={2}>
            <SelectComponent
              label="Height (Inches)"
              name="heightInches"
              control={control}
              options={heightInch}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2.5} lg={1.8} py={1} px={2}>
            <SelectComponent
              name="weight"
              label="Weight (Kg)"
              placeholderText="Select"
              control={control}
              options={weights}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2.6} py={1} px={2}>
            <SelectComponent
              name="bloodGroup"
              placeholderText="Select"
              options={bloodGroups}
              label="Blood Group"
              control={control}
              error={errors?.bloodGroup}
              req
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} px={2} py={1}>
            <SelectComponent
              name="chestType"
              placeholderText="Select"
              options={chestType}
              label="Chest Type"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} px={2} py={1}>
            <SelectComponent
              name="chestSize"
              placeholderText="Select"
              options={chestSize}
              label="Chest Size (Inches)"
              control={control}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4} px={2} py={1}>
          <SelectComponent
            name="disability"
            label="Disability"
            placeholderText="Select (if any)"
            options={[
              { label: "No I don't have any", value: "no" },
              { label: "Yes I do have disability", value: "yes" },
            ]}
            control={control}
            error={errors?.disability}
            req
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} px={2} py={1}>
          <SuiTextInputField
            name="diseases"
            control={control}
            label="Diseases"
            placeholder="Diseases (if any)"
            onKeyPress={(e) => {
              restrictInput(e, /[^a-zA-Z ]/gi);
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end">
        <Grid item mr={2}>
          {fitnessFormLoading ? (
            <CircularProgress color="dark" />
          ) : (
            <SuiButton
              variant="contained"
              color="dark"
              onClick={handleSubmit(fitnessFormSubmit)}
              disabled={!isDirty}
            >
              Save
            </SuiButton>
          )}
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default FitnessInfo;
