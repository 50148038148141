import { createSlice } from "@reduxjs/toolkit";
import {
  addOrganisation,
  getOrganisations,
  removeOrganisation,
  updateOrganisation,
} from "./Organisations.thunk";

const initialState = {
  error: null,
  success: null,
  loading: false,
  addLoading: false,
  org: null,
  data: [],
};

export const OrgSlice = createSlice({
  name: "org",
  initialState,
  reducers: {
    resetMessageState: (state) => {
      state.error = null;
      state.success = null;
    },
    getOrganisation: (state, { payload }) => {
      state.org = state.data.find((org) => {
        return org.orgId === payload;
      });
    },
    resetOrganisation: (state) => {
      state.org = null;
    },
  },
  extraReducers: {
    [getOrganisations.pending]: (state) => {
      state.loading = true;
    },
    [getOrganisations.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.organisations;
    },
    [getOrganisations.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [addOrganisation.pending]: (state) => {
      state.addLoading = true;
    },
    [addOrganisation.fulfilled]: (state, { payload }) => {
      state.addLoading = false;
      state.success = payload;
    },
    [addOrganisation.rejected]: (state, { error }) => {
      state.addLoading = false;
      state.error = error.message;
    },
    [removeOrganisation.pending]: (state) => {
      state.loading = true;
    },
    [removeOrganisation.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = payload.message;
    },
    [removeOrganisation.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [updateOrganisation.pending]: (state) => {
      state.addLoading = true;
    },
    [updateOrganisation.fulfilled]: (state, { payload }) => {
      state.addLoading = false;
      state.success = payload.message;
    },
    [updateOrganisation.rejected]: (state, { error }) => {
      state.addLoading = false;
      state.error = error.message;
    },
  },
});

export const { resetMessageState, getOrganisation, resetOrganisation } = OrgSlice.actions;

export default OrgSlice.reducer;
