import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiBadge from "components/SuiBadge";
import SuiButton from "components/SuiButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";
import { useDispatch, useSelector } from "react-redux";
import { getJobsData } from "./Jobs.thunk";
import JobDetails from "./Jobs.details";
import toast from "react-hot-toast";
import { resetMessageState } from "./Jobs.reducer";
import Footer from "examples/Footer";
import AlertDialogue from "components/alertDialogue";

function Jobs() {
  const history = useNavigate();
  const { search } = useLocation();
  const queryParam = new URLSearchParams(search);
  const { loading, data, error, success } = useSelector((state) => state.jobs);
  const { role } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [applyModalVisible, setApplyModalVisible] = useState(false);
  const [applyAgain, setApplyAgain] = useState({ visible: false, jobId: null });
  const [tableColumns, setTableColumns] = useState([
    { name: "job Title", align: "center" },
    { name: "type", align: "center" },
    { name: "apply Last Date", align: "left" },
    { name: "grade", align: "center" },
    { name: "vacancies", align: "center" },
    { name: "experience Duration", align: "center" },
    { name: "max Age", align: "center" },
    { name: "field Of Experience", align: "center" },
    { name: "status", align: "center" },
  ]);

  const onApply = (jobId, hasApplied) => {
    if (hasApplied) {
      setApplyAgain({ visible: true, jobId });
    } else {
      history(`?jobId=${jobId}`);
    }
  };
  const onCloseModal = () => {
    history("/jobs");
  };

  const onApplyAgain = () => {
    history(`?jobId=${applyAgain.jobId}`);
    setApplyAgain({ visible: false, jobId: null });
  };
  const onCancelApplyAgain = () => {
    setApplyAgain({ visible: false, jobId: null });
  };

  useEffect(() => {
    if (role === "user") {
      const columnCopy = [...tableColumns];
      setTableColumns([...columnCopy, { name: "apply", align: "center" }]);
    }
  }, [role]);

  useEffect(() => {
    dispatch(getJobsData());
  }, [dispatch, success]);

  useEffect(() => {
    if (queryParam.get("jobId")) {
      setApplyModalVisible(true);
    } else {
      setApplyModalVisible(false);
    }
  }, [queryParam]);

  useEffect(() => {
    if (success) {
      toast.success(success || "Successfully Submitted", {
        duration: 4000,
        position: "top-center",
        className: "toaster successToast",
        icon: null,
      });
      onCloseModal();
      dispatch(resetMessageState());
    }
    if (error) {
      toast.error(error, {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
      dispatch(resetMessageState());
    }
  }, [error, success]);

  const jobsTableData = {
    columns: [...tableColumns],

    rows: data.map((job) => {
      return {
        "job Title": (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {job.jobTitle}
          </SuiTypography>
        ),
        type: (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {job.employmentType}
          </SuiTypography>
        ),
        "apply Last Date": (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {job.deadLine}
          </SuiTypography>
        ),
        grade: (
          <SuiBadge variant="contained" color="secondary" badgeContent={job.grade} size="lg" />
        ),
        vacancies: (
          <SuiBadge variant="contained" color="secondary" badgeContent={job.vacancies} size="lg" />
        ),
        "experience Duration": (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {job.experienceDuration}
          </SuiTypography>
        ),

        "max Age": (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {job.maxAge || job.ageLimit}
          </SuiTypography>
        ),
        fieldOfExperience: (
          <SuiTypography>
            {job?.fieldOfExperience?.map((e) => {
              return <SuiBadge badgeContent={e} color="secondary" size="sm" />;
            })}
          </SuiTypography>
        ),
        status: (
          <SuiBadge
            color={job.statusReason === "active" ? "success" : "secondary"}
            badgeContent={job.statusReason}
          />
        ),
        apply: (
          <SuiButton
            variant="contained"
            color="dark"
            size="lg"
            onClick={() => {
              onApply(job.jobId, job.hasApplied);
            }}
          >
            Apply
          </SuiButton>
        ),
      };
    }),
  };
  const { columns, rows } = jobsTableData;

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <SuiBox mb={3}>
            <Card>
              <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SuiTypography variant="h6">Active Jobs</SuiTypography>
              </SuiBox>

              <SuiBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                {loading ? (
                  <SuiBox px={2}>
                    <Grid item>
                      <Skeleton animation="wave" height={70} />
                    </Grid>
                    <Grid item>
                      <Skeleton height={70} />
                    </Grid>
                    <Grid item>
                      <Skeleton animation="wave" height={70} />
                    </Grid>
                    <Grid item>
                      <Skeleton height={70} />
                    </Grid>
                    <Grid item>
                      <Skeleton height={70} pb={2} />
                    </Grid>
                  </SuiBox>
                ) : (
                  <Table columns={columns} rows={rows} />
                )}
              </SuiBox>
            </Card>
          </SuiBox>
        </SuiBox>
        <Footer company={{ name: "Panoptic Solutions" }} />
        <JobDetails applyModalVisible={applyModalVisible} onCloseModal={onCloseModal} apply />
        <AlertDialogue
          open={applyAgain.visible}
          content="You have already applied to this job do you want to apply again?"
          agreeBtnText="Apply"
          agreeBtnClr="dark"
          disagreeBtnText="Cancel"
          onCancel={onCancelApplyAgain}
          onApprove={onApplyAgain}
        />
      </DashboardLayout>
    </>
  );
}

export default Jobs;
