import { createSlice } from "@reduxjs/toolkit";
import { applyJob, getJobDetails, getJobsData, submitWithMeraid } from "./Jobs.thunk";

const initialState = {
  error: null,
  success: null,
  loading: false,
  detailsLoading: false,
  meraidLoading: false,
  jobDetails: null,
  data: [],
};

export const JobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    resetMessageState: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: {
    [getJobsData.pending]: (state) => {
      state.loading = true;
    },
    [getJobsData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [getJobsData.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [applyJob.pending]: (state) => {
      state.loading = true;
    },
    [applyJob.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = payload;
    },
    [applyJob.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [getJobDetails.pending]: (state) => {
      state.detailsLoading = true;
    },
    [getJobDetails.fulfilled]: (state, { payload }) => {
      state.detailsLoading = false;
      state.jobDetails = payload;
    },
    [getJobDetails.rejected]: (state, { error }) => {
      state.detailsLoading = false;
      state.error = error.message;
    },
    [submitWithMeraid.pending]: (state) => {
      state.meraidLoading = true;
    },
    [submitWithMeraid.fulfilled]: (state, { payload }) => {
      state.meraidLoading = false;
      state.success = payload.message;
    },
    [submitWithMeraid.rejected]: (state, { error }) => {
      state.meraidLoading = false;
      state.error = error.message;
    },
  },
});

export const { resetMessageState } = JobsSlice.actions;

export default JobsSlice.reducer;
