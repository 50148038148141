import { createSlice } from "@reduxjs/toolkit";
import { getDocUploadData, uploadImage } from "./PersonalInfo.thunk";

const initialState = {
  imgLoading: false,
  imgErr: null,
  imgSuccess: null,
  docUploadData: null,
};

export const PersonalInfoSlice = createSlice({
  name: "personalInfo",
  initialState,
  reducers: {
    resetMessageState: (state) => {
      state.imgSuccess = null;
      state.imgErr = null;
    },
  },
  extraReducers: {
    [getDocUploadData.pending]: (state) => {
      state.imgLoading = true;
    },
    [getDocUploadData.fulfilled]: (state, { payload }) => {
      state.imgLoading = false;
      state.docUploadData = payload;
    },
    [getDocUploadData.rejected]: (state, { error }) => {
      state.imgLoading = false;
      state.imgErr = error.message;
    },
    [uploadImage.pending]: (state) => {
      state.imgLoading = true;
    },
    [uploadImage.fulfilled]: (state, { payload }) => {
      state.imgLoading = false;
      state.imgSuccess = payload;
    },
    [uploadImage.rejected]: (state, { error }) => {
      state.imgLoading = false;
      //  The S3 url does not return anything as response it just sends 204 status code which means the file is uploaded(We have checked the bucket it is uploading the image).There was no other way currently.Server cancels the axios post request as the interceptor intercepts the request and attatch headers with it. Right now we are checking ig the error is === Failed to fetch then out file is uploading and the status code is 204
      if (
        error.message === "Failed to fetch" ||
        error.message === "NetworkError when attempting to fetch resource."
      ) {
        state.imgSuccess = "Image is ready to be saved";
      } else {
        state.imgErr = error.message;
      }
    },
  },
});

export const { resetPersistedState, setChanged, resetMessageState } = PersonalInfoSlice.actions;

export default PersonalInfoSlice.reducer;
