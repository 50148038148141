import * as yup from "yup";

export const schema = yup.object().shape({
  applicantGroup: yup.array().min(1).required(),
  sms: yup.bool().required(),
  email: yup.bool().notRequired(),
  inAppNotification: yup.bool().required(),
  message: yup.string().required(),
});

export const addGroupSchema = yup.object().shape({
  groupName: yup.string().required(),
});

export const DEFAULT_VALUES = {
  applicantGroup: "",
  sms: false,
  email: false,
  inAppNotification: false,
  message: "",
};

export const ADD_GROUP_DEFAULT_VALUES = {
  groupName: "",
};
