import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SERVER_URL } from "constants";
import { getUserId } from "utils";

export const getOrganisations = createAsyncThunk("GET_ORGANISATIONS", async () => {
  try {
    const { data } = await axios.get(`${SERVER_URL}/orgs`);
    return data;
  } catch (err) {
    throw err.response.data;
  }
});

export const addOrganisation = createAsyncThunk("ADD_ORGANISATIONS", async (obj) => {
  try {
    const { data } = await axios.post(`${SERVER_URL}/org`, obj);
    return data.message;
  } catch (err) {
    throw err.response.data;
  }
});

export const removeOrganisation = createAsyncThunk("REMOVE_ORGANISATION", async (orgId) => {
  try {
    const { data } = await axios.delete(`${SERVER_URL}/org/${orgId}`);
    return data;
  } catch (err) {
    throw err.response.data;
  }
});

export const updateOrganisation = createAsyncThunk("UPDATE_ORGANISATION", async (obj) => {
  try {
    const userId = getUserId();
    const { data } = await axios.put(`${SERVER_URL}/org/${obj.orgId}`, obj);
    return data;
  } catch (err) {
    throw err.response.data;
  }
});
