import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import theme from "assets/theme";
import { CacheProvider } from "@emotion/react";
import routes from "routes";
import { useSoftUIController, setMiniSidenav } from "context";
import brand from "assets/images/jobapp.png";
import PrivateRoute from "routes/PrivateRoute";
import PublicRoute from "routes/PublicRoute";
import { useSelector } from "react-redux";
import SwitchPage from "layouts/authentication/switch.page";

export default function App() {
  const { role } = useSelector((state) => state.auth);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  // const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const filteredRoutes = routes.filter((r) => {
    return r.role.includes(role || "user");
  });

  // Cache for the rtl
  // useMemo(() => {
  //   const cacheRtl = createCache({
  //     key: "rtl",
  //     stylisPlugins: [rtlPlugin],
  //   });
  //   setRtlCache(cacheRtl);
  // }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.privacy === "private") {
        return (
          <Route
            exact
            path={route.route}
            element={<PrivateRoute>{route.component}</PrivateRoute>}
            key={route.key}
          />
        );
      } else {
        return (
          <Route
            exact
            path={route.route}
            element={<PublicRoute>{route.component}</PublicRoute>}
            key={route.key}
          />
        );
      }
    });
  return direction === "rtl" ? (
    <CacheProvider>
      <ThemeProvider>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName="Job App"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        <Routes>
          {getRoutes(routes)}
          <Route
            path="*"
            element={role === "admin" ? <Navigate to="/jobs" /> : <Navigate to="/cv-builder" />}
          />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="Job App"
            routes={filteredRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      <Toaster />
      <Routes>
        {getRoutes(filteredRoutes)}
        <Route path="/" element={<Navigate to="/authentication/sign-in" />} />
        <Route path="/auth/switch" element={<SwitchPage />} />
        <Route
          path="*"
          element={
            role === "admin" ? (
              <Navigate to="/jobs" />
            ) : role == "user" ? (
              <Navigate to="/cv-builder" />
            ) : (
              <Navigate to="/auth/switch" />
            )
          }
        />
      </Routes>
    </ThemeProvider>
  );
}
