import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiBadge from "components/SuiBadge";
import SuiButton from "components/SuiButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";
import { useDispatch, useSelector } from "react-redux";
import Footer from "examples/Footer";
import { Icon } from "@mui/material";
import { getJobsData } from "layouts/jobs/Jobs.thunk";
import UpdateJob from "./AllJobs.update";
import toast from "react-hot-toast";
import { resetMessageState } from "layouts/jobs/Jobs.reducer";
import { resetMessageState as allJobsReset } from "./AllJobs.reducer";
import AlertDialogue from "components/alertDialogue";
import { deleteJob } from "./AllJobs.thunk";

function AllJobs() {
  const history = useNavigate();
  const { search } = useLocation();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [jobId, setJobId] = useState("");
  const queryParam = new URLSearchParams(search);
  const { loading, data, error, success } = useSelector((state) => state.jobs);
  const {
    success: updateSuccess,
    error: updateError,
    deleteLoading,
  } = useSelector((state) => state.allJobs);

  const dispatch = useDispatch();

  const onDelete = (jobId) => {
    setJobId(jobId);
    setDeleteModalVisible(true);
  };
  const onApproveDeleteJob = () => {
    dispatch(deleteJob(jobId));
    setDeleteModalVisible(false);
  };
  const onEdit = (jobId) => {
    history(`?jobId=${jobId}`);
  };
  const onCloseModal = () => {
    history("/all-jobs");
    setEditModalVisible(false);
    setDeleteModalVisible(false);
  };

  useEffect(() => {
    if (queryParam.has("jobId")) {
      setEditModalVisible(true);
    } else {
      setEditModalVisible(false);
    }
  }, [queryParam]);
  useEffect(() => {
    dispatch(getJobsData("all"));
  }, [dispatch]);

  useEffect(() => {
    if (success || updateSuccess) {
      toast.success(success || updateSuccess || "Successfully Submitted", {
        duration: 4000,
        position: "top-center",
        className: "toaster successToast",
        icon: null,
      });
      onCloseModal();
      dispatch(resetMessageState());
      dispatch(allJobsReset());
      dispatch(getJobsData("all"));
    }
    if (error || updateError) {
      toast.error(error || updateError, {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
      dispatch(resetMessageState());
      dispatch(allJobsReset());
    }
  }, [error, success, updateSuccess, updateError]);

  const allJobsData = {
    columns: [
      { name: "job Title", align: "left" },
      { name: "type", align: "center" },
      { name: "publish Date", align: "center" },
      { name: "apply Last Date", align: "left" },
      { name: "grade", align: "center" },
      { name: "vacancies", align: "center" },
      { name: "experience Duration", align: "center" },
      //   { name: "maxAge", align: "center" },
      { name: "field Of Experience", align: "center" },
      { name: "status", align: "center" },
      { name: "action", align: "center" },
      { name: "Download Applications", align: "center" },
    ],

    rows: data.map((job, index) => {
      return {
        "job Title": (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {job.jobTitle}
          </SuiTypography>
        ),
        type: (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {job.employmentType}
          </SuiTypography>
        ),
        "publish Date": (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {job.publishDate}
          </SuiTypography>
        ),
        "apply Last Date": (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {job.deadLine}
          </SuiTypography>
        ),
        grade: (
          <SuiBadge variant="contained" color="secondary" badgeContent={job.grade} size="lg" />
        ),
        vacancies: (
          <SuiBadge variant="contained" color="secondary" badgeContent={job.vacancies} size="lg" />
        ),
        "experience Duration": (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {job.experienceDuration}
          </SuiTypography>
        ),

        // maxAge: (
        //   <SuiTypography variant="caption" color="secondary" fontWeight="medium">
        //     {job.maxAge}
        //   </SuiTypography>
        // ),

        fieldOfExperience: (
          <SuiTypography>
            {job?.fieldOfExperience?.map((e) => {
              return <SuiBadge badgeContent={e} color="secondary" size="sm" />;
            })}
          </SuiTypography>
        ),
        status: (
          <SuiBadge
            color={job.statusReason === "active" ? "success" : "secondary"}
            badgeContent={job.statusReason}
          />
        ),
        action: (
          <>
            <Grid container>
              <Grid item mr={0.5}>
                <SuiButton
                  color="dark"
                  size="lg"
                  disabled={job.statusReason === "active"}
                  onClick={() => {
                    onEdit(job.jobId);
                  }}
                >
                  <Icon fontSize="large">edit</Icon>
                </SuiButton>
              </Grid>
              <Grid item>
                <SuiButton
                  color="error"
                  size="lg"
                  onClick={() => {
                    onDelete(job.jobId);
                  }}
                >
                  <Icon fontSize="large">delete_sweep</Icon>
                </SuiButton>
              </Grid>
            </Grid>
          </>
        ),
        "Download Applications": (
          <SuiTypography
            component="a"
            href={`data:text/csv;charset=utf-8,${escape(`Applications \n ${"name, Behram"}`)}`}
            rel="noreferrer"
            variant="h6"
            textTransform="capitalize"
            color="success"
          >
            <Icon fontSize="medium">download</Icon>
          </SuiTypography>
        ),
      };
    }),
  };
  const { columns, rows } = allJobsData;

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <SuiBox mb={3}>
            <Card>
              <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SuiTypography variant="h6">All Jobs</SuiTypography>
              </SuiBox>

              <SuiBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                {loading || deleteLoading ? (
                  <SuiBox px={2}>
                    <Grid item>
                      <Skeleton animation="wave" height={70} />
                    </Grid>
                    <Grid item>
                      <Skeleton height={70} />
                    </Grid>
                    <Grid item>
                      <Skeleton animation="wave" height={70} />
                    </Grid>
                    <Grid item>
                      <Skeleton height={70} />
                    </Grid>
                    <Grid item>
                      <Skeleton height={70} pb={2} />
                    </Grid>
                  </SuiBox>
                ) : (
                  <Table columns={columns} rows={rows} />
                )}
              </SuiBox>
            </Card>
          </SuiBox>
        </SuiBox>
        <UpdateJob open={editModalVisible} onClose={onCloseModal} />
        <AlertDialogue
          open={deleteModalVisible}
          content="are your sure you want to delete this job"
          agreeBtnText="Delete"
          disagreeBtnText="Cancel"
          onCancel={onCloseModal}
          onApprove={onApproveDeleteJob}
        />
        <Footer company={{ name: "Panoptic Solutions" }} />
      </DashboardLayout>
    </>
  );
}

export default AllJobs;
