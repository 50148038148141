import SuiInput from "components/SuiInput";
import { Controller } from "react-hook-form";
import { Grid, Icon } from "@mui/material";

function SuiTextInputField({
  placeholder,
  label,
  type,
  className = "",
  control,
  name,
  req,
  errors,
  verified,
  ...rest
}) {
  return (
    <>
      <div className="text-field">
        <Grid container justifyContent="space-between">
          <Grid item>
            <label htmlFor={name}>{label} </label>
            {verified && (
              <span className="multiple-placeholder">
                -Verified <Icon color="success">checkmark</Icon>
              </span>
            )}
          </Grid>
          <Grid item>{req && <label>*</label>}</Grid>
        </Grid>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <SuiInput placeholder={placeholder} {...field} {...rest} type={type} id={name} />
          )}
        />
        {errors && <span className="error-msg">{errors}!</span>}
      </div>
    </>
  );
}
export default SuiTextInputField;
