import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SERVER_URL } from "constants";
// import { getUserId } from "utils";

export const updateJob = createAsyncThunk("UPDATE_JOB", async (obj) => {
  try {
    // const userId = getUserId();
    const { data } = await axios.put(`${SERVER_URL}/jobs/${obj.jobId}`, obj);
    return data;
  } catch (err) {
    throw err.response.data;
  }
});

export const deleteJob = createAsyncThunk("DELETE_JOB", async (jobId) => {
  try {
    // const userId = getUserId();
    const { data } = await axios.delete(`${SERVER_URL}/jobs/${jobId}`);
    return data;
  } catch (err) {
    throw err.response.data;
  }
});
