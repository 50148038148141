import { createSlice } from "@reduxjs/toolkit";
import { deleteJob, updateJob } from "./AllJobs.thunk";

const initialState = {
  error: null,
  success: null,
  loading: false,
  deleteLoading: false,
};

export const AllJobsSlice = createSlice({
  name: "allJobs",
  initialState,
  reducers: {
    resetMessageState: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: {
    [updateJob.pending]: (state) => {
      state.loading = true;
    },
    [updateJob.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = payload.message;
    },
    [updateJob.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [deleteJob.pending]: (state) => {
      state.deleteLoading = true;
    },
    [deleteJob.fulfilled]: (state, { payload }) => {
      state.deleteLoading = false;
      state.success = payload.message;
    },
    [deleteJob.rejected]: (state, { error }) => {
      state.deleteLoading = false;
      state.error = error.message;
    },
  },
});

export const { resetMessageState } = AllJobsSlice.actions;

export default AllJobsSlice.reducer;
