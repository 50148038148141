import { createSlice } from "@reduxjs/toolkit";
import { createJob } from "./JobBuilder.thunk";

const initialState = {
  error: null,
  success: null,
  loading: false,
};

export const JobBuilderSlice = createSlice({
  name: "jobBuilder",
  initialState,
  reducers: {
    resetMessageState: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: {
    [createJob.pending]: (state) => {
      state.loading = true;
    },
    [createJob.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = payload;
    },
    [createJob.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export const { resetMessageState } = JobBuilderSlice.actions;

export default JobBuilderSlice.reducer;
