import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { Controller } from "react-hook-form";
import SuiTypography from "components/SuiTypography";

function CheckBox({ label, control, name, checked }) {
  return (
    <Grid container>
      {label && (
        <Grid item px={1}>
          <SuiTypography variant="caption" color="dark">
            {label}
          </SuiTypography>
        </Grid>
      )}
      <Grid item>
        <Controller
          name={name}
          control={control}
          render={({ field }) => <Checkbox {...field} checked={checked} />}
        />
      </Grid>
    </Grid>
  );
}

export default CheckBox;
