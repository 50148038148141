import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getUserServerUrl } from "constants";
import { getJobsServerUrl } from "constants";
import { getUserId } from "utils";

// eslint-disable-next-line no-promise-executor-return
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const collectApi = async (requestId) => {
  await delay(3000);
  const userId = getUserId();
  const { data } = await axios.post(
    `${getUserServerUrl(userId)}/apply-with-meraid-status`,
    requestId
  );
  if (data.message === "failed") {
    throw "Failed";
  } else if (data.message === "pending") {
    return collectApi(requestId);
  }
  return data;
};

export const getJobsData = createAsyncThunk("GET_JOBS_DATA", async (type) => {
  try {
    const userId = getUserId();
    const { data } = await axios.get(`${getJobsServerUrl(userId)}?type=${type || "active"}`);
    const mappedData = data.map((job) => {
      return {
        ...job,
        fieldOfExperience:
          typeof job.fieldOfExperience === "string"
            ? job.fieldOfExperience.split(",")
            : job.fieldOfExperience,
      };
    });

    return mappedData;
  } catch (err) {
    throw err.response.data;
  }
});

export const applyJob = createAsyncThunk("APPLY_JOB", async (obj) => {
  try {
    const userId = getUserId();
    const { data } = await axios.post(`${getJobsServerUrl(userId)}/${obj.jobId}/apply`, obj);

    return data.message;
  } catch (err) {
    throw err.response.data;
  }
});

export const getJobDetails = createAsyncThunk("GET_JOB_DETAILS", async (jobId) => {
  try {
    const userId = getUserId();
    const { data } = await axios.get(`${getJobsServerUrl(userId)}/${jobId}`);
    return data;
  } catch (err) {
    throw err.response.data;
  }
});

export const submitWithMeraid = createAsyncThunk("SUBMIT_WITH_MERAID", async (obj) => {
  try {
    const userId = getUserId();
    const { data } = await axios.post(
      `${getJobsServerUrl(userId)}/${obj.jobId}/apply-with-meraid`,
      obj
    );
    const { requestId } = data;
    const response = await collectApi({ requestId });
    return response;
  } catch (err) {
    throw err.response;
  }
});
