import moment from "moment";

export const removeUndefine = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
};

export const mapSelectFieldData = (data) => {
  return data
    ? data.map((item) => {
        return {
          label: item,
          value: item,
        };
      })
    : [];
};

export const restrictInput = (el, regex) => {
  if (regex.test(el.key)) {
    el.preventDefault();
  }
};

export const getUserId = () => {
  return localStorage.getItem("userId");
};

export const formateDate = (val) => {
  return !val ? "" : moment(val).format("YYYY-MM-DD");
};
