import {
  Avatar,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import axios from "axios";
import { SERVER_URL } from "constants";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

const SwitchPage = () => {
  const navigate = useNavigate();

  const {
    jwt: { token },
    role,
  } = useSelector((store) => store.auth);
  const [authData, setAuthData] = React.useState({});

  const authInit = async () => {
    if (!token) return navigate("/authentication/sign-in");
    try {
      const { data } = await axios.get(`${SERVER_URL}/auth/init`);
      setAuthData(data);
    } catch (e) {}
  };

  React.useEffect(() => {
    authInit();
  }, []);

  const _onOrgSelect = (orgId) => {
    window.location.href = `https://ats.jobapp.pk/?orgId=${orgId}&token=${token}`;
  };
  if (!token) return <Navigate to="/authentication/sign-in" />;

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 99999999,
        width: "100vw",
        height: "100vh",
      }}
    >
      <Card sx={{ width: "30rem", padding: "1rem" }}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Switch Account
          </Typography>
          <List sx={{ width: "100%" }}>
            {role == "admin" && (
              <ListItem
                alignItems="center"
                sx={{ marginBottom: ".5rem" }}
                onClick={() => navigate("/jobs")}
              >
                <ListItemAvatar>
                  <Avatar />
                </ListItemAvatar>
                <ListItemText primary="Admin" />
              </ListItem>
            )}
            {role == "user" && (
              <ListItem
                alignItems="center"
                sx={{ marginBottom: ".5rem" }}
                onClick={() => navigate("/cv-builder")}
              >
                <ListItemAvatar>
                  <Avatar />
                </ListItemAvatar>
                <ListItemText primary="User" />
              </ListItem>
            )}

            {authData.orgs !== undefined && (
              <>
                <Typography variant="body2" fontWeight={500} gutterBottom>
                  Organizations
                </Typography>
                {authData.orgs.map((entry) => (
                  <ListItem
                    sx={{ marginBottom: ".5rem" }}
                    alignItems="center"
                    onClick={() => _onOrgSelect(entry.orgId)}
                  >
                    <ListItemAvatar>
                      <Avatar />
                    </ListItemAvatar>
                    <ListItemText primary={entry.orgName} />
                  </ListItem>
                ))}
              </>
            )}
          </List>
        </CardContent>
      </Card>
    </div>
  );
};

export default SwitchPage;
