import * as yup from "yup";

export const schema = yup.object({
  fieldOfExperience: yup.string().trim().required(),
  organizationName: yup.string().trim().required(),
  organizationNumber: yup.string().min(10).max(11).required(),
  designation: yup.string().trim().required(),
  currentlyWorking: yup.bool(),
  enrollment: yup.string(),
  startDate: yup.date().max(new Date(), "Start date cannot be in the future").required(),
  endDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .min(yup.ref("startDate"), "End date should be ahead of start date"),
});

export const DEFAULT_VALUES = {
  fieldOfExperience: "",
  organizationName: "",
  organizationNumber: "",
  designation: "",
  currentlyWorking: false,
  startDate: "",
  endDate: "",
  enrollment: "",
};
