import { Controller } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Grid } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import { border } from "@mui/system";

function EditorComponent({ control, name, label, defaultValue, req, error }) {
  ClassicEditor.defaultConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "insertTable",
        "|",
        "|",
        "link",
        "|",
        "undo",
        "redo",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    language: "en",
  };
  return (
    <div className="text-field">
      <Grid container flexDirection="column">
        <Grid item>
          <Grid container justifyContent="space-between">
            {label && (
              <Grid item>
                <label>{label}</label>
              </Grid>
            )}
            <Grid item>{req && <label>*</label>}</Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <CKEditor
                style={{ border: "red" }}
                editor={ClassicEditor}
                defaultValue={defaultValue || ""}
                data={field.value}
                onChange={(e, editor) => {
                  const editorData = editor.getData();
                  field.onChange(editorData);
                }}
                onReady={(editor) => {
                  editor.ui.view.editable.element.style.maxWidth = "auto";
                }}
              />
            )}
          />
          {error && (
            <SuiBox ml={0.8} mt={-1}>
              <SuiTypography variant="caption" color="error">
                {error?.message?.toUpperCase()}
              </SuiTypography>
            </SuiBox>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default EditorComponent;
