import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { CvSlice } from "layouts/cv-builder/CvBuilder.reducer";
import { JobsSlice } from "layouts/jobs/Jobs.reducer";
import { AuthSlice } from "layouts/authentication/auth.reducer";
import { HistorySlice } from "layouts/history/history.reducer";
import { JobBuilderSlice } from "layouts/job-builder/JobBuilder.reducer";
import { OrgSlice } from "layouts/organisations/Organisations.reducer";
import { ProfileSlice } from "layouts/profile/Profile.reducer";
import { AllJobsSlice } from "layouts/all-jobs/AllJobs.reducer";
import { PersonalInfoSlice } from "layouts/cv-builder/personalInfo/personalInfo.reducer";
import { ApplicantCommunicationSlice } from "layouts/applicant-communication/ApplicantCommunication.reducer";

const reducers = combineReducers({
  auth: AuthSlice.reducer,
  cv: CvSlice.reducer,
  personalInfo: PersonalInfoSlice.reducer,
  jobs: JobsSlice.reducer,
  history: HistorySlice.reducer,
  jobBuilder: JobBuilderSlice.reducer,
  org: OrgSlice.reducer,
  profile: ProfileSlice.reducer,
  allJobs: AllJobsSlice.reducer,
  applicantCommunication: ApplicantCommunicationSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
