import * as yup from "yup";

const urlRegex = /[a-zA-Z0-9-]{0,61}[a-zA-Z0-9](\.[a-zA-Z]{2,})+$/;
export const schema = yup.object().shape({
  orgName: yup.string().trim().required(),
  orgType: yup.string().trim(),
  department: yup.string().trim(),
  ministry: yup.string().notRequired(),
  website: yup.string().matches(urlRegex, "Not a valid URL").required(),
  orgAddress: yup.mixed().notRequired(),
  adminCnic: yup.string().length(13, "Invalid Cnic Number").required(),
});

export const DEFAULT_VALUES = {
  orgName: "",
  orgType: "",
  department: "",
  ministry: "",
  website: "",
  orgLogo: "",
  orgAddress: "",
  adminCnic: "",
};
