import { yupResolver } from "@hookform/resolvers/yup";
import { Close } from "@mui/icons-material";
import { CircularProgress, Grid } from "@mui/material";
import SelectCreateable from "components/createable";
import MaterialDialog from "components/Dialog";
import EditorComponent from "components/editor";
import SelectComponent from "components/select";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTextInputField from "components/SuiTextInputField";
import SuiTypography from "components/SuiTypography";
import { fieldOfExperience } from "layouts/cv-builder/CvBuilder.constant";
import { stationForTest } from "layouts/cv-builder/CvBuilder.constant";
import { schema } from "layouts/job-builder/JobBuilder.constant";
import { requiredMaxAge } from "layouts/job-builder/JobBuilder.constant";
import { requiredMinAge } from "layouts/job-builder/JobBuilder.constant";
import { experienceDuration } from "layouts/job-builder/JobBuilder.constant";
import { educations } from "layouts/job-builder/JobBuilder.constant";
import { DEFAULT_VALUES } from "layouts/job-builder/JobBuilder.constant";
import { getJobDetails } from "layouts/jobs/Jobs.thunk";
import { getOrganisations } from "layouts/organisations/Organisations.thunk";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { formateDate } from "utils";
import { mapSelectFieldData } from "utils";
import { updateJob } from "./AllJobs.thunk";

function UpdateJob({ open, onClose }) {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const jobId = new URLSearchParams(search).get("jobId");
  const { loading } = useSelector((state) => state.allJobs);
  const { jobDetails } = useSelector((state) => state.jobs);
  const { data, loading: orgLoading } = useSelector((state) => state.org);

  const closeModal = () => {
    onClose();
    reset(DEFAULT_VALUES);
  };

  useEffect(() => {
    if (jobId) {
      dispatch(getJobDetails(jobId));
      dispatch(getOrganisations());
    }
  }, [jobId]);
  useEffect(() => {
    if (jobDetails) {
      reset({
        ...jobDetails,
        ...jobDetails.jobInfo,
        ...jobDetails.jobRequirements,
        testCenters: mapSelectFieldData([...jobDetails.testCenters]),
        fieldOfExperience: mapSelectFieldData([...jobDetails.jobRequirements.fieldOfExperience]),
        skills: mapSelectFieldData([...jobDetails.skills]),
      });
    }
  }, [jobDetails]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: "all",
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (errors && Object.keys(errors).length) {
      toast.error("Form Contains Invalid Fields", {
        duration: 2000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
    }
  }, [errors]);

  const onUpdate = (data) => {
    dispatch(
      updateJob({
        ...data,
        fieldOfExperience: data.fieldOfExperience.map((e) => {
          return e.value;
        }),
        skills: data.skills.map((s) => {
          return s.value;
        }),
        testCenters: data.testCenters.map((t) => {
          return t.value;
        }),
        jobId: jobId,
        publishDate: formateDate(data.publishDate),
        expiryDate: formateDate(data.expiryDate),
      })
    );
  };

  const handleChange = (e, regex) => {
    setValue(e.target.name, e.target.value.replace(regex, ""), {
      shouldDirty: true,
    });
  };

  return (
    <MaterialDialog
      fullScreen
      open={open}
      title={
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>Update Job</Grid>
          <Grid item>
            <Close onClick={closeModal} />
          </Grid>
        </Grid>
      }
      content={
        <>
          <SuiBox py={2}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4} px={2} py={1}>
                <Grid container flexDirection="column">
                  <Grid item>
                    <SuiTextInputField
                      name="jobTitle"
                      label="Job Title"
                      control={control}
                      req
                      error={errors.jobTitle}
                      onChange={(e) => {
                        handleChange(e, /[^A-Za-z ]/gi);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <SuiTextInputField
                      name="location"
                      label="Location"
                      control={control}
                      req
                      error={errors.location}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} px={2} py={1}>
                <EditorComponent
                  label="Description"
                  name="description"
                  error={errors.description}
                  control={control}
                  req
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} md={4} px={2} py={1}>
                <SuiTextInputField
                  name="vacancies"
                  label="No Of Vacancies"
                  type="number"
                  control={control}
                  req
                  error={errors.vacancies}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} px={2} py={1}>
                <SuiTextInputField
                  name="publishDate"
                  label="Publish Date"
                  type="date"
                  control={control}
                  req
                  error={errors.publishDate}
                />
                {errors.publishDate && (
                  <SuiBox ml={1} mt={-1}>
                    <SuiTypography variant="caption" color="error">
                      {errors?.publishDate.message}
                    </SuiTypography>
                  </SuiBox>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} px={2} py={1}>
                <SuiTextInputField
                  name="expiryDate"
                  label="Expiry Date"
                  type="date"
                  control={control}
                  req
                  error={errors.expiryDate}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} md={4} px={2} py={1}>
                <SelectComponent
                  name="orgId"
                  label="Employer Organization Name"
                  control={control}
                  options={data.map((org) => {
                    return { label: org.orgName, value: org.orgId };
                  })}
                  req
                  error={errors.orgName}
                  isLoading={orgLoading}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} px={2} py={1}>
                <SuiTextInputField
                  name="grade"
                  label="Grade"
                  control={control}
                  req
                  error={errors.grade}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} px={2} py={1}>
                <SuiTextInputField
                  name="salary"
                  label="Salary In PKR"
                  control={control}
                  error={errors.salary}
                  onChange={(e) => {
                    handleChange(e, /[^0-9]/gi);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} md={4} px={2} py={1}>
                <SelectComponent
                  name="jobType"
                  label="Job Type"
                  options={[
                    {
                      label: "On Site",
                      value: "onSite",
                    },
                    {
                      label: "Remote",
                      value: "Remote",
                    },
                  ]}
                  control={control}
                  req
                  error={errors.jobType}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} px={2} py={1}>
                <SelectComponent
                  name="shift"
                  label="Shift"
                  options={[
                    {
                      label: "Morning",
                      value: "m",
                    },
                    {
                      label: "Evening",
                      value: "e",
                    },
                  ]}
                  control={control}
                  error={errors.shift}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={2} px={2} py={1}>
                <SelectComponent
                  name="minAge"
                  label="Min Age"
                  options={mapSelectFieldData(requiredMinAge)}
                  control={control}
                  req
                  error={errors.minAge}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={2} px={2} py={1}>
                <SelectComponent
                  name="maxAge"
                  label="Max Age"
                  options={mapSelectFieldData(requiredMaxAge)}
                  control={control}
                  req
                  error={errors.maxAge}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={4} px={2} py={1}>
                <SelectComponent
                  name="education"
                  label="Required Education"
                  options={educations}
                  control={control}
                  req
                  error={errors.education}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} px={2} py={1}>
                <SelectCreateable
                  isMulti
                  name="skills"
                  label="Required Skills"
                  placeholderText="Create"
                  control={control}
                  error={errors.skills}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} px={2} py={1}>
                <SelectCreateable
                  isMulti
                  name="fieldOfExperience"
                  label="Required Experience Field"
                  placeholderText="Select"
                  options={fieldOfExperience}
                  control={control}
                  req
                  error={errors.fieldOfExperience}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={4} px={2} py={1}>
                <SelectComponent
                  name="experienceDuration"
                  label="Required Experience Duration"
                  options={experienceDuration}
                  control={control}
                  req
                  error={errors.experienceDuration}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} px={2} py={1}>
                <SelectComponent
                  name="employmentType"
                  label="Employment Type"
                  options={mapSelectFieldData(["Regular", "Contract", "PartTime", "DailyWages"])}
                  control={control}
                  req
                  error={errors.employmentType}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} px={2} py={1}>
                <SelectComponent
                  isMulti
                  name="testCenters"
                  label="Test Centers"
                  options={stationForTest}
                  control={control}
                  error={errors.testCenters}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="flex-end">
              <Grid item xs={12} sm={12} md={6} px={2} py={1}>
                <SuiTextInputField
                  name="declarations"
                  label="Declarations"
                  control={control}
                  multiline
                  rows={6}
                  error={errors.declarations}
                  req
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={1.65} mr={2} py={1}>
                <SuiButton color="dark" size="large" onClick={handleSubmit(onUpdate)}>
                  {loading ? <CircularProgress size={25} color="white" /> : "Update"}
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </>
      }
    />
  );
}

export default UpdateJob;
