import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AccordionComponent from "components/accordian";
import SuiProgress from "components/SuiProgress";
import toast from "react-hot-toast";
import { resetMessageState } from "./CvBuilder.reducer";
import PersonalInfo from "./personalInfo/PersonalInfo.index";
import ContactInfo from "./contactInfo/ContactInfo.index";
import FitnessInfo from "./fitnessInfoForm/FitnessInfo.index";
import NationalityInfo from "./nationalityInfo/NationalityInfo.index";
import EducationInfo from "./educationInfo/EducationInfo.index";
import ExperienceInfo from "./experienceInfo/ExperienceInfo.index";
import SkillsInfo from "./skillsInfo/SkillsInfo.index";
import { getResumeInfo } from "./CvBuilder.thunk";
import Footer from "examples/Footer";
import { CircularProgress, Grid } from "@mui/material";
import AlertDialogue from "components/alertDialogue";
import { useCallbackPrompt } from "hooks/useCallbackPrompt";
import { getUserProfile } from "layouts/profile/Profile.thunk";
import { Navigate } from "react-router-dom";

function CvBuilder() {
  const dispatch = useDispatch();
  const [selectedAccordion, setSelectedAccordion] = useState();
  const [alertVisible, setAlertVisible] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(alertVisible);
  const { error, success, resumeInfo, cvProgressValue, loading, dirty, personalInfoSaved } =
    useSelector((state) => state.cv);
  const { user } = useSelector((state) => state.profile);

  useEffect(() => {
    if (resumeInfo && resumeInfo.completion) {
      switch (true) {
        case resumeInfo.completion.personalInfo === false:
          setSelectedAccordion(1);
          break;
        case resumeInfo.completion.contactInfo === false:
          setSelectedAccordion(2);
          break;
        case resumeInfo.completion.medicalInfo === false:
          setSelectedAccordion(3);
          break;
        case resumeInfo.completion.demoGraphicsInfo === false:
          setSelectedAccordion(4);
          break;
        case resumeInfo.completion.educationInfo === false:
          setSelectedAccordion(5);
          break;
        case resumeInfo.completion.experienceInfo === false:
          setSelectedAccordion(6);
          break;
        case resumeInfo.completion.skillsInfo === false:
          setSelectedAccordion(7);
          break;
        default:
          setSelectedAccordion(1);
          break;
      }
    }
  }, [resumeInfo?.completion]);

  useEffect(() => {
    const ifSomeDirty = Object.values(dirty).some((field) => {
      return field === true;
    });
    if (ifSomeDirty) {
      setAlertVisible(true);
    } else {
      setAlertVisible(false);
    }
  }, [dirty]);

  useEffect(() => {
    dispatch(getResumeInfo());
  }, []);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      toast.success(success || "Successfully Submitted", {
        duration: 4000,
        position: "top-center",
        className: "toaster successToast",
        icon: null,
      });
      handleSelectedAccordian();
    }
    if (error) {
      toast.error(error, {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
    }
    dispatch(resetMessageState());
  }, [error, success, dispatch]);

  const accordianComponents = [
    {
      title: "Personal Information",
      apiName: "personalInfo",
      component: <PersonalInfo />,
      id: 1,
    },
    {
      title: "Contact Information",
      apiName: "contactInfo",
      component: <ContactInfo />,
      id: 2,
    },
    {
      title: "Fitness Info",
      apiName: "medicalInfo",
      component: <FitnessInfo />,
      id: 3,
    },
    {
      title: "Nationality And Spouse Information",
      apiName: "demoGraphicsInfo",
      component: <NationalityInfo />,
      id: 4,
    },
    {
      title: "Education",
      apiName: "educationInfo",
      component: <EducationInfo />,
      id: 5,
    },
    {
      title: "Professional Experience",
      apiName: "experienceInfo",
      component: <ExperienceInfo />,
      id: 6,
    },
    {
      title: "Skills And Experience ",
      apiName: "skillsInfo",
      component: <SkillsInfo />,
      id: 7,
    },
  ];

  const handleSelectedAccordian = () => {
    setSelectedAccordion((currentState) => currentState + 1);
  };

  const handleSingleAccordion = (type, id) => {
    if (!personalInfoSaved) {
      setSelectedAccordion(1);
      toast.error("Please fill personal info first", {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
      return;
    }
    if (type) {
      setSelectedAccordion(() => id);
    } else {
      setSelectedAccordion(0);
    }
  };

  if (user?.email === "undefined") {
    return <Navigate to="../profile?emailRequired" />;
  }

  return (
    <>
      <AlertDialogue
        agreeBtnText="Continue"
        disagreeBtnText="Cancel"
        open={showPrompt}
        onCancel={cancelNavigation}
        onApprove={confirmNavigation}
        content="Unsaved changes will be lost, do you want to continue"
      />
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container alignItems="center">
          <Grid item xs={9} md={10}>
            <SuiProgress value={cvProgressValue} variant="contained" />
          </Grid>
          <Grid item xs={3} md={2} pl={5}>
            <SuiTypography variant="caption">{`${
              Math.round(cvProgressValue * 10) / 10
            }%`}</SuiTypography>
          </Grid>
        </Grid>

        <SuiBox mb={3} mt={5}>
          <div>
            <SuiBox
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="start"
            >
              <SuiBox width="90%">
                {accordianComponents.map((accordianComponent) => {
                  const Component = accordianComponent.component;
                  return (
                    <SuiBox pt={1}>
                      <AccordionComponent
                        id={accordianComponent.id}
                        handleAccordion={handleSingleAccordion}
                        isOpen={accordianComponent.id === selectedAccordion}
                        completed={resumeInfo?.completion[accordianComponent.apiName] === true}
                        changed={dirty[accordianComponent.apiName] === true}
                        summary={
                          <SuiTypography
                            variant="h5"
                            style={{
                              opacity:
                                accordianComponent.id !== 1 && !personalInfoSaved ? "0.2" : "1",
                            }}
                          >
                            {accordianComponent.title}
                          </SuiTypography>
                        }
                        details={
                          loading ? (
                            <CircularProgress size={30} color="dark" />
                          ) : accordianComponent.id === selectedAccordion ? (
                            Component
                          ) : null
                        }
                      />
                    </SuiBox>
                  );
                })}
              </SuiBox>
            </SuiBox>
          </div>
          {/* <Grid container direction="row" justifyContent="flex-end">
            <Grid item mr={4} mt={4}>
              <SuiButton variant="contained" color="dark" size="large">
                Save All
              </SuiButton>
            </Grid>
          </Grid> */}
        </SuiBox>
        <Footer company={{ name: "Panoptic Solutions" }} />
      </DashboardLayout>
    </>
  );
}

export default CvBuilder;
