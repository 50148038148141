import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const getApplicantGroups = createAsyncThunk("GET_APPLICANT_GROUPS", async (obj) => {
  try {
    await delay(4000);
    const data = [
      {
        id: 1,
        name: "PPS",
      },
      {
        id: 2,
        name: "PMS",
      },
      {
        id: 3,
        name: "LMS",
      },
    ];
    return data;
  } catch (err) {
    throw err.response;
  }
});

export const addApplicantGroup = createAsyncThunk("ADD_APPLICANT_GROUP", async (obj) => {
  try {
    await delay(4000);

    return "Added Successfully";
  } catch (err) {
    throw err.response;
  }
});

export const sendNotification = createAsyncThunk("SEND_NOTIFICATION", async (obj) => {
  try {
    await delay(4000);
    return "Sent Successfully";
  } catch (err) {
    throw err.response;
  }
});
