import * as yup from "yup";

export const signInSchema = yup.object().shape({
  cnic: yup
    .string()
    .required("CNIC is a required field")
    .min(13, "CNIC Cannot Be Less Than 13 Characters")
    .max(13, "CNIC Cannot Exceed 13 Characters"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password Cannot Be Less Than 8 Characters")
    .max(30, "Password Cannot Exceed 30 Characters"),
});

export const meraidSchema = yup.object().shape({
  cnic: yup
    .string()
    .min(13, "CNIC Cannot Be Less Than 13 Characters")
    .max(13, "CNIC Cannot Exceed 13 Characters")
    .required("You must enter CNIC to sign in"),
});
