import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import { Close } from "@mui/icons-material";
import {
  AppBar,
  Card,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Toolbar,
} from "@mui/material";
import CheckBox from "components/checkbox";
import MaterialDialog from "components/Dialog";
import SelectComponent from "components/select";
import SuiBadge from "components/SuiBadge";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DEFAULT_VALUES, schema } from "./Jobs.constants";
import { applyJob, getJobDetails, submitWithMeraid } from "./Jobs.thunk";
import { useEffect } from "react";
import { mapSelectFieldData } from "utils";
import { getAppliedResume } from "layouts/history/history.thunk";
import JobResume from "./Jobs.resume";
import toast from "react-hot-toast";

function JobDetails({ applyModalVisible, onCloseModal, apply }) {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { loggedInWithMeraid } = useSelector((state) => state.auth);
  const { loading, jobDetails, detailsLoading, meraidLoading } = useSelector((state) => state.jobs);
  const { resumeLoading, resumeInfo } = useSelector((state) => state.history);
  const jobId = new URLSearchParams(search).get("jobId");
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUES,
  });

  const onApplyJob = (data) => {
    if (errors && Object.keys(errors).length) {
      toast.error("Declarations Are Not Checked", {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
      return;
    }
    if (loggedInWithMeraid) {
      dispatch(submitWithMeraid({ ...data, jobId }));
    } else {
      dispatch(applyJob({ ...data, jobId }));
    }
  };

  useEffect(() => {
    if (jobId && !apply) {
      dispatch(getAppliedResume(jobId));
    }
    if (jobId) {
      dispatch(getJobDetails(jobId));
    }
  }, [jobId]);

  return (
    <>
      <MaterialDialog
        open={applyModalVisible}
        fullScreen
        content={
          detailsLoading || resumeLoading ? (
            <Box
              container
              sx={{
                width: "30vw",
                bgcolor: "background.paper",
                height: "100vh",
                position: "absolute",
                top: "48%",
                left: "48%",
              }}
            >
              <CircularProgress color="dark" />
            </Box>
          ) : (
            <SuiBox px={2}>
              <AppBar color="dark">
                <Toolbar sx={{ position: "relative" }}>
                  <SuiTypography sx={{ ml: 2, flex: 1 }} variant="h4" component="div" color="white">
                    {jobDetails && jobDetails.jobTitle && jobDetails.jobTitle}
                  </SuiTypography>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={onCloseModal}
                    aria-label="close"
                  >
                    <Close color="white" />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <Grid
                container
                mt={5}
                spacing={3}
                display="flex"
                justifyContent="start"
                alignItems="stretch"
              >
                {!apply && (
                  <Grid item md={7} xs={12} sm={12} lg={7} height="100%">
                    <JobResume data={resumeInfo} />
                  </Grid>
                )}
                <Grid
                  item
                  md={apply ? 6 : 5}
                  xs={12}
                  sm={12}
                  lg={apply ? 6 : 5}
                  style={{ height: "100%" }}
                >
                  <Card>
                    <Box sx={{ width: "100%", bgcolor: "background.paper" }} padding={2}>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12} pb={1}>
                          <SuiTypography variant="h4" component="div" color="grey">
                            Job Description
                          </SuiTypography>
                        </Grid>
                        {jobDetails && jobDetails.description && (
                          <Grid item xs={12} sm={12} md={12} pb={1}>
                            <SuiTypography variant="h6" component="div" color="grey">
                              <span
                                dangerouslySetInnerHTML={{ __html: jobDetails.description }}
                              ></span>
                            </SuiTypography>
                          </Grid>
                        )}
                        {jobDetails && jobDetails.ministry && (
                          <Grid item xs={12} sm={12} md={12} mt={1}>
                            <SuiTypography variant="h6" component="div" color="grey">
                              {jobDetails.ministry}
                            </SuiTypography>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={12} mt={1} ml={-1}>
                          <SuiBadge
                            variant="contained"
                            color="dark"
                            badgeContent={
                              <SuiTypography variant="h6" color="white">
                                {jobDetails && jobDetails.jobTitle && jobDetails.jobTitle}
                              </SuiTypography>
                            }
                            size="lg"
                          />
                        </Grid>
                        {jobDetails && jobDetails.website && (
                          <Grid item xs={12} sm={12} md={12} mt={1}>
                            <SuiTypography variant="h6" component="div" color="grey">
                              <a href={jobDetails.website}>{jobDetails.website}</a>
                            </SuiTypography>
                          </Grid>
                        )}

                        {jobDetails &&
                        jobDetails.jobInfo &&
                        Object.keys(jobDetails.jobInfo).length ? (
                          <Grid item xs={12} sm={12} md={12} mt={1} ml={-1}>
                            {Object.keys(jobDetails.jobInfo).map((key) => {
                              let fullWord = "";
                              key
                                .replace(/([A-Z])/g, " $1")
                                .toLowerCase()
                                .split(" ")
                                .forEach((word) => {
                                  fullWord += word[0].toUpperCase() + word.slice(1) + "  ";
                                });

                              return (
                                <SuiBadge
                                  variant="contained"
                                  color="info"
                                  badgeContent={`${fullWord} - ${jobDetails.jobInfo[key]}`}
                                  size="lg"
                                />
                              );
                            })}
                          </Grid>
                        ) : null}
                        {jobDetails &&
                        jobDetails.jobRequirements &&
                        Object.keys(jobDetails.jobRequirements).length ? (
                          <Grid item xs={12} sm={12} md={12} mt={1} ml={-1}>
                            {Object.keys(jobDetails.jobRequirements).map((key) => {
                              let fullWord = "";
                              key
                                .replace(/([A-Z])/g, " $1")
                                .toLowerCase()
                                .split(" ")
                                .forEach((word) => {
                                  fullWord += word[0].toUpperCase() + word.slice(1) + "  ";
                                });
                              return (
                                <SuiBadge
                                  variant="contained"
                                  color="secondary"
                                  badgeContent={`${fullWord}: ${jobDetails.jobRequirements[key]}`}
                                  size="lg"
                                />
                              );
                            })}
                          </Grid>
                        ) : null}
                        {jobDetails && jobDetails.ageRelaxation ? (
                          <Grid item xs={12} sm={12} md={12} mt={1}>
                            <SuiTypography variant="h6" component="div" color="grey">
                              - {jobDetails.ageRelaxation} years general age relaxation has already
                              been added to maximum age.
                            </SuiTypography>
                          </Grid>
                        ) : null}

                        {jobDetails && jobDetails.location && (
                          <Grid item xs={12} sm={12} md={12} mt={1}>
                            <SuiTypography variant="h6" component="div" color="grey">
                              <strong>Quota :</strong> {jobDetails.location}
                            </SuiTypography>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Card>
                </Grid>

                {apply && (
                  <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Card>
                      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                        <Grid container p={2}>
                          <Grid item xs={12} sm={12} md={12} p={1}>
                            <SuiTypography variant="h4" component="div" color="grey">
                              Eligibility Criteria
                            </SuiTypography>
                          </Grid>
                        </Grid>
                        <List>
                          {jobDetails &&
                          jobDetails.ineligibleFields &&
                          jobDetails.ineligibleFields.length ? (
                            jobDetails.ineligibleFields.map((field) => {
                              return (
                                <ListItem>
                                  <ListItemButton>
                                    <ListItemIcon>
                                      <Icon color="error" fontSize="small">
                                        close
                                      </Icon>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={<SuiTypography variant="h6">{field}</SuiTypography>}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              );
                            })
                          ) : (
                            <ListItem>
                              <ListItemButton>
                                <ListItemIcon>
                                  <Icon color="success" fontSize="small">
                                    checkmark
                                  </Icon>
                                </ListItemIcon>
                                <ListItemText primary="You are eligible to this job" />
                              </ListItemButton>
                            </ListItem>
                          )}
                        </List>
                      </Box>
                    </Card>
                  </Grid>
                )}
              </Grid>
              {apply && (
                <>
                  <Grid container spacing={2} mt={2}>
                    {jobDetails?.ineligibleFields && !jobDetails.ineligibleFields.length && (
                      <Grid item xs={12} sm={12} md={6}>
                        <Card>
                          <Box sx={{ width: "100%", bgcolor: "background.paper" }} padding={2}>
                            <Grid container>
                              <Grid item xs={12} sm={12} md={12} pb={1}>
                                <SuiTypography variant="h5" component="div" color="grey">
                                  Declarations
                                </SuiTypography>
                              </Grid>

                              {jobDetails && jobDetails.terms && (
                                <Grid item>
                                  <Grid container>
                                    <Grid item xs={11} sm={11} md={11}>
                                      <SuiTypography variant="h6" component="div" color="grey">
                                        {jobDetails && jobDetails.terms}
                                      </SuiTypography>
                                    </Grid>
                                    <Grid container justifyContent="flex-end" alignItems="flex-end">
                                      <Grid item>
                                        <CheckBox
                                          name="tncAccepted"
                                          label="I Agree"
                                          control={control}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}

                              <Grid item xs={12} sm={12} md={12} pt={1.2}>
                                <SuiTypography variant="h6" component="div" color="grey">
                                  If any declaration or information and/or details provided by me is
                                  found incorrect/false at any stage, I shall be liable to legal
                                  action(s), including but not limited to cancellation of my
                                  candidature and/or termination from service.
                                </SuiTypography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} pb={1}>
                                <SuiTypography variant="h6" component="small">
                                  {jobDetails && jobDetails.declarations}
                                </SuiTypography>
                              </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-end" alignItems="flex-end">
                              <Grid item>
                                <CheckBox
                                  label="I Agree"
                                  name="declarationAccepted"
                                  control={control}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Card>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={6} my={2}>
                      <Grid container flexDirection="column">
                        {jobDetails && jobDetails.ageRelaxation ? (
                          <Grid item pb={2}>
                            <Card>
                              <Box sx={{ width: "100%", bgcolor: "background.paper" }} padding={2}>
                                <Grid container>
                                  <Grid item xs={1} pl={1.5}>
                                    <CheckBox name="ageRelaxationClaimed" control={control} />
                                  </Grid>
                                  <Grid item xs={11} sm={11} md={11} pb={1} display="flex">
                                    <SuiTypography variant="h6" component="div" color="grey">
                                      Avail Age Relaxation
                                    </SuiTypography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Card>
                          </Grid>
                        ) : null}
                        {jobDetails && jobDetails.terms && (
                          <Grid item>
                            <Card>
                              <Box sx={{ width: "100%", bgcolor: "background.paper" }} padding={2}>
                                <Grid container>
                                  <Grid item xs={1} pl={1.5}>
                                    <CheckBox name="tncAccepted" control={control} />
                                  </Grid>
                                  <Grid item xs={11} sm={11} md={11} pb={1} display="flex">
                                    <SuiTypography variant="h6" component="div" color="grey">
                                      {jobDetails && jobDetails.terms}
                                    </SuiTypography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Card>
                          </Grid>
                        )}
                        {jobDetails?.testCenters?.length ? (
                          <Grid item pt={2}>
                            <Card>
                              <Box sx={{ width: "100%", bgcolor: "background.paper" }} padding={2}>
                                <Grid container>
                                  <Grid item xs={12} sm={12} md={12}>
                                    <SuiTypography variant="h6" component="div" color="grey">
                                      Test Center
                                    </SuiTypography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    py={4}
                                    style={{ zIndex: "999" }}
                                  >
                                    <SelectComponent
                                      name="testCenter"
                                      placeholder="Select"
                                      options={mapSelectFieldData(jobDetails.testCenters)}
                                      control={control}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            </Card>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="flex-end">
                    {jobDetails?.ineligibleFields && jobDetails.ineligibleFields.length ? (
                      <Grid item sm={12} xs={12} md={6} p={1}>
                        <Box
                          bgcolor="#ea0606"
                          borderRadius="10px"
                          padding={2}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <SuiTypography variant="h5" component="div" color="white">
                            You are not eligible for this job!
                          </SuiTypography>
                        </Box>
                      </Grid>
                    ) : (
                      <Grid item justifyContent="flex-end">
                        <Grid container justifyContent="space-between" alignItems="center">
                          {errors && Object.keys(errors).length ? (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={loggedInWithMeraid ? 7 : 8}
                              lg={loggedInWithMeraid ? 7 : 8}
                            >
                              <SuiTypography variant="h6" color="error">
                                Declarations Or Term & Conditions Are Not Checked!
                              </SuiTypography>
                            </Grid>
                          ) : null}
                          {loggedInWithMeraid ? (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={errors && Object.keys(errors).length ? 5 : 12}
                              lg={errors && Object.keys(errors).length ? 5 : 12}
                            >
                              <SuiButton
                                color="dark"
                                size="large"
                                disabled={loading}
                                onClick={handleSubmit(onApplyJob)}
                              >
                                {meraidLoading ? (
                                  <CircularProgress color="white" size={25} />
                                ) : (
                                  "Apply With MeraID"
                                )}
                              </SuiButton>
                              {meraidLoading && (
                                <SuiBox pt={0.5}>
                                  <SuiTypography variant="caption">
                                    Open Your MeraID Application
                                  </SuiTypography>
                                </SuiBox>
                              )}
                            </Grid>
                          ) : (
                            <Grid xs={12} sm={12} md={4} lg={4}>
                              <SuiButton
                                color="dark"
                                size="large"
                                disabled={loading}
                                onClick={handleSubmit(onApplyJob)}
                              >
                                {loading ? <CircularProgress size={25} color="white" /> : "Apply"}
                              </SuiButton>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            </SuiBox>
          )
        }
      />
    </>
  );
}

export default JobDetails;
