import countries from "countries-list";
import { mapSelectFieldData } from "utils";

export const countriesOptions = Object.keys(countries.countries).map((code) => {
  return {
    label: countries.countries[code].name,
    value: countries.countries[code].name,
  };
});

export const genderValues = [
  {
    label: "Male",
    value: "M",
  },
  {
    label: "Female",
    value: "F",
  },
  {
    label: "Others",
    value: "O",
  },
];

export const maritalStatusValues = [
  {
    label: "Single",
    value: "s",
  },
  {
    label: "Married",
    value: "m",
  },
  {
    label: "Divorced",
    value: "d",
  },
];

export const bloodGroups = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"].map((item) => {
  return {
    label: item,
    value: item,
  };
});

export const drivingLiscenceTypes = ["LTV", "HTV", "LTV(public service)"].map((item) => {
  return {
    label: item,
    value: item,
  };
});

export const provinces = ["KPK", "Punjab", "Sindh", "Balochistan"].map((item) => {
  return {
    label: item,
    value: item,
  };
});

export const stationForTest = ["Islamabad", "Lahore", "Karachi", "Peshawar", "Quetta"].map(
  (item) => {
    return {
      label: item,
      value: item,
    };
  }
);

export const domicileValues = [
  "AJK",
  "Balochistan",
  "Gilgit-Baltistan",
  "Islamabad Capital Territory (ICT)",
  "KPK",
  "Northern Area/FATA",
  "Punjab",
  "Sindh (Rural)",
  "Sindh (Urban)",
].map((item) => {
  return {
    label: item,
    value: item,
  };
});

export const AjkDomicileDistricts = [
  "Bagh",
  "Bhimber",
  "Hattian Bala",
  "Haveli",
  "Kotli",
  "Mirpur",
  "Muzaffarabad",
  "Neelum",
  "Poonch",
  "Sudhnati",
].map((item) => {
  return {
    label: item,
    value: item,
  };
});
export const balochistanDistricts = [
  "Awaran",
  "Barkhan",
  "Chagai",
  "Chaman",
  "Dera Bugti",
  "Duki",
  "Gwadar",
  "Harnai",
  "Jafarabad",
  "Jhal Magsi",
  "Kachhi",
  "Kalat",
  "Kech",
  "Kharan",
  "Khuzdar",
  "Kohlu",
  "Lasbela",
  "Loralai",
  "Mastung",
  "Musakhel",
  "Nasirabad",
  "Nushki",
  "Panjgur",
  "Pishin",
  "Qila Abdullah",
  "Qilla Saifullah",
  "Quetta",
  "Shaheed Sikandarabad",
  "Sherani",
  "Sibi",
  "Sohbatpur",
  "Surab",
  "Washuk",
  "Zhob",
  "Ziarat",
].map((item) => {
  return {
    label: item,
    value: item,
  };
});
export const gilgitBaltistanDistricts = [
  "Astore",
  "Darel",
  "Diamer",
  "Ghanche",
  "Ghizer",
  "Gilgit",
  "Gupis–Yasin",
  "Hunza",
  "Kharmang",
  "Nagar",
  "Roundu",
  "Shigar",
  "Skardu",
  "Tangir",
].map((item) => {
  return {
    label: item,
    value: item,
  };
});
export const islamabadDistricts = ["Islamabad"].map((item) => {
  return {
    label: item,
    value: item,
  };
});
export const kpkDistricts = [
  "Abbottabad",
  "Bannu",
  "Battagram",
  "Buner",
  "Charsadda",
  "Dera Ismail Khan",
  "Hangu",
  "Haripur",
  "Karak",
  "Kohat",
  "Kolai Palas",
  "Lakki Marwat",
  "Lower Chitral",
  "Lower Dir",
  "Lower Kohistan",
  "Malakand",
  "Mansehra",
  "Mardan",
  "Nowshera",
  "Peshawar",
  "Shangla",
  "Swabi",
  "Swat",
  "Tank",
  "Torghar",
  "Upper Chitral",
  "Upper Dir",
  "Upper Kohistan",
].map((item) => {
  return {
    label: item,
    value: item,
  };
});
export const fataDistricts = [
  "Bajaur",
  "Khyber",
  "Kurram",
  "Mohmand",
  "North Waziristan",
  "Orakzai",
  "South Waziristan",
].map((item) => {
  return {
    label: item,
    value: item,
  };
});
export const punjabDistricts = [
  "Attock",
  "Bahawalnagar",
  "Bahawalpur",
  "Bhakkar",
  "Chakwal",
  "Chiniot",
  "Dera Ghazi Khan",
  "Faisalabad",
  "Gujranwala",
  "Gujrat",
  "Hafizabad",
  "Jhang",
  "Jhelum",
  "Kasur",
  "Khanewal",
  "Khushab",
  "Lahore",
  "Layyah",
  "Lodhran",
  "Mandi Bahauddin",
  "Mianwali",
  "Multan",
  "Muzaffargarh",
  "Nankana Sahib",
  "Narowal",
  "Okara",
  "Pakpattan",
  "Rahim Yar Khan",
  "Rajanpur",
  "Rawalpindi",
  "Sahiwal",
  "Sargodha",
  "Sheikhupura",
  "Sialkot",
  "Toba Tek Singh",
  "Vehari",
].map((item) => {
  return {
    label: item,
    value: item,
  };
});
export const sindhRuralDistricts = [
  "Badin",
  "Dadu",
  "Ghotki",
  "Hyderabad",
  "Jacobabad",
  "Jamshoro",
  "Kashmore",
  "Keamari",
  "Khairpur",
  "Korangi",
  "Larkana",
  "Malir",
  "Matiari",
  "Mirpur Khas",
  "Naushahro Feroze",
  "Qambar Shahdadkot",
  "Sanghar",
  "Shaheed Benazirabad",
  "Shikarpur",
  "Sujawal",
  "Sukkur(R)",
  "Tando Allahyar",
  "Tando Muhammad Khan",
  "Tharparkar",
  "Thatta",
  "Umerkot",
].map((item) => {
  return {
    label: item,
    value: item,
  };
});
export const sindhUrbanDistricts = [
  "Hyderabad",
  "Karachi Central",
  "Karachi East",
  "Karachi South",
  "Karachi West",
  "Sukkur(U)",
].map((item) => {
  return {
    label: item,
    value: item,
  };
});

export const fieldOfExperience = mapSelectFieldData([
  "Administration",
  "Information Technology(IT)",
  "System Administration/Networking",
  "Project Management",
  "Education",
  "Health & medicine",
  "Law & justice",
  "Marketing",
  "Accountancy & Finance",
  "Business Management",
  "Telecommunications",
  "Civil Engineering",
  "Electrical Engineering",
  "Mechanical Engineering",
  "Agriculture Engineering",
  "Administration & Finance",
  "Ministerial/Office Staff",
  "Software",
  "Human Resource",
  "Research & Development",
  "Procurement",
  "Planning & Development",
  "Transportation",
  "Quality Assurance/Quality Control",
  "Supply Chain Management",
  "Information Technology(IT) & Software",
  "Corporate Affairs",
  "Communications",
  "HR/Communications/Marketing",
  "Communications & Marketing",
  "Environmental Engineering",
  "Oil, Gas & Mining",
  "Nature, Forest & Wildlife",
  "Agriculture Research",
  "Statistics & Data Analysis",
  "Technical Staff",
  "Veterinary & Animal Science",
  "Cooking & Culinary Arts",
  "Labour & Manpower",
  "Security & Surveillance",
  "Audit",
  "Shipping and Logistics",
  "Chemical Science",
  "Insurance and Reinsurance",
  "Social & Management Sciences",
  "Monitoring & Evaluation",
  "Engineering & Management",
  "Administration & IT",
  "Research & Analysis",
  "Information Technology (IT) & Telecommunication",
  "Science & Technology",
  "Power & Energy",
  "Aviation & Aerospace",
  "Law & Administration",
  "Nanoscience & Nanotechnology",
  "Design & Development",
  "Agricultural Engineering & Science",
  "Accountancy & Finance",
  "Administration & Finance",
  "Administration & IT",
  "Agricultural Engineering & Science",
  "Agriculture Engineering",
  "Agriculture Research",
  "Audit",
  "Aviation & Aerospace",
  "Business Management",
  "Chemical Science",
  "Civil Engineering",
  "Communications",
  "Communications & Marketing",
  "Cooking & Culinary Arts",
  "Corporate Affairs",
  "Design & Development",
  "Education",
  "Electrical Engineering",
  "Engineering & Management",
  "Environmental Engineering",
  "Health & medicine",
  "HR/Communications/Marketing",
  "Human Resource",
  "Information Technology  & Telecommunication",
  "Information Technology",
  "Information Technology & Software",
  "Insurance & Reinsurance",
  "Labour & Manpower",
  "Law & Administration",
  "Law & justice",
  "Marketing",
  "Mechanical Engineering",
  "Ministerial/Office Staff",
  "Monitoring & Evaluation",
  "Nanoscience & Nanotechnology",
  "Nature, Forest & Wildlife",
  "Oil, Gas & Mining",
  "Planning & Development",
  "Power & Energy",
  "Procurement",
  "Project Management",
  "Quality Assurance/Quality Control",
  "Research & Analysis",
  "Research & Development",
  "Science & Technology",
  "Security & Surveillance",
  "Shipping & Logistics",
  "Social & Management Sciences",
  "Software",
  "Statistics & Data Analysis",
  "Supply Chain Management",
  "System Administration/Networking",
  "Technical Staff",
  "Telecommunications",
  "Transportation",
  "Veterinary & Animal Science",
]);

export const chestSize = [
  30.0, 30.25, 30.5, 30.75, 31.0, 31.25, 31.5, 31.75, 32.0, 32.25, 32.5, 32.75, 33.0, 33.25, 33.5,
  33.75, 34.0, 34.25, 34.5, 34.75, 35.0, 35.25, 35.5, 35.75, 36.0, 36.25, 36.5, 36.75, 37.0, 37.25,
  37.5, 37.75, 38.0, 38.25, 38.5, 38.75, 39.0, 39.25, 39.5, 39.75, 40.0, 40.25, 40.5, 40.75, 41.0,
  41.25, 41.5, 41.75, 42.0, 42.25, 42.5, 42.75, 43.0, 43.25, 43.5, 43.75, 44.0, 44.25, 44.5, 44.75,
  45.0,
].map((item) => {
  return {
    label: item,
    value: item,
  };
});

export const heightFt = [];
for (let i = 4; i <= 8; i++) {
  heightFt.push({
    label: i.toString(),
    value: i.toString(),
  });
}
export const heightInch = [];
for (let i = 0; i <= 11; i++) {
  heightInch.push({
    label: i,
    value: i.toString(),
  });
}

export let weights = [];
for (let i = 40; i <= 120; i++) {
  weights.push(
    {
      label: i,
      value: i.toString(),
    },
    {
      label: `${i}.25`,
      value: `${i}.25`,
    },
    {
      label: `${i}.50`,
      value: `${i}.50`,
    },
    {
      label: `${i}.75`,
      value: `${i}.75`,
    }
  );
}

export const chestType = [
  { label: "Normal", value: "normal" },
  { label: "Extended", value: "extended" },
];

export const religions = ["Islam", "Christianity", "Hinduism", "Ahmadiya", "Others"].map((item) => {
  return {
    label: item,
    value: item,
  };
});

export const FITNESS_DEFAULT_VALUES = {
  disability: "no",
  chestType: "normal",
};

export const NATIONALITY_DEFAULT_VALUES = {
  nationality: "Pakistan",
};
