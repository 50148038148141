import { createSlice } from "@reduxjs/toolkit";
import { forgotPassword, meraidLogin, resendVerificationEmail, signIn, signup } from "./auth.thunk";

const initialState = {
  error: null,
  success: null,
  loading: false,
  emailLoading: false,
  isLoggedIn: false,
  role: null,
  loggedInWithMeraid: false,
  jwt: {
    token: null,
    refreshToken: null,
  },
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetMessageState: (state) => {
      state.error = null;
      state.success = null;
      state.loading = false;
    },
    signout: (state) => {
      state.isLoggedIn = false;
      state.loggedInWithMeraid = false;
      state.jwt = {
        token: null,
        refreshToken: null,
      };
      localStorage.clear();
    },
  },
  extraReducers: {
    [signup.pending]: (state) => {
      state.loading = true;
    },
    [signup.fulfilled]: (state, { payload }) => {
      state.success = payload;
      state.loading = false;
    },
    [signup.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [signIn.pending]: (state) => {
      state.loading = true;
    },
    [signIn.fulfilled]: (state, { payload }) => {
      state.jwt.token = payload.jwt.accessToken;
      state.jwt.refreshToken = payload.jwt.refreshToken;
      state.role = payload.role || "user";
      state.isLoggedIn = true;
      state.loading = false;
    },
    [signIn.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [meraidLogin.pending]: (state) => {
      state.loading = true;
    },
    [meraidLogin.fulfilled]: (state, { payload }) => {
      localStorage.setItem("token", payload.token);
      state.isLoggedIn = true;
      state.jwt.token = payload.jwt.accessToken;
      state.loading = false;
      state.role = payload.role || "user";
      state.loggedInWithMeraid = true;
    },
    [meraidLogin.rejected]: (state, { error }) => {
      state.loading = false;
      if (error.message === "failed") {
        state.error = "The login request has been rejected by the user";
      } else if (error.message.startsWith("Cannot read properties of undefined")) {
        state.error = "Network Error";
      } else {
        state.error = error.message;
      }
    },
    [resendVerificationEmail.pending]: (state) => {
      state.emailLoading = true;
    },
    [resendVerificationEmail.fulfilled]: (state, { payload }) => {
      state.emailLoading = false;
      state.success = payload;
    },
    [resendVerificationEmail.rejected]: (state, { error }) => {
      state.emailLoading = false;
      state.error = error.message;
    },
    [forgotPassword.pending]: (state) => {
      state.emailLoading = true;
    },
    [forgotPassword.fulfilled]: (state, { payload }) => {
      state.emailLoading = false;
      state.success = payload;
    },
    [forgotPassword.rejected]: (state, { error }) => {
      state.emailLoading = false;
      state.error = error.message;
    },
  },
});

export const { resetMessageState, signout } = AuthSlice.actions;

export default AuthSlice.reducer;
