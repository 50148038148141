import { useEffect, useState } from "react";
import { Card, Grid, Icon, Skeleton } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";
import AddNewOrganisation from "./Organisations.add";
import SuiAvatar from "components/SuiAvatar";
import Footer from "examples/Footer";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { resetMessageState } from "./Organisations.reducer";
import { getOrganisations, removeOrganisation } from "./Organisations.thunk";
import AlertDialogue from "components/alertDialogue";
import { useLocation, useNavigate } from "react-router-dom";

function Organisations() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { search } = useLocation();
  const queryParam = new URLSearchParams(search);
  const { data, loading, error, success } = useSelector((state) => state.org);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [orgId, setOrgId] = useState("");

  const onCloseModal = () => {
    setAddModalVisible(false);
    history("/organisations");
  };

  useEffect(() => {
    dispatch(getOrganisations());
  }, [dispatch, success]);

  const onRemoveOrg = (id) => {
    setDeleteModalVisible(true);
    setOrgId(id);
  };

  const onEditOrg = (id) => {
    history(`?orgId=${id}`);
  };

  const onApproveDeleteOrg = () => {
    dispatch(removeOrganisation(orgId));
    setDeleteModalVisible(false);
  };
  const onCancel = () => {
    setDeleteModalVisible(false);
  };

  useEffect(() => {
    if (queryParam.has("orgId")) {
      setAddModalVisible(true);
    }
  }, [queryParam]);

  useEffect(() => {
    if (success) {
      toast.success(success || "Successfully Submitted", {
        duration: 4000,
        position: "top-center",
        className: "toaster successToast",
        icon: null,
      });
      onCloseModal();
      dispatch(resetMessageState());
    }
    if (error) {
      toast.error(error, {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
      dispatch(resetMessageState());
    }
  }, [error, success]);

  const organisationsTableData = {
    columns: [
      { name: "logo", align: "center" },
      { name: "org Type", align: "center" },
      { name: "name", align: "left" },
      { name: "department", align: "center" },
      { name: "ministry", align: "center" },
      { name: "address", align: "center" },
      { name: "website", align: "center" },
      { name: "action", align: "center" },
    ],

    rows: data.map((org, index) => {
      return {
        logo: (
          <SuiBox mr={2}>
            <SuiAvatar src={org?.logo} size="md" variant="rounded" />
          </SuiBox>
        ),
        "org Type": (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {org.orgType}
          </SuiTypography>
        ),
        name: (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {org.orgName}
          </SuiTypography>
        ),
        department: (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {org.department}
          </SuiTypography>
        ),
        ministry: (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {org.ministry}
          </SuiTypography>
        ),
        address: (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {org.orgAddress}
          </SuiTypography>
        ),
        website: (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {org.website}
          </SuiTypography>
        ),
        action: (
          <>
            <Grid container>
              <Grid item mr={0.5}>
                <SuiButton
                  color="dark"
                  size="lg"
                  onClick={() => {
                    onEditOrg(org.orgId);
                  }}
                >
                  <Icon fontSize="large">edit</Icon>
                </SuiButton>
              </Grid>
              <Grid item>
                <SuiButton
                  color="error"
                  size="lg"
                  onClick={() => {
                    onRemoveOrg(org.orgId);
                  }}
                >
                  <Icon fontSize="large">delete_sweep</Icon>
                </SuiButton>
              </Grid>
            </Grid>
          </>
        ),
      };
    }),
  };
  const { columns, rows } = organisationsTableData;
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox mb={3} mt={5}>
          <Grid container></Grid>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6" color="dark">
                Organisations
              </SuiTypography>
              <SuiButton
                color="dark"
                size="medium"
                onClick={() => {
                  setAddModalVisible(true);
                }}
              >
                <Icon>add</Icon> Add
              </SuiButton>
            </SuiBox>
            {loading ? (
              <SuiBox px={2}>
                <Grid item>
                  <Skeleton animation="wave" height={70} />
                </Grid>
                <Grid item>
                  <Skeleton height={70} />
                </Grid>
                <Grid item>
                  <Skeleton animation="wave" height={70} />
                </Grid>
                <Grid item>
                  <Skeleton height={70} />
                </Grid>
                <Grid item>
                  <Skeleton height={70} pb={2} />
                </Grid>
              </SuiBox>
            ) : (
              <Table columns={columns} rows={rows} />
            )}
          </Card>
        </SuiBox>
        {addModalVisible && <AddNewOrganisation open={addModalVisible} onClose={onCloseModal} />}
        <AlertDialogue
          open={deleteModalVisible}
          content="are your sure you want to delete"
          agreeBtnText="Delete"
          disagreeBtnText="Cancel"
          onCancel={onCancel}
          onApprove={onApproveDeleteOrg}
        />
        <Footer company={{ name: "Panoptic Solutions" }} />
      </DashboardLayout>
    </>
  );
}

export default Organisations;
