import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";

function AlertDialogue({
  content,
  open,
  onCancel,
  onApprove,
  disagreeBtnText,
  agreeBtnText,
  agreeBtnClr,
}) {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {<SuiTypography variant="h6">{content?.toUpperCase()}</SuiTypography>}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: "1rem" }}>
        <SuiButton onClick={onCancel}>{disagreeBtnText}</SuiButton>
        <SuiButton onClick={onApprove} color={agreeBtnClr || "error"}>
          {agreeBtnText}
        </SuiButton>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialogue;
