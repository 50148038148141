import { getUserId } from "utils";
import * as yup from "yup";

export const schema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required("Firstname is required")
    .max(50, "Firstname is too long")
    .min(3, "Firstname is too short"),
  lastName: yup
    .string()
    .trim()
    .required("Lastname is required")
    .max(50, "Lastname is too long")
    .min(3, "Lastname is too short"),
  fatherName: yup
    .string()
    .trim()
    .required("Fathername is required")
    .max(60, "Fathername is too long")
    .min(3, "Fathername is too short"),
  gender: yup.string().required("Gender is required"),
  birthDate: yup
    .date()
    .max(`${new Date().getFullYear() - 15}`, "Minimum age is 15 years")
    .required("Date of birth is required"),
  domicile: yup.string().trim().required("Domicile is required"),
  domicileDistrict: yup.string().trim().required("Domicile district is required"),
  highestDegree: yup.string().trim().required(),
  religion: yup.string().trim().required("Religion is required"),
  drivingLicense: yup.string().trim().required(),
  licenseType: yup
    .string()
    .when("drivingLicense", { is: "Yes", then: yup.string().trim().required() }),
  licenseExpiryDate: yup
    .string()
    .when("drivingLicense", { is: "Yes", then: yup.string().trim().required() }),
});

export const DEFAULT_VALUES = {
  cnic: getUserId(),
  firstName: "",
  lastName: "",
  fatherName: "",
  gender: "",
  birthDate: "",
  domicile: "",
  domicileDistrict: "",
  maritalStatus: "",
  religion: "",
  highestDegree: "",
  drivingLicense: "",
  licenseType: "",
  licenseExpiryDate: "",
};
