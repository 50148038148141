import * as yup from "yup";

export const schema = yup.object().shape({
  // email: yup.string().email("Invalid email").required("Email is required"),
  cellPhoneNo: yup
    .string().trim()
    .required("Phone is required")
    .min(11, "Phone number cannot be less than 11 digits")
    .max(13, "Phone number cannot exceed 13 digits"),
  province: yup.string().trim().required("Province is required"),
  presentAddress: yup.string().trim().required("Address is required"),
  permanentAddress: yup.string().trim().required("Address is required"),
  postalAddress: yup.string().trim(). required("Address is required"),
  policeStation: yup.string().trim().required("Police station is required"),
  policeStationAddress: yup.string().trim().required("Police station address is required"),
});

export const DEFAULT_VALUES = {
  // email: "",
  cellPhoneNo: "",
  province: "",
  presentAddress: "",
  permanentAddress: "",
  postalAddress: "",
  policeStation: "",
  policeStationAddress: "",
};
