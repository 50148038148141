import React, { useEffect, useState } from "react";
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SuiButton from "components/SuiButton";
import MaterialDialog from "components/Dialog";
import toast from "react-hot-toast";
import Table from "examples/Tables/Table";
import SuiTextInputField from "components/SuiTextInputField";
import { restrictInput } from "utils";
import { CircularProgress, Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { submitEducationInfo } from "../CvBuilder.thunk";
import { DEFAULT_VALUES, PassingYears, schema } from "./EducationInfo.constant";
import useDidMountEffect from "hooks/useDidMountEffect";
import { resetDirty, setDirty, setEducationInfo } from "../CvBuilder.reducer";
import SelectComponent from "components/select";

function EducationInfo() {
  const dispatch = useDispatch();
  const { educationFormLoading, resumeInfo, success, resumeId } = useSelector((state) => state.cv);
  const [educationModalVisible, setEducationModalVisible] = useState(false);
  const [education, setEducation] = useState([]);
  const [edit, setEdit] = useState({ edit: false, index: null });
  const [updateEduApi, setUpdateEduApi] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUES,
  });
  const onAddEducation = (data) => {
    if (edit.edit) {
      if (!isDirty) {
        setEducationModalVisible(false);
        reset(DEFAULT_VALUES);
        return;
      }
      const educationInstance = [...education];
      educationInstance[edit.index] = data;
      setEducation([...educationInstance]);
    } else {
      setEducation([data, ...education]);
    }
    setEducationModalVisible(false);
    setEdit({ edit: false, index: null });
    reset(DEFAULT_VALUES);
    setUpdateEduApi(true);
  };

  const removeEducation = (i) => {
    const educationInstance = [...education];
    educationInstance.splice(i, 1);
    setEducation(educationInstance);
    setDisabled(false);
    dispatch(setDirty("educationInfo"));
  };

  const onEditEducation = (i) => {
    setEdit({ edit: true, index: i });
    reset(education[i]);
    setEducationModalVisible(true);
  };
  useDidMountEffect(() => {
    if (updateEduApi) {
      onSubmit();
    }
    setUpdateEduApi(false);
  }, [updateEduApi]);

  useEffect(() => {
    if (success) {
      dispatch(resetDirty("educationInfo"));
      dispatch(setEducationInfo(education));
    }
  }, [success]);
  useEffect(() => {
    if (resumeInfo && resumeInfo.educationInfo) {
      setEducation(resumeInfo.educationInfo.education);
    }
  }, [resumeInfo]);

  const onSubmit = () => {
    if (resumeId) {
      dispatch(
        submitEducationInfo({
          resumeId,
          education,
        })
      );
    } else {
      toast.error("Please Fill Personal Info Form First", {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
    }
  };

  const handleCaseChange = (event) => {
    setValue(event.target.name, event.target.value.toUpperCase(), { shouldDirty: true });
  };

  const educationTableData = {
    columns: [
      {
        name: "qualification",
        align: "center",
      },
      {
        name: "passing Year",
        align: "center",
      },
      {
        name: "institute",
        align: "center",
      },
      {
        name: "marks",
        align: "center",
      },
      {
        name: "grade",
        align: "center",
      },
      {
        name: "major Subjects",
        align: "center",
      },
      {
        name: "action",
        align: "center",
      },
    ],

    rows: education.map((item, index) => {
      return {
        qualification: item.qualification,
        "passing Year": item.passingYear,
        institute: item.institute,
        marks: item.obtainedMarks + "/" + item.totalMarks,
        grade: item.grade,
        "major Subjects": item.majorSubjects,
        action: (
          <>
            <Grid container>
              <Grid item mr={0.8}>
                <Icon
                  fontSize="sm"
                  color="dark"
                  style={{ cursor: "pointer" }}
                  baseClassName="material-icons-outlined"
                  onClick={() => {
                    onEditEducation(index);
                  }}
                >
                  edit
                </Icon>
              </Grid>
              <Grid item>
                <Icon
                  fontSize="sm"
                  color="error"
                  style={{ cursor: "pointer" }}
                  baseClassName="material-icons-outlined"
                  onClick={() => {
                    removeEducation(index);
                  }}
                >
                  delete_forever
                </Icon>
              </Grid>
            </Grid>
          </>
        ),
      };
    }),
  };
  return (
    <SuiBox container>
      <MaterialDialog
        open={educationModalVisible}
        onClose={() => {}}
        title={edit.edit ? "Edit Education" : "Add Education"}
        content={
          <>
            <Grid container>
              <Grid item xs={12} sm={6} md={4} px={1} py={1}>
                <SuiTextInputField
                  name="qualification"
                  control={control}
                  label="Qualification"
                  placeholder="e.g Matric"
                  req
                  error={errors?.qualification}
                  onKeyPress={(e) => {
                    restrictInput(e, /[^a-zA-Z ]/gi);
                  }}
                  onChange={(e) => {
                    handleCaseChange(e);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3.5} px={1} py={1}>
                <SelectComponent
                  name="passingYear"
                  control={control}
                  label="Passing Year"
                  req
                  options={PassingYears}
                  error={errors?.passingYear}
                  onKeyPress={(e) => {
                    restrictInput(e, /[^0-9.]/gi);
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4.5} px={1} py={1}>
                <SuiTextInputField
                  label="Board/Univeristy/Institue"
                  name="institute"
                  control={control}
                  placeholder="e.g BISEP"
                  req
                  error={errors?.institute}
                  onKeyPress={(e) => {
                    restrictInput(e, /[^a-zA-Z ]/gi);
                  }}
                  onChange={(e) => {
                    handleCaseChange(e);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} sm={4} md={3} px={1} py={1}>
                <SuiTextInputField
                  label="Obtained Marks"
                  name="obtainedMarks"
                  control={control}
                  onKeyPress={(e) => {
                    restrictInput(e, /[^0-9.]/gi);
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={3} px={1} py={1}>
                <SuiTextInputField
                  label="Total Marks"
                  name="totalMarks"
                  control={control}
                  onKeyPress={(e) => {
                    restrictInput(e, /[^0-9.]/gi);
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} px={1} py={1}>
                <SuiTextInputField
                  label="Divison / Grade / GPA"
                  name="grade"
                  control={control}
                  req
                  error={errors?.grade}
                  onChange={(e) => {
                    handleCaseChange(e);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={8} md={6} px={1} py={1}>
                <SuiTextInputField
                  label="Major Subjects / Specialization"
                  name="majorSubjects"
                  control={control}
                  multiline
                  rows={2}
                />
              </Grid>
            </Grid>
          </>
        }
        actions={
          <Grid container justifyContent="flex-end">
            <Grid item mr={2} mt={2}>
              <SuiButton
                onClick={() => {
                  setEducationModalVisible(false);
                  setEdit({ edit: false, index: null });
                  reset(DEFAULT_VALUES);
                }}
              >
                Cancel
              </SuiButton>
            </Grid>
            <Grid item mr={2} mt={2}>
              <SuiButton color="dark" onClick={handleSubmit(onAddEducation)}>
                {edit.edit ? "Update" : "Add"}
              </SuiButton>
            </Grid>
          </Grid>
        }
      />
      <Grid container>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} px={1} pt={1} pb={2}>
            <Table columns={educationTableData.columns} rows={educationTableData.rows} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item ml={2}>
          <SuiButton
            color="dark"
            variant="outlined"
            onClick={() => {
              setEducationModalVisible(true);
            }}
          >
            <Icon>add</Icon> Add Education
          </SuiButton>
        </Grid>
        <Grid item mr={2}>
          {educationFormLoading ? (
            <CircularProgress color="dark" />
          ) : (
            <SuiButton variant="contained" color="dark" onClick={onSubmit} disabled={disabled}>
              Save
            </SuiButton>
          )}
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default EducationInfo;
