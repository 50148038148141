import { yupResolver } from "@hookform/resolvers/yup";
import { Card, CircularProgress, Grid, Icon } from "@mui/material";
import CheckBox from "components/checkbox";
import SelectComponent from "components/select";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTextInputField from "components/SuiTextInputField";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import AddNewGroup from "./ApplicantCommunication.add";
import { DEFAULT_VALUES, schema } from "./ApplicantCommunication.constant";
import { resetMessageState } from "./ApplicantCommunication.reducer";
import { getApplicantGroups, sendNotification } from "./ApplicantCommunication.thunk";

function ApplicantCommunication() {
  const dispatch = useDispatch();
  const { applicantGroups, groupsLoading, loading, success, error } = useSelector(
    (state) => state.applicantCommunication
  );
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    dispatch(getApplicantGroups());
    setModalVisible(false);
  }, [success]);
  useEffect(() => {
    if (success) {
      toast.success(success || "Successfully Submitted", {
        duration: 4000,
        position: "top-center",
        className: "toaster successToast",
        icon: null,
      });
      dispatch(resetMessageState());
    }
    if (error) {
      toast.error(error, {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
      dispatch(resetMessageState());
    }
  }, [error, success]);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUES,
  });

  const onSubmit = (data) => {
    if (!data.sms && !data.email && !data.inAppNotification) {
      toast.error("Please select a medium of communication", {
        duration: 2000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
      return;
    }
    dispatch(sendNotification(data));
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mb={3} mt={5} width="100%">
        <Card>
          <SuiBox p={3}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={10} md={4} lg={5}>
                <SelectComponent
                  isMulti
                  label="Applicant Groups"
                  name="applicantGroup"
                  control={control}
                  options={applicantGroups?.map((group) => {
                    return { label: group.name, value: group.id };
                  })}
                  isLoading={groupsLoading}
                  placeholderText="Select Applicant Group"
                  error={errors.applicantGroup}
                  req
                />
              </Grid>
              <Grid item>
                <SuiButton
                  color="dark"
                  onClick={() => {
                    setModalVisible(true);
                  }}
                >
                  <Icon>add</Icon> Add Group
                </SuiButton>
              </Grid>
            </Grid>
            <Grid container mt={8}>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <CheckBox label="SMS" name="sms" control={control} />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <CheckBox label="Email" name="email" control={control} />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <CheckBox label="App Notification" name="inAppNotification" control={control} />
              </Grid>
            </Grid>
            <Grid container mt={1} justifyContent="space-between">
              <Grid item xs={6}>
                <SuiTextInputField
                  label="Message"
                  name="message"
                  control={control}
                  multiline
                  rows={6}
                  req
                  error={errors.message}
                />
              </Grid>
              <Grid item xs={5} alignSelf="flex-end">
                <SuiButton color="dark" onClick={handleSubmit(onSubmit)} disabled={loading}>
                  {loading ? <CircularProgress size={20} color="white" /> : "Send"}
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </Card>
      </SuiBox>
      {modalVisible && (
        <AddNewGroup
          open={modalVisible}
          onClose={() => {
            setModalVisible(false);
          }}
        />
      )}
      <Footer company={{ name: "Panoptic Solutions" }} />
    </DashboardLayout>
  );
}

export default ApplicantCommunication;
