import { getUserId } from "utils";
const userId = getUserId();

// let BASE_SERVER_URL = `https://.jobapp.pk`;

const endpoints = {
  demo: "https://api-demo.jobapp.pk",
  dev: "https://api.dev.jobapp.pk",
};

export const BASE_SERVER_URL = endpoints[process.env.REACT_APP_ENV];
export const SERVER_VERSION = "v1";
export const SERVER_URL = `${BASE_SERVER_URL}/${SERVER_VERSION}`;

export const getUserServerUrl = (userId) => {
  return `${SERVER_URL}/user/${userId}`;
};
export const getResumeInfoUrl = (userId) => {
  return `${SERVER_URL}/user/${userId}/resume/`;
};
export const getResumeServerUrl = (userId) => {
  return `${SERVER_URL}/user/${userId}/resume-builder`;
};
export const getJobsServerUrl = (userId) => {
  return `${SERVER_URL}/user/${userId}/jobs`;
};
export const getApplicationServerUrl = (userId) => {
  return `${SERVER_URL}/user/${userId}/apps`;
};
