import React, { useEffect } from "react";
import SuiBox from "components/SuiBox";
import SuiTextInputField from "components/SuiTextInputField";
import Grid from "@mui/material/Grid";
import { yupResolver } from "@hookform/resolvers/yup";
import SuiButton from "components/SuiButton";
import SelectComponent from "components/select";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { provinces } from "../CvBuilder.constant";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { submitContactInfo } from "../CvBuilder.thunk";
import { DEFAULT_VALUES, schema } from "./ContactInfo.constant";
import { getUserProfile } from "layouts/profile/Profile.thunk";
import { resetDirty, setDirty, updateDirtyContactInfo } from "../CvBuilder.reducer";

function ContactInfo() {
  const dispatch = useDispatch();
  const { contactFormLoading, resumeInfo, success, resumeId } = useSelector((state) => state.cv);
  const { user } = useSelector((state) => state.profile);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUES,
  });
  const contactFormSubmit = (data) => {
    if (resumeId) {
      dispatch(submitContactInfo({ ...data, resumeId }));
    } else {
      toast.error("Please Fill Personal Info Form First", {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
    }
  };

  useEffect(() => {
    const subscription = watch((value) => dispatch(updateDirtyContactInfo(value)));
    return () => {
      subscription?.unsubscribe();
    };
  }, [watch]);

  useEffect(() => {
    if (success) {
      dispatch(resetDirty("contactInfo"));
    }
  }, [success]);

  useEffect(() => {
    if (isDirty) {
      dispatch(setDirty("contactInfo"));
    }
  }, [isDirty]);

  useEffect(() => {
    if (resumeInfo && resumeInfo.contactInfo) {
      reset({
        ...resumeInfo.contactInfo,
      });
    }
  }, []);

  const handleChange = (e, regex) => {
    setValue(e.target.name, e.target.value.replace(regex, ""), {
      shouldDirty: true,
    });
  };

  return (
    <SuiBox container>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} px={2} py={1}>
          <SuiTextInputField
            name="email"
            control={control}
            label="Email"
            placeholder="username@domain.com"
            type="email"
            req
            value={user?.email}
            verified={user?.emailVerified}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} px={2} py={1}>
          <SuiTextInputField
            label="Cell Phone No"
            name="cellPhoneNo"
            control={control}
            placeholder="e.g 03xxxxxxxxx"
            req
            error={errors?.cellPhoneNo}
            inputProps={{ maxLength: 11 }}
            onChange={(e) => {
              handleChange(e, /[^0-9.]/gi);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} px={2} py={1}>
          <SelectComponent
            label="Province"
            name="province"
            placeholderText="Select"
            options={provinces}
            className="react-dropdown"
            control={control}
            req
            error={errors?.province}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} px={2} py={1}>
          <SuiTextInputField
            name="presentAddress"
            control={control}
            label="Present Address"
            multiline
            rows={3}
            req
            error={errors?.presentAddress}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} px={2} py={1}>
          <SuiTextInputField
            name="postalAddress"
            control={control}
            label="Postal Address"
            multiline
            rows={3}
            req
            error={errors?.postalAddress}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} px={2} py={1}>
          <SuiTextInputField
            name="permanentAddress"
            control={control}
            label="Permanent Address"
            multiline
            rows={3}
            req
            error={errors?.permanentAddress}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} px={2} py={1}>
          <SuiTextInputField
            name="policeStation"
            control={control}
            label="Police Station"
            req
            error={errors?.policeStation}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} px={2} py={1}>
          <SuiTextInputField
            name="policeStationAddress"
            control={control}
            label="Police Station Address"
            multiline
            rows={3}
            req
            error={errors?.policeStationAddress}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end">
        <Grid item mr={2}>
          {contactFormLoading ? (
            <CircularProgress color="dark" />
          ) : (
            <SuiButton
              variant="contained"
              color="dark"
              onClick={handleSubmit(contactFormSubmit)}
              disabled={!isDirty}
            >
              Save
            </SuiButton>
          )}
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default ContactInfo;
