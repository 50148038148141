import * as yup from "yup";

export const schema = yup.object().shape({
  nationality: yup.string().trim().required(),
});

export const DEFAULT_VALUES = {
  nationality: "Pakistan",
  foreignNationality: "",
  dualNationality: "",
  spouseForeignNationality: "",
};
