import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import Header from "layouts/profile/components/Header";
import { Box, Card, CircularProgress, Divider, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import SuiTypography from "components/SuiTypography";
import SuiTextInputField from "components/SuiTextInputField";
import { useForm } from "react-hook-form";
import { Close } from "@mui/icons-material";
import SuiButton from "components/SuiButton";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile, updateProfile } from "./Profile.thunk";
import toast from "react-hot-toast";
import { resetMessageState } from "./Profile.reducer";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./Profile.constant";
import { useLocation, useSearchParams } from "react-router-dom";

function Overview() {
  const dispatch = useDispatch();

  let [searchParams] = useSearchParams();

  let { user, loading, error, success } = useSelector((state) => state.profile);

  // if (user?.email === "undefined") user.email = "";

  const [edit, setEdit] = useState(false);
  const [emailUpdate, setEmailUpdate] = useState({
    updated: false,
    showEmailCard: false,
    newEmail: null,
  });
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onUpdate = (data) => {
    if (data.email !== user.email) {
      setEmailUpdate({ ...emailUpdate, updated: true, newEmail: data.email });
    }
    dispatch(updateProfile(data));
  };
  useEffect(() => {
    if (success) {
      if (emailUpdate.updated) {
        setEmailUpdate({ ...emailUpdate, showEmailCard: true });
      }
      toast.success(success || "Successfully Submitted", {
        duration: 6000,
        position: "top-center",
        className: "toaster successToast",
        icon: null,
      });
      dispatch(resetMessageState());
      setEdit(false);
    }
    if (error) {
      toast.error(error, {
        duration: 8000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
      dispatch(resetMessageState());
    }
  }, [error, success]);

  useEffect(() => {
    if (user) {
      reset({
        userId: user?.userId,
        fullName: user?.fullName,
        phoneNo: user?.phoneNo,
        email: user?.email == "undefined" ? "" : user?.email,
      });
    }
  }, [user]);
  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch, success]);

  return (
    <DashboardLayout>
      <Header />

      {searchParams.get("emailRequired") !== null && (
        <div
          style={{
            background: "red",
            marginTop: "1rem",
            borderRadius: 5,
            fontSize: "14px",
            marginBottom: "-3rem",
            color: "#fff",
            padding: "1rem",
          }}
        >
          <p>Please provide Email Address</p>
        </div>
      )}

      <SuiBox mt={5} mb={3}>
        <Grid container spacing={3}>
          {!edit ? (
            <Grid item xs={12} sm={10} md={6} lg={7} xl={7}>
              {loading ? (
                <Card>
                  <Box padding={2}>
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                  </Box>
                </Card>
              ) : (
                <ProfileInfoCard
                  title="profile information"
                  onProfileEdit={() => {
                    setEdit(true);
                  }}
                  info={
                    {
                      userId: user?.userId,
                      fullName: user?.fullName,
                      email: user?.emailVerified ? `${user?.email}   -Verified` : user?.email,
                      phoneNo: user?.phoneNo,
                      email: user?.email === "undefined" ? "" : user?.email,
                    } || "No Profile Info"
                  }
                  action={{ route: "", tooltip: "Edit Profile" }}
                />
              )}
            </Grid>
          ) : (
            <Grid item xs={12} sm={10} md={6} lg={7} xl={7}>
              <Card>
                <Box sx={{ width: "100%", bgcolor: "background.paper" }} padding={2}>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <SuiTypography variant="h6" component="div">
                        Update Profile Info
                      </SuiTypography>
                    </Grid>
                    <Grid item mt={-1}>
                      <SuiTypography variant="body2" color="secondary">
                        <Close
                          onClick={() => {
                            setEdit(false);
                          }}
                        />
                      </SuiTypography>
                    </Grid>
                  </Grid>
                  <Grid container py={2} flexDirection="column">
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <SuiTextInputField
                        name="fullName"
                        label="Full Name"
                        control={control}
                        error={errors.fullName}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <SuiTextInputField
                        name="phoneNo"
                        label="Mobile No"
                        control={control}
                        type="number"
                        error={errors.phoneNo}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} md={6} lg={6} xl={6}>
                          <SuiTextInputField
                            name="email"
                            label="email"
                            control={control}
                            error={errors.email}
                          />
                        </Grid>
                        <Grid item mt={3.5}>
                          <SuiButton
                            color="dark"
                            onClick={handleSubmit(onUpdate)}
                            disabled={!isDirty || loading}
                          >
                            {loading ? <CircularProgress size={25} color="white" /> : "Update"}
                          </SuiButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          )}
          {emailUpdate.showEmailCard && (
            <Grid item xs={10} sm={10} md={6} lg={5} xl={4}>
              <Card>
                <Box sx={{ width: "100%", bgcolor: "lightblue" }} padding={2}>
                  <Grid container justifyContent="space-between" mb={3.5}>
                    <Grid item>
                      <SuiTypography variant="h6" component="div">
                        Email Verification
                      </SuiTypography>
                    </Grid>
                    <Grid item mt={-1}>
                      <SuiTypography variant="body2" color="secondary">
                        <Close
                          onClick={() => {
                            setEmailUpdate({
                              updated: false,
                              showEmailCard: false,
                              newEmail: null,
                            });
                          }}
                        />
                      </SuiTypography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container flexDirection="column">
                    <Grid item>
                      <SuiTypography variant="body2" lineHeight={2.3}>
                        We have sent you verification email on{" "}
                        <strong>{emailUpdate.newEmail}</strong>.Please verify your updated
                        email.Your new email will be updated after verification.
                      </SuiTypography>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          )}
        </Grid>
      </SuiBox>

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Overview;
