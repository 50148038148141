import { createSlice } from "@reduxjs/toolkit";
import {
  addApplicantGroup,
  getApplicantGroups,
  sendNotification,
} from "./ApplicantCommunication.thunk";

const initialState = {
  error: null,
  success: null,
  loading: false,
  groupsLoading: false,
  addGroupLoading: false,
  applicantGroups: [],
};

export const ApplicantCommunicationSlice = createSlice({
  name: "applicantCommunication",
  initialState,
  reducers: {
    resetMessageState: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: {
    [getApplicantGroups.pending]: (state) => {
      state.groupsLoading = true;
    },
    [getApplicantGroups.fulfilled]: (state, { payload }) => {
      state.groupsLoading = false;
      state.applicantGroups = payload;
    },
    [getApplicantGroups.rejected]: (state, { error }) => {
      state.groupsLoading = false;
      state.error = error.message;
    },
    [addApplicantGroup.pending]: (state) => {
      state.addGroupLoading = true;
    },
    [addApplicantGroup.fulfilled]: (state, { payload }) => {
      state.addGroupLoading = false;
      state.success = payload;
    },
    [addApplicantGroup.rejected]: (state, { error }) => {
      state.addGroupLoading = false;
      state.error = error.message;
    },
    [sendNotification.pending]: (state) => {
      state.loading = true;
    },
    [sendNotification.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = payload;
    },
    [sendNotification.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export const { resetMessageState } = ApplicantCommunicationSlice.actions;

export default ApplicantCommunicationSlice.reducer;
