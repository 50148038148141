import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import curved6 from "assets/images/curved-images/curved14.jpg";
import SuiTextInputField from "components/SuiTextInputField";
import { useForm, useWatch } from "react-hook-form";
import { signupSchema } from "./signup.constant";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { signup } from "../auth.thunk";
import { CircularProgress } from "@mui/material";
import { resetMessageState } from "../auth.reducer";

function SignUp() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { loading, error, success } = useSelector((state) => state.auth);
  const [agreement, setAgremment] = useState(true);
  const [passwordMatches, setPasswordMatches] = useState(false);
  const { pathname } = useLocation();

  const handleSetAgremment = () => setAgremment(!agreement);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(signupSchema),
  });
  const onSubmit = (data) => {
    dispatch(
      signup({
        cnic: data.cnic,
        email: data.email,
        phoneNo: data.phoneNo,
        password: data.password,
      })
    );
  };

  const password = useWatch({
    name: "password",
    control,
  });
  const confirmPassword = useWatch({
    name: "confirmPassword",
    control,
  });

  useEffect(() => {
    if (success) {
      toast.success(success, {
        duration: 4000,
        position: "top-center",
        className: "toaster successToast",
        icon: null,
      });
      history("/authentication/sign-in");
      dispatch(resetMessageState());
    }
    if (error) {
      toast.success(error, {
        duration: 5500,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
      dispatch(resetMessageState());
    }
  }, [success, error]);

  useEffect(() => {
    window.onbeforeunload = () => {
      dispatch(resetMessageState());
    };
  }, []);

  useEffect(() => {
    if (password && confirmPassword && confirmPassword === password) {
      setPasswordMatches(true);
    } else {
      setPasswordMatches(false);
    }
  }, [password, confirmPassword]);
  useEffect(() => {
    dispatch(resetMessageState());
  }, [pathname]);

  const handleNumberChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/[^0-9.]/gi, ""), {
      shouldDirty: true,
    });
  };
  return (
    <BasicLayout
      title="Welcome!"
      description="The doorway to apply for your dream-job!"
      image={curved6}
    >
      <Card>
        <SuiBox p={3} mb={1} textAlign="center">
          <SuiTypography variant="h5" fontWeight="medium">
            Sign Up
          </SuiTypography>
        </SuiBox>
        <SuiBox pb={5} px={3}>
          <SuiBox component="form" role="form">
            <SuiBox mb={2}>
              <SuiTextInputField
                name="cnic"
                label="CNIC"
                placeholder="e.g 1730128223341"
                control={control}
                error={errors.cnic}
                req
                inputProps={{ maxLength: 13 }}
                onChange={(e) => {
                  handleNumberChange(e);
                }}
              />
              {errors.cnic && (
                <SuiBox ml={1} mt={-1}>
                  <SuiTypography variant="caption" color="error">
                    {errors.cnic?.message}
                  </SuiTypography>
                </SuiBox>
              )}
            </SuiBox>
            <SuiBox mb={2}>
              <SuiTextInputField
                name="email"
                label="Email"
                placeholder="name@domain.com"
                control={control}
                type="email"
                error={errors.email}
                req
              />
              {errors.email && (
                <SuiBox ml={1} mt={-1}>
                  <SuiTypography variant="caption" color="error">
                    {errors.email?.message}
                  </SuiTypography>
                </SuiBox>
              )}
            </SuiBox>
            <SuiBox mb={2}>
              <SuiTextInputField
                name="phoneNo"
                label="Mobile No"
                placeholder="e.g 0300xxxxxxx"
                control={control}
                error={errors.phoneNo}
                inputProps={{ maxLength: 11 }}
                req
                onChange={(e) => {
                  handleNumberChange(e);
                }}
              />
              {errors.phoneNo && (
                <SuiBox ml={1} mt={-1}>
                  <SuiTypography variant="caption" color="error">
                    {errors.phoneNo?.message}
                  </SuiTypography>
                </SuiBox>
              )}
            </SuiBox>
            <SuiBox mb={2}>
              <SuiTextInputField
                type="password"
                name="password"
                label="Password"
                placeholder="********"
                control={control}
                error={errors.password}
                success={passwordMatches}
                req
              />
              {errors.password && (
                <SuiBox ml={1} mt={-1}>
                  <SuiTypography variant="caption" color="error">
                    {errors.password?.message}
                  </SuiTypography>
                </SuiBox>
              )}
            </SuiBox>
            <SuiBox mb={2}>
              <SuiTextInputField
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                placeholder="Confirm Password"
                control={control}
                success={passwordMatches}
                error={errors.confirmPassword}
                req
              />
              {errors.confirmPassword && (
                <SuiBox ml={1} mt={-1}>
                  <SuiTypography variant="caption" color="error">
                    {errors.confirmPassword?.message}
                  </SuiTypography>
                </SuiBox>
              )}
            </SuiBox>
            <SuiBox display="flex" alignItems="center">
              {/* <Checkbox checked={agreement} onChange={handleSetAgremment} /> */}
              {/* <SuiTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetAgremment}
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </SuiTypography> */}
              <SuiTypography variant="button" fontWeight="bold" textGradient>
                By clicking Agree & Join, you agree to the Job App User Agreement and Privacy Policy
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={4} mb={1}>
              <Link to={"/dashboard"}>
                <SuiButton
                  variant="gradient"
                  color="dark"
                  fullWidth
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={30} disableShrink color="info" />
                  ) : (
                    "Agree & Join"
                  )}
                </SuiButton>
              </Link>
            </SuiBox>
            <SuiBox mt={3} textAlign="center">
              <SuiTypography variant="button" color="text" fontWeight="regular">
                Already have an account?&nbsp;
                <SuiTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Sign in
                </SuiTypography>
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
