import * as yup from "yup";

export const signupSchema = yup.object().shape({
  cnic: yup
    .string()
    .min(13, "CNIC cannot be less than 13 characters")
    .max(13, "CNIC cannot exceed 13 characters")
    .required("CNIC is a required field"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phoneNo: yup
    .string()
    .required("Phone is required")
    .min(11, "Phone number cannot be less than 11 digits")
    .max(13, "Phone number cannot exceed 13 digits"),
  password: yup
    .string()
    .min(8, "Password cannot be Less than 8 characters")
    .max(30, "Password cannot exceed 30 characters")
    .required("Phone is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});
