import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";
import { useDispatch, useSelector } from "react-redux";
import { getHistoryData } from "./history.thunk";
import Footer from "examples/Footer";
import SuiButton from "components/SuiButton";
import JobDetails from "layouts/jobs/Jobs.details";

function History() {
  const history = useNavigate();
  const { search } = useLocation();
  const queryParam = new URLSearchParams(search);
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);

  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.history);

  const onDetails = (id) => {
    history(`?jobId=${id}`);
  };

  const onCloseModal = () => {
    history("/history");
  };

  useEffect(() => {
    if (queryParam.get("jobId")) {
      setDetailsModalVisible(true);
    } else {
      setDetailsModalVisible(false);
    }
  }, [queryParam]);

  useEffect(() => {
    dispatch(getHistoryData());
  }, [dispatch]);
  const HistoryTableData = {
    columns: [
      { name: "job Title", align: "center" },
      { name: "apply Date", align: "left" },
      { name: "organization", align: "center" },
      { name: "details", align: "center" },
    ],
    rows: data.map((job, index) => {
      return {
        "job Title": (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {job.jobTitle}
          </SuiTypography>
        ),
        "apply Date": (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {new Date(job.applyDate).toLocaleDateString("en-US")}
          </SuiTypography>
        ),
        organization: (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {job.organization}
          </SuiTypography>
        ),
        details: (
          <SuiButton
            color="dark"
            size="medium"
            onClick={() => {
              onDetails(job.id);
            }}
          >
            Details
          </SuiButton>
        ),
      };
    }),
  };
  const { columns, rows } = HistoryTableData;
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
          <SuiBox mb={3}>
            <Card>
              <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SuiTypography variant="h6">History Of Applied Jobs</SuiTypography>
              </SuiBox>

              <SuiBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                {loading ? (
                  <SuiBox px={2}>
                    <Grid item>
                      <Skeleton animation="wave" height={70} />
                    </Grid>
                    <Grid item>
                      <Skeleton height={70} />
                    </Grid>
                    <Grid item>
                      <Skeleton animation="wave" height={70} />
                    </Grid>
                    <Grid item>
                      <Skeleton height={70} />
                    </Grid>
                    <Grid item>
                      <Skeleton height={70} pb={2} />
                    </Grid>
                  </SuiBox>
                ) : (
                  <Table columns={columns} rows={rows} />
                )}
              </SuiBox>
            </Card>
          </SuiBox>
        </SuiBox>
        <Footer company={{ name: "Panoptic Solutions" }} />
        <JobDetails applyModalVisible={detailsModalVisible} onCloseModal={onCloseModal} />
      </DashboardLayout>
    </>
  );
}

export default History;
