import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "../src/assets/styles/stylesheet.scss";

// Soft UI Dashboard React Context Provider
import { SoftUIControllerProvider } from "context";
import { store, persistor } from "store/store.index";
import { interceptors } from "axios/axios.interceptor";

interceptors();

ReactDOM.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </SoftUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
