import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getUserServerUrl } from "constants";
import { getUserId } from "utils";

export const getDocUploadData = createAsyncThunk("'GET_DOC_UPLOAD_DATA", async (obj) => {
  try {
    const userId = getUserId();
    const { data } = await axios.post(`${getUserServerUrl(userId)}/upload-document`, obj);
    return data;
  } catch (err) {
    throw err.response.data || err;
  }
});

// export const uploadPersonalImage = createAsyncThunk("UPLOAD_PERSONAL_IMAGE", async (obj) => {
//   try {
//     const formData = new FormData();
//     Object.keys(obj.fields).map((key) => {
//       return formData.append(key, obj.fields[key]);
//     });
//     const { data } = await axios.post(obj.url, formData);
//     return data;
//   } catch (err) {
//     throw err.response.data || err;
//   }
// });

export const uploadImage = createAsyncThunk("UPLOAD_IMAGE", async (obj) => {
  const formData = new FormData();
  Object.keys(obj.fields).map((key) => {
    return formData.append(key, obj.fields[key]);
  });
  const options = {
    method: "POST",
    body: formData,
  };
  const response = await fetch(obj.url, options);
  return response;
});
