import { mapSelectFieldData } from "utils";
import * as yup from "yup";

export const schema = yup.object().shape({
  qualification: yup.string().trim().required(),
  passingYear: yup.string().max(4).min(4).required(),
  grade: yup.string().trim().required(),
  institute: yup.string().trim().required(),
});

export const DEFAULT_VALUES = {
  qualification: "",
  passingYear: "",
  grade: "",
  institute: "",
  obtainedMarks: "",
  totalMarks: "",
  majorSubjects: "",
};

const years = [];
for (let i = 1900; i <= new Date().getFullYear(); i++) {
  years.push(i);
}

export const PassingYears = mapSelectFieldData(years.reverse());
