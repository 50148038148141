import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getApplicationServerUrl } from "constants";
import { getJobsServerUrl } from "constants";
import { getUserId } from "utils";

export const getHistoryData = createAsyncThunk("GET_HISTORY_DATA", async () => {
  try {
    const userId = getUserId();
    const { data } = await axios.get(`${getJobsServerUrl(userId)}/history`);
    return data.history;
  } catch (err) {
    throw err.response.data.message;
  }
});

export const getAppliedResume = createAsyncThunk("GET_APPLIED_RESUME", async (jobId) => {
  try {
    const userId = getUserId();
    const { data } = await axios.get(`${getApplicationServerUrl(userId)}/${jobId}`);
    return data.applicationDetail;
  } catch (err) {
    throw err.response.data.message;
  }
});
