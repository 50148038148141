import React, { useEffect } from "react";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Grid from "@mui/material/Grid";
import SelectComponent from "components/select";
import { countriesOptions } from "../CvBuilder.constant";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { submitNationalityInfo } from "../CvBuilder.thunk";
import { DEFAULT_VALUES, schema } from "./NationalityInfo.constant";
import { resetDirty, setDirty, updateDirtyNationalityInfo } from "../CvBuilder.reducer";

function NationalityInfo() {
  const dispatch = useDispatch();

  const { nationalityFormLoading, resumeInfo, success, resumeId } = useSelector(
    (state) => state.cv
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    watch,
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const subscription = watch((value) =>
      dispatch(
        updateDirtyNationalityInfo({
          ...value,
          dualNationality: value.dualNationality.map((nationality) => nationality.value),
        })
      )
    );
    return () => {
      subscription?.unsubscribe();
    };
  }, [watch]);

  useEffect(() => {
    if (isDirty) {
      dispatch(setDirty("demoGraphicsInfo"));
    }
  }, [isDirty]);

  useEffect(() => {
    if (success) {
      dispatch(resetDirty("demoGraphicsInfo"));
    }
  }, [success]);

  useEffect(() => {
    if (resumeInfo && resumeInfo.demoGraphicsInfo) {
      reset({
        ...resumeInfo.demoGraphicsInfo,
        dualNationality: resumeInfo.demoGraphicsInfo.dualNationality.map((country) => {
          return {
            label: country,
            value: country,
          };
        }),
      });
    } else {
      reset(DEFAULT_VALUES);
    }
  }, []);

  const nationalityFormSubmit = (data) => {
    if (resumeId) {
      dispatch(
        submitNationalityInfo({
          ...data,
          dualNationality: data.dualNationality.map((item) => item.value),
          resumeId,
        })
      );
    } else {
      toast.error("Please Fill Personal Info Form First", {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
    }
  };
  return (
    <SuiBox>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} px={2} py={2}>
          <SelectComponent
            name="nationality"
            control={control}
            label="Nationality"
            options={countriesOptions}
            req
            error={errors?.nationality}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} px={2} py={2}>
          <SelectComponent
            name="foreignNationality"
            control={control}
            label="Foreign Nationality"
            placeholderText="Select"
            options={[
              {
                label: "Yes",
                value: "yes",
              },
              {
                label: "No",
                value: "no",
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} px={2} py={2}>
          <SelectComponent
            isMulti
            name="dualNationality"
            control={control}
            label="Dual Nationality"
            placeholderText="Select"
            options={countriesOptions}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} px={2} py={2}>
          <SelectComponent
            name="spouseForeignNationality"
            control={control}
            label="Do you have a foreign national spouse?"
            placeholderText="Select"
            options={[
              {
                label: "Yes",
                value: "yes",
              },
              {
                label: "No",
                value: "no",
              },
            ]}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end">
        <Grid item mr={2} mt={2}>
          {nationalityFormLoading ? (
            <CircularProgress color="dark" />
          ) : (
            <SuiButton
              variant="contained"
              color="dark"
              onClick={handleSubmit(nationalityFormSubmit)}
              disabled={!isDirty}
            >
              Save
            </SuiButton>
          )}
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default NationalityInfo;
