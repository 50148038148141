import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, Grid } from "@mui/material";
import MaterialDialog from "components/Dialog";
import SuiButton from "components/SuiButton";
import SuiTextInputField from "components/SuiTextInputField";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { addGroupSchema, ADD_GROUP_DEFAULT_VALUES } from "./ApplicantCommunication.constant";
import { addApplicantGroup } from "./ApplicantCommunication.thunk";

function AddNewGroup({ open, onClose }) {
  const dispatch = useDispatch();
  const [csvFile, setCsvFile] = useState();
  const { addGroupLoading } = useSelector((state) => state.applicantCommunication);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addGroupSchema),
    defaultValues: ADD_GROUP_DEFAULT_VALUES,
  });

  const onAddGroup = (data) => {
    if (csvFile) {
      dispatch(addApplicantGroup({ ...data, groupFile: csvFile }));
    } else {
      toast.error("Please select a file", {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file.type.match(/csv/)) {
      setCsvFile(file);
    } else {
      toast.error("Please select .CSV files", {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
      return;
    }
  };
  return (
    <MaterialDialog
      open={open}
      title="Add New Group"
      content={
        <>
          <Grid container>
            <Grid item xs={12} sm={6} md={6} px={1} py={1}>
              <SuiTextInputField
                name="groupName"
                control={control}
                label="Group Name"
                req
                error={errors?.groupName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} px={1} py={1}>
              <SuiTextInputField
                name="groupFile"
                control={control}
                label="Group CSV"
                req
                type="file"
                onChange={handleFileChange}
                error={errors?.groupFile}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" mt={2}>
            <Grid item xs={4.5} sm={3} md={3} lg={2.5} py={1}>
              <SuiButton onClick={onClose}>Cancel</SuiButton>
            </Grid>
            <Grid item xs={3.5} sm={2} md={2} lg={2} py={1}>
              <SuiButton
                color="dark"
                onClick={handleSubmit(onAddGroup)}
                size="medium"
                disabled={addGroupLoading}
              >
                {addGroupLoading ? <CircularProgress size={20} color="white" /> : "Add"}
              </SuiButton>
            </Grid>
          </Grid>
        </>
      }
    />
  );
}

export default AddNewGroup;
