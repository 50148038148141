/*
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard React layouts
// import Dashboard from "layouts/dashboard";
import Jobs from "layouts/jobs/Jobs.index";
// import Billing from "layouts/billing";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// Soft UI Dashboard React icons
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CvBuilder from "layouts/cv-builder/CvBuilder.index";
import JobBuilder from "layouts/job-builder/JobBuilder.index";
import History from "layouts/history/history.index";
import {
  AllInbox,
  Apartment,
  HistoryOutlined,
  Person,
  Sms,
  ViewCarousel,
  Work,
} from "@mui/icons-material";
import Organisations from "layouts/organisations/Organizations.index";
// import MyJobs from "layouts/my-jobs/MyJobs.index";
import AllJobs from "layouts/all-jobs/AllJobs.index";
import ApplicantCommunication from "layouts/applicant-communication/ApplicantCommunication.index";

const routes = [
  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   route: "/dashboard",
  //   icon: <Shop size="12px" />,
  //   component: <Dashboard />,
  //   noCollapse: true,
  //   privacy: "private",
  // },
  {
    type: "collapse",
    name: "CV Builder",
    key: "CV-builder",
    route: "/CV-builder",
    icon: <Person />,
    component: <CvBuilder />,
    noCollapse: false,
    privacy: "private",
    role: ["user"],
  },
  {
    type: "collapse",
    name: "Organisations",
    key: "organisations",
    route: "/organisations",
    icon: <Apartment />,
    component: <Organisations />,
    noCollapse: false,
    privacy: "private",
    role: ["admin"],
  },
  {
    type: "collapse",
    name: "Jobs",
    key: "jobs",
    route: "/jobs",
    icon: <Work size="12px" />,
    component: <Jobs />,
    noCollapse: true,
    privacy: "private",
    role: ["user", "admin"],
  },
  // {
  //   type: "collapse",
  //   name: "My Jobs",
  //   key: "my-jobs",
  //   route: "/my-jobs",
  //   icon: <HistoryEdu size="12px" />,
  //   component: <MyJobs />,
  //   noCollapse: true,
  //   privacy: "private",
  // },
  {
    type: "collapse",
    name: "All Jobs",
    key: "all-jobs",
    route: "/all-jobs",
    icon: <AllInbox size="12px" />,
    component: <AllJobs />,
    noCollapse: true,
    privacy: "private",
    role: ["admin"],
  },

  {
    type: "collapse",
    name: "Job Builder",
    key: "job-builder",
    route: "/job-builder",
    icon: <ViewCarousel />,
    component: <JobBuilder />,
    noCollapse: false,
    privacy: "private",
    role: ["admin"],
  },
  {
    type: "collapse",
    name: "Applied Job History",
    key: "history",
    route: "/history",
    icon: <HistoryOutlined />,
    component: <History />,
    noCollapse: false,
    privacy: "private",
    role: ["user"],
  },
  {
    type: "collapse",
    name: "Communication",
    key: "communication",
    route: "/communication",
    icon: <Sms />,
    component: <ApplicantCommunication />,
    noCollapse: false,
    privacy: "private",
    role: ["admin"],
  },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   route: "/billing",
  //   icon: <CreditCard size="12px" />,
  //   component: <Billing />,
  //   noCollapse: true,
  //   privacy: "private",
  // },
  // { type: "title", title: "Account Pages", key: "account-pages" },
  {
    type: "",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true,
    privacy: "private",
    role: ["user", "admin"],
  },
  {
    type: "",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <Document size="12px" />,
    component: <SignIn />,
    noCollapse: true,
    privacy: "public",
    role: ["user", "admin"],
  },
  {
    type: "",
    name: "Sign Up",
    key: "sign-up",
    route: "/authentication/sign-up",
    icon: <SpaceShip size="12px" />,
    component: <SignUp />,
    noCollapse: true,
    privacy: "public",
    role: ["user", "admin"],
  },
];

export default routes;
