import axios from "axios";
import { store } from "store/store.index";
import { signout } from "layouts/authentication/auth.reducer";

export const interceptors = () => {
  axios.interceptors.request.use(
    (request) => {
      const { jwt } = store.getState().auth;
      if (jwt.token) {
        request.headers.Authorization = `Bearer ${jwt.token}`;
      }
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      if (response.status === 401) {
        store.dispatch(signout());
      }
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        store.dispatch(signout());
      }
      return Promise.reject(error);
    }
  );
};
