import moment from "moment";
import * as yup from "yup";

export const requiredMinAge = [];
for (let i = 15; i <= 75; i++) {
  requiredMinAge.push(i);
}

export const requiredMaxAge = [];
for (let i = 15; i <= 75; i++) {
  requiredMaxAge.push(i);
}

export const educations = [
  { label: "Ph.D", value: "phd" },
  { label: "M.Phil/MS", value: "mphil/ms" },
  { label: "Master", value: "master" },
  { label: "Bachelor", value: "bachelor" },
  { label: "Intermediate", value: "intermediate" },
  { label: "Matric", value: "matric" },
  { label: "Middle", value: "middle" },
  { label: "Primary", value: "primary" },
  { label: "Illiterate", value: "illiterate" },
];

export const experienceDuration = [];
const maxExperience = 30;
for (let i = 1; i <= maxExperience; i++) {
  experienceDuration.push({ label: `${i} Years`, value: `${i}y` });
}

export const schema = yup.object().shape({
  jobTitle: yup.string().trim().required(),
  description: yup.string().max(300).required(),
  vacancies: yup.number().min(1).required(),
  location: yup.string().required(),
  expiryDate: yup
    .date()
    .min(new Date(), "Expiry date must be in the future")
    .required()
    .typeError("Expiry date is required"),
  publishDate: yup
    .date()
    .required()
    .min(moment().subtract(1, "day"), "Publish date cannot be in the past")
    .max(yup.ref("expiryDate"), "Publish date should be before expiry date")
    .typeError("Publish date is required field"),
  orgId: yup.string().trim().required(),
  grade: yup.string().trim().required(),
  salary: yup.string().notRequired(),
  jobType: yup.string().trim().required(),
  shift: yup.string().notRequired(),
  minAge: yup.number().required(),
  maxAge: yup.number().min(yup.ref("minAge"), "Max age should be more than Min age").required(),
  education: yup.string().trim().required(),
  skills: yup.array().required(),
  fieldOfExperience: yup.array().max(7).required(),
  experienceDuration: yup.string().trim().required(),
  employmentType: yup.string().trim().required(),
  testCenters: yup.array().notRequired(),
  declarations: yup.string().trim().required(),
});

export const DEFAULT_VALUES = {
  jobTitle: "",
  description: "",
  vacancies: "",
  location: "",
  publishDate: "",
  expiryDate: "",
  orgId: "",
  grade: "",
  salary: "",
  jobType: "",
  shift: "",
  minAge: "",
  maxAge: "",
  education: "",
  skills: "",
  fieldOfExperience: "",
  experienceDuration: "",
  employmentType: "",
  testCenters: [],
  declarations: "",
};
export const DEFAULT_NEW_VALUES = {
  jobTitle: "",
  description: "",
  vacancies: "",
  location: "",
  publishDate: "",
  expiryDate: moment(new Date(), "DD-MM-YYYY").add(30, "days").format("YYYY-MM-DD"),
  orgId: "",
  grade: "",
  salary: "",
  jobType: "",
  shift: "",
  minAge: "",
  maxAge: "",
  education: "",
  skills: "",
  fieldOfExperience: "",
  experienceDuration: "",
  employmentType: "",
  testCenters: [],
  declarations: "",
};
