import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = ({ children }) => {
  const { isLoggedIn, role } = useSelector((state) => state.auth);
  const { cvProgressValue } = useSelector((state) => state.cv);

  if (isLoggedIn) {
    if (cvProgressValue < 90 && role !== "admin") {
      return <Navigate to="/cv-builder" />;
    }
    return <Navigate to="/jobs" />;
  } else {
    return children;
  }
};

export default PublicRoute;
