import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SERVER_URL } from "constants";
// import { getUserId } from "utils";

// const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const createJob = createAsyncThunk("CREATE_JOB", async (obj) => {
  try {
    // const userId = getUserId();
    const { data } = await axios.post(`${SERVER_URL}/jobs`, obj);
    return data.message;
  } catch (err) {
    throw err.response.data.message || err;
  }
});
