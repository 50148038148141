import React, { memo, useEffect, useState } from "react";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Grid from "@mui/material/Grid";
import MaterialDialog from "components/Dialog";
import Table from "examples/Tables/Table";
import CheckBox from "components/checkbox";
import SuiTextInputField from "components/SuiTextInputField";
import toast from "react-hot-toast";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { restrictInput } from "utils";
import { CircularProgress, Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { submitProfessionalExperience } from "../CvBuilder.thunk";
import { DEFAULT_VALUES, schema } from "./ExperienceInfo.constant";
import { formateDate } from "utils";
import useDidMountEffect from "hooks/useDidMountEffect";
import { resetDirty, setDirty, setExperienceInfo } from "../CvBuilder.reducer";

function ExperienceInfo() {
  const dispatch = useDispatch();
  const { experienceFormLoading, resumeInfo, success, resumeId } = useSelector((state) => state.cv);
  const [experienceModalVisible, setExperienceModalVisible] = useState(false);
  const [experience, setExperience] = useState([]);
  const [updateExperienceApi, setUpdateExperienceApi] = useState(false);
  const [edit, setEdit] = useState({ edit: false, index: null });
  const [disabled, setDisabled] = useState(true);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUES,
  });

  const onAddExperience = (data) => {
    const mappedData = {
      ...data,
      startDate: formateDate(data.startDate),
      endDate: formateDate(data.endDate),
    };
    if (mappedData.currentlyWorking === false && !mappedData.endDate) {
      setError("endDate", { type: "focus" }, { shouldFocus: true });
      return;
    }
    if (edit.edit) {
      if (!isDirty) {
        setExperienceModalVisible(false);
        reset(DEFAULT_VALUES);
        return;
      }
      const experienceInstance = [...experience];
      experienceInstance[edit.index] = mappedData;
      setExperience(experienceInstance);
    } else {
      if (mappedData) {
        setExperience([mappedData, ...experience]);
      } else {
        return;
      }
    }
    setExperienceModalVisible(false);
    reset(DEFAULT_VALUES);
    setUpdateExperienceApi(true);
  };

  const onRemoveExperience = (i) => {
    const experienceInstance = [...experience];
    experienceInstance.splice(i, 1);
    setExperience(experienceInstance);
    setDisabled(false);
    dispatch(setDirty("experienceInfo"));
  };
  const onEditExperience = (i) => {
    setEdit({ edit: true, index: i });
    reset(experience[i]);
    setExperienceModalVisible(true);
  };

  useDidMountEffect(() => {
    if (updateExperienceApi) {
      onSubmit();
    }
    setUpdateExperienceApi(false);
  }, [updateExperienceApi]);

  useEffect(() => {
    if (success) {
      dispatch(resetDirty("experienceInfo"));
      dispatch(setExperienceInfo(experience));
    }
  }, [success]);
  const watchCurrentlyWorking = useWatch({
    name: "currentlyWorking",
    control: control,
  });
  useEffect(() => {
    if (watchCurrentlyWorking) {
      clearErrors("endDate");
      setValue("endDate", "");
    }
  }, [watchCurrentlyWorking]);
  useEffect(() => {
    if (resumeInfo && resumeInfo.experienceInfo) {
      const currentExperience = resumeInfo.experienceInfo.experience.map((e) => {
        return {
          ...e,
        };
      });
      setExperience(currentExperience);
    }
  }, [resumeInfo]);
  const onSubmit = () => {
    if (resumeId) {
      dispatch(
        submitProfessionalExperience({
          resumeId,
          experience: experience,
        })
      );
    } else {
      toast.error("Please Fill Personal Info Form First", {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
    }
  };

  const experienceTableData = {
    columns: [
      { name: "field Of Experience", align: "center" },
      { name: "designation", align: "center" },
      { name: "organization Name", align: "center" },
      { name: "enrollment", align: "center" },
      { name: "start Date", align: "center" },
      { name: "end Date", align: "center" },
      { name: "", align: "center" },
    ],

    rows: experience.map((item, index) => {
      return {
        "field Of Experience": item.fieldOfExperience,
        designation: item.designation,
        "organization Name": item.organizationName,
        enrollment: item.enrollment,
        "start Date": item.startDate,
        "end Date": item.endDate || "Currenlty Working",
        "": (
          <>
            <Grid container>
              <Grid item mr={0.8}>
                <Icon
                  color="dark"
                  size="lg"
                  onClick={() => {
                    onEditExperience(index);
                  }}
                  fontSize="medium"
                  baseClassName="material-icons-outlined"
                  style={{ cursor: "pointer" }}
                >
                  edit
                </Icon>
              </Grid>
              <Grid item>
                <Icon
                  color="error"
                  onClick={() => {
                    onRemoveExperience(index);
                  }}
                  fontSize="medium"
                  baseClassName="material-icons-outlined"
                  style={{ cursor: "pointer" }}
                >
                  delete_forever
                </Icon>
              </Grid>
            </Grid>
          </>
        ),
      };
    }),
  };

  return (
    <SuiBox container>
      <Grid container justifyContent="flex-start"></Grid>
      <MaterialDialog
        open={experienceModalVisible}
        title={edit.edit ? "Update Experience" : "Add Experience"}
        content={
          <>
            <Grid container>
              <Grid item xs={12} sm={6} md={12} px={1} py={1}>
                <SuiTextInputField
                  name="fieldOfExperience"
                  label="Field Of Experience"
                  control={control}
                  req
                  error={errors.fieldOfExperience}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12} px={1} py={1}>
                <SuiTextInputField
                  name="organizationName"
                  control={control}
                  label="Organization Name"
                  req
                  error={errors.organizationName}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12} px={1} py={1}>
                <SuiTextInputField
                  name="organizationNumber"
                  control={control}
                  label="Organization Contact Number"
                  placeholder="03139398304 / 0915340987"
                  inputProps={{ maxlength: 15 }}
                  onKeyPress={(e) => {
                    restrictInput(e, /[^0-9]/gi);
                  }}
                  req
                  error={errors.organizationNumber}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} md={12} px={1} py={1}>
                <SuiTextInputField
                  name="designation"
                  control={control}
                  label="Designation"
                  req
                  error={errors.designation}
                  onKeyPress={(e) => {
                    restrictInput(e, /[^a-zA-Z ]/gi);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12} px={1} py={1}>
                <SuiTextInputField name="enrollment" control={control} label="Enrollment" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} md={3.5} px={1} py={1}>
                <SuiTextInputField
                  name="startDate"
                  control={control}
                  label="Starting Date"
                  type="date"
                  req
                  error={errors.startDate}
                />
              </Grid>
              {!watchCurrentlyWorking ? (
                <Grid item xs={12} sm={6} md={3.5} px={1} py={1}>
                  <SuiTextInputField
                    name="endDate"
                    control={control}
                    label="End Date"
                    type="date"
                    error={errors.endDate}
                    disabled={watchCurrentlyWorking}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12} sm={6} md={5} px={1.2} mt={3.5} py={2}>
                <CheckBox
                  label="Currently Working"
                  name="currentlyWorking"
                  control={control}
                  checked={watchCurrentlyWorking}
                />
              </Grid>
            </Grid>
          </>
        }
        actions={
          <Grid container justifyContent="flex-end">
            <Grid item mr={2} mt={2}>
              <SuiButton
                onClick={() => {
                  setExperienceModalVisible(false);
                  setEdit({ edit: false, index: null });
                  reset(DEFAULT_VALUES);
                }}
              >
                Cancel
              </SuiButton>
            </Grid>
            <Grid item mr={2} mt={2}>
              <SuiButton color="dark" onClick={handleSubmit(onAddExperience)}>
                {edit.edit ? "Update" : "Add"}
              </SuiButton>
            </Grid>
          </Grid>
        }
      />
      <Grid container>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} px={1} pt={1} pb={2}>
            <Table columns={experienceTableData.columns} rows={experienceTableData.rows} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item ml={2}>
          <SuiButton
            color="dark"
            variant="outlined"
            onClick={() => {
              setExperienceModalVisible(true);
            }}
          >
            <Icon>add</Icon> Add Experience
          </SuiButton>
        </Grid>
        <Grid item mr={2}>
          {experienceFormLoading ? (
            <CircularProgress color="dark" />
          ) : (
            <SuiButton variant="contained" color="dark" onClick={onSubmit} disabled={disabled}>
              Save
            </SuiButton>
          )}
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default memo(ExperienceInfo);
