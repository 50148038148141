import { createSlice } from "@reduxjs/toolkit";
import { getAppliedResume, getHistoryData } from "./history.thunk";

const initialState = {
  error: null,
  success: null,
  loading: false,
  resumeLoading: false,
  resumeInfo: null,
  data: [],
};

export const HistorySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    resetMessageState: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: {
    [getHistoryData.pending]: (state) => {
      state.loading = true;
    },
    [getHistoryData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    },
    [getHistoryData.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
    [getAppliedResume.pending]: (state) => {
      state.resumeLoading = true;
    },
    [getAppliedResume.fulfilled]: (state, { payload }) => {
      state.resumeLoading = false;
      state.resumeInfo = payload;
    },
    [getAppliedResume.rejected]: (state, { error }) => {
      state.resumeLoading = false;
      state.error = error.message;
    },
  },
});

export const { resetMessageState } = HistorySlice.actions;

export default HistorySlice.reducer;
