import * as React from "react";
// import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  borderRadius: "0.8rem",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  boxShadow:
    "inset 0rem 0rem 0.0625rem 0.0625rem rgb(255 255 255 / 90%), 0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 5%);",
}));

export default function AccordionComponent({
  summary,
  details,
  id,
  handleAccordion,
  isOpen,
  completed,
  changed,
}) {
  const handleChange = (panel) => (event, newExpanded) => {
    handleAccordion(newExpanded, panel);
  };
  return (
    <div>
      <Accordion
        expanded={isOpen}
        onChange={handleChange(id)}
        style={{ border: changed ? "1px solid #ff812e" : completed ? "1px solid #66D406" : null }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{summary}</Typography>
        </AccordionSummary>
        <AccordionDetails>{details}</AccordionDetails>
      </Accordion>
    </div>
  );
}
