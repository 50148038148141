import React, { useEffect } from "react";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import Grid from "@mui/material/Grid";
import SelectCreateable from "components/createable";
import SelectComponent from "components/select";
import { fieldOfExperience } from "../CvBuilder.constant";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { submitSkillsInfo } from "../CvBuilder.thunk";
import { resetDirty, setDirty, updateDirtySkillsInfo } from "../CvBuilder.reducer";

function SkillsInfo() {
  const dispatch = useDispatch();
  const { skillsFormLoading, resumeInfo, success, resumeId } = useSelector((state) => state.cv);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({});

  useEffect(() => {
    const subscription = watch((value) =>
      dispatch(
        updateDirtySkillsInfo({
          ...value,
          fieldOfExperience: value.fieldOfExperience.map((item) => item.value),
          skills: value.skills.map((item) => item.value),
        })
      )
    );
    return () => {
      subscription?.unsubscribe();
    };
  }, [watch]);

  useEffect(() => {
    if (isDirty) {
      dispatch(setDirty("skillsInfo"));
    }
  }, [isDirty]);

  useEffect(() => {
    if (success) {
      dispatch(resetDirty("skillsInfo"));
    }
  }, [success]);

  useEffect(() => {
    if (resumeInfo && resumeInfo.skillsInfo) {
      reset({
        fieldOfExperience: resumeInfo.skillsInfo.fieldOfExperience.map((e) => {
          return {
            label: e,
            value: e,
          };
        }),
        skills: resumeInfo.skillsInfo.skills.map((e) => {
          return {
            label: e,
            value: e,
          };
        }),
      });
    } else {
      reset({});
    }
  }, []);
  const skillsFormSubmit = (data) => {
    if ((resumeInfo, resumeInfo.skillsInfo)) {
      dispatch(
        submitSkillsInfo({
          data: {
            fieldOfExperience: data.fieldOfExperience.map((item) => item.value),
            skills: data.skills.map((item) => item.value),
          },
          resumeId,
        })
      );
    } else {
      toast.error("Please Fill Personal Info Form First", {
        duration: 4000,
        position: "top-center",
        className: "toaster errorToast",
        icon: null,
      });
    }
  };
  return (
    <SuiBox container>
      <Grid container>
        <Grid item xs={12} sm={12} md={5} px={1}>
          <SelectComponent
            isMulti
            name="fieldOfExperience"
            placeholderText="select"
            label="Field Of Experience"
            control={control}
            options={fieldOfExperience}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5} px={1}>
          <SelectCreateable
            isMulti
            name="skills"
            placeholderText="select"
            label="Skills"
            control={control}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end">
        <Grid item mr={2} mt={1}>
          {skillsFormLoading ? (
            <CircularProgress color="dark" />
          ) : (
            <SuiButton
              variant="contained"
              color="dark"
              onClick={handleSubmit(skillsFormSubmit)}
              disabled={!isDirty}
            >
              Save
            </SuiButton>
          )}
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default SkillsInfo;
