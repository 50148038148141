import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getUserServerUrl } from "constants";
import { getUserId } from "utils";

// const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const getUserProfile = createAsyncThunk("GET_USER_PROFILE", async () => {
  try {
    const userId = getUserId();
    const { data } = await axios.get(`${getUserServerUrl(userId)}`);
    return data;
  } catch (err) {
    throw err.response.data.message;
  }
});

export const updateProfile = createAsyncThunk("UPDATE_USER_PROFILE", async (obj) => {
  try {
    const userId = getUserId();
    const { data } = await axios.post(`${getUserServerUrl(userId)}/update`, obj);
    return data.message;
  } catch (err) {
    throw err.response.data;
  }
});
