import { useEffect } from "react";
import { Box, Card, Grid } from "@mui/material";
import SuiBadge from "components/SuiBadge";
import SuiTypography from "components/SuiTypography";
import Table from "examples/Tables/Table";

function JobResume({ data }) {
  useEffect(() => {}, [data]);

  const experienceTableData = {
    columns: [
      { name: "designation", align: "center" },
      { name: "organisation Name", align: "center" },
      {
        name: "organisation Number",
        align: "center",
      },
      { name: "start Date", align: "center" },
      { name: "end Date", align: "center" },
      { name: "enrollment", align: "center" },
      { name: "field Of Experience", align: "center" },
    ],
    rows: data.professionalExperience.map((ex) => {
      return {
        designation: (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {ex.designation}
          </SuiTypography>
        ),
        "organisation Number": (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {ex.organizationNumber}
          </SuiTypography>
        ),
        "start Date": (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {ex.startDate}
          </SuiTypography>
        ),
        "end Date": (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {ex.endDate || "Currently Working"}
          </SuiTypography>
        ),
        enrollment: (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {ex.enrollment}
          </SuiTypography>
        ),
        "field Of Experience": (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {ex.fieldOfExperience}
          </SuiTypography>
        ),
        "organisation Name": (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {ex.organizationName}
          </SuiTypography>
        ),
      };
    }),
  };

  const educationTableData = {
    columns: [
      { name: "passing Year", align: "center" },
      { name: "marks", align: "center" },
      { name: "percentage", align: "left" },
      { name: "grade", align: "center" },
      { name: "major Subjects", align: "center" },
    ],

    rows: data.academicRecord.map((ed) => {
      return {
        "passing Year": (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {ed.passingYear}
          </SuiTypography>
        ),
        marks: (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {`${ed.obtainedMarks}/${ed.totalMarks}`}
          </SuiTypography>
        ),
        percentage: (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {ed.percentage}
          </SuiTypography>
        ),
        grade: (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {ed.grade}
          </SuiTypography>
        ),
        "major Subjects": (
          <SuiTypography variant="caption" color="secondary" fontWeight="medium">
            {ed.majorSubjects}
          </SuiTypography>
        ),
      };
    }),
  };
  return (
    <Card>
      <Box sx={{ width: "100%", bgcolor: "background.paper" }} padding={2}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} pb={1}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <SuiTypography variant="h4">
                  Resume <small>(Applied with)</small>
                </SuiTypography>
              </Grid>
              {data.submittedAt && (
                <Grid item>
                  <SuiTypography variant="h6" color="secondary">
                    Submitted On {new Date(data.submittedAt).toLocaleDateString("en-US")}
                  </SuiTypography>
                </Grid>
              )}
            </Grid>
          </Grid>

          {data.name && (
            <Grid item xs={12} sm={12} md={12}>
              <SuiTypography variant="h3">{data.name}</SuiTypography>
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={8}>
            <div className="MuiTableContainer-root css-1yz9zn8-MuiTableContainer-root">
              <table className="MuiTable-root css-w793m5-MuiTable-root">
                <tbody className="MuiTableBody-root css-apqrd9-MuiTableBody-root">
                  <tr className="MuiTableRow-root css-iw4ra7-MuiTableRow-root">
                    <th
                      scope="column"
                      className="MuiBox-root css-1urnfl0"
                      style={{ textAlign: "left" }}
                    >
                      <SuiTypography component="div" variant="caption">
                        FATHER NAME
                      </SuiTypography>
                    </th>
                    <th className="MuiBox-root css-1urnfl0">
                      <SuiTypography variant="h6">{data?.fatherName}</SuiTypography>
                    </th>
                  </tr>
                  <tr>
                    <th
                      scope="column"
                      className="MuiBox-root css-1urnfl0"
                      style={{ textAlign: "left" }}
                    >
                      <SuiTypography variant="caption">BIRTH DATE</SuiTypography>
                    </th>
                    <th className="MuiBox-root css-1urnfl0">
                      <SuiTypography variant="h6">{data?.birthDate}</SuiTypography>
                    </th>
                  </tr>
                  <tr>
                    <th
                      scope="column"
                      className="MuiBox-root css-1urnfl0"
                      style={{ textAlign: "left" }}
                    >
                      <SuiTypography variant="caption">EMAIL</SuiTypography>
                    </th>
                    <th className="MuiBox-root css-1urnfl0">
                      <SuiTypography variant="h6">{data?.email}</SuiTypography>
                    </th>
                  </tr>
                  <tr>
                    <th
                      scope="column"
                      className="MuiBox-root css-1urnfl0"
                      style={{ textAlign: "left" }}
                    >
                      <SuiTypography variant="caption">PHONE NO</SuiTypography>
                    </th>
                    <th className="MuiBox-root css-1urnfl0">
                      <SuiTypography variant="h6">{data?.cellPhoneNo}</SuiTypography>
                    </th>
                  </tr>
                  <tr>
                    <th
                      scope="column"
                      className="MuiBox-root css-1urnfl0"
                      style={{ textAlign: "left" }}
                    >
                      <SuiTypography variant="caption">MARITAL STATUS</SuiTypography>
                    </th>
                    <th className="MuiBox-root css-1urnfl0">
                      <SuiTypography variant="h6">
                        {data?.maritalStatus === "s"
                          ? "Single"
                          : data?.maritalStatus === "m"
                          ? "married"
                          : "Divorced"}
                      </SuiTypography>
                    </th>
                  </tr>
                  <tr>
                    <th
                      scope="column"
                      className="MuiBox-root css-1urnfl0"
                      style={{ textAlign: "left" }}
                    >
                      <SuiTypography variant="caption">GENDER</SuiTypography>
                    </th>
                    <th className="MuiBox-root css-1urnfl0">
                      <SuiTypography variant="h6">
                        {data?.gender === "M" ? "Male" : data.gender === "F" ? "female" : "Others"}
                      </SuiTypography>
                    </th>
                  </tr>
                  <tr>
                    <th
                      scope="column"
                      className="MuiBox-root css-1urnfl0"
                      style={{ textAlign: "left" }}
                    >
                      <SuiTypography variant="caption">RELIGION</SuiTypography>
                    </th>
                    <th className="MuiBox-root css-1urnfl0">
                      <SuiTypography variant="h6">{data?.religion}</SuiTypography>
                    </th>
                  </tr>
                  <tr>
                    <th
                      scope="column"
                      className="MuiBox-root css-1urnfl0"
                      style={{ textAlign: "left" }}
                    >
                      <SuiTypography variant="caption">DOMICILE</SuiTypography>
                    </th>
                    <th className="MuiBox-root css-1urnfl0">
                      <SuiTypography variant="h6">{data?.domicile}</SuiTypography>
                    </th>
                  </tr>
                  <tr>
                    <th
                      scope="column"
                      className="MuiBox-root css-1urnfl0"
                      style={{ textAlign: "left" }}
                    >
                      <SuiTypography variant="caption">DOMICILE DISTRICT</SuiTypography>
                    </th>
                    <th className="MuiBox-root css-1urnfl0">
                      <SuiTypography variant="h6">{data?.domicileDistrict}</SuiTypography>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </Grid>

          {data.academicRecord && data.academicRecord.length ? (
            <Grid container mt={1.5}>
              <Grid item>
                <SuiTypography variant="h5">Academic Record</SuiTypography>
              </Grid>
              <Grid item xs={12}>
                <Table columns={educationTableData.columns} rows={educationTableData.rows} />
              </Grid>
            </Grid>
          ) : null}
          {data.professionalExperience && data.professionalExperience.length ? (
            <Grid container mt={1.5}>
              <Grid item>
                <SuiTypography variant="h5">Experiences</SuiTypography>
              </Grid>
              <Grid item xs={12}>
                <Table columns={experienceTableData.columns} rows={experienceTableData.rows} />
              </Grid>
            </Grid>
          ) : null}

          {data.skills && data.skills.length ? (
            <Grid container mt={1.5} justifyContent="flex-start" alignItems="flex-start">
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <SuiTypography variant="h6">Skills</SuiTypography>
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={10}>
                {data.skills.map((skill) => {
                  <SuiBadge variant="contained" color="dark" badgeContent={skill} size="md" />;
                  return;
                })}
              </Grid>
            </Grid>
          ) : null}
          {data.fieldOfExperiences && data.fieldOfExperiences ? (
            <Grid container mt={1.5} justifyContent="flex-start" alignItems="flex-start">
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <SuiTypography variant="h6">Field Of Experiences</SuiTypography>
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={10}>
                {data.fieldOfExperiences.map((exp) => {
                  return (
                    <SuiBadge variant="contained" color="secondary" badgeContent={exp} size="md" />
                  );
                })}
              </Grid>
            </Grid>
          ) : null}

          {data.permanentAddress && (
            <Grid container pt={1.2}>
              <Grid item xs={4}>
                <SuiTypography variant="h6">Permanent Address</SuiTypography>
              </Grid>
              <Grid item xs={8}>
                <SuiTypography variant="h6" color="secondary">
                  {data.permanentAddress}
                </SuiTypography>
              </Grid>
            </Grid>
          )}

          {data.presentAddress && (
            <Grid container pt={1.2}>
              <Grid item xs={4}>
                <SuiTypography variant="h6">Present Address</SuiTypography>
              </Grid>
              <Grid item xs={8}>
                <SuiTypography variant="h6" color="secondary">
                  {data.presentAddress}
                </SuiTypography>
              </Grid>
            </Grid>
          )}

          {data.postalAddress && (
            <Grid container pt={1.2}>
              <Grid item xs={4}>
                <SuiTypography variant="h6">Postal Address</SuiTypography>
              </Grid>
              <Grid item xs={8}>
                <SuiTypography variant="h6" color="secondary">
                  {data.postalAddress}
                </SuiTypography>
              </Grid>
            </Grid>
          )}

          {data.policeStation && (
            <Grid container pt={1.2}>
              <Grid item xs={4}>
                <SuiTypography variant="h6">Police station</SuiTypography>
              </Grid>
              <Grid item xs={8}>
                <SuiTypography variant="h6" color="secondary">
                  {data.policeStation}
                </SuiTypography>
              </Grid>
            </Grid>
          )}

          {data.policeStationAddress && (
            <Grid container pt={1.2}>
              <Grid item xs={4}>
                <SuiTypography variant="h6">Police station Address</SuiTypography>
              </Grid>
              <Grid item xs={8}>
                <SuiTypography variant="h6" color="secondary">
                  {data.policeStationAddress}
                </SuiTypography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Card>
  );
}

export default JobResume;
